export default class FeaturedLotsController {
  /*@ngInject*/
  constructor($rootScope,
              $scope,
              AppSettings,
              ModelHelper,
              EventHelper,
              $q,
              LocaleFactory,
              FormatsFactory,
              RestFactory,
              $sce) {

    //common features.
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.$q = $q;
    this.name = 'FetauredLotsController';
    this.loading = false;
    this.range = AppSettings.variables.featured_lots_range || 3;

    this.lots = [];
    this.auctions = [];

    let now = moment();
    now.tz(this.settings.timezone);
    now = now.unix();

    //auctions time line
    // 0: past
    // 1: current
    // 2: future
    let lotsQuery = {
      'sort': null,
      'random': '',
      'filter[auction.timeline][value]': '1',
      'filter[auction.timeline][operator]': '=',
      'filter[auction.item_status]': 0,
      'filter[timeout][value]': now,
      'filter[timeout][operator]': ">",
      'filter[status]': 1
    };

    let auctionsQuery = {
      'sort': null,
      'random': '',
      'filter[timeline][value]': '1',
      'filter[timeline][operator]': '=',
      'filter[item_status]': 0,
      'filter[status]': 1
    }

    var featuredLots = RestFactory.featuredLots(lotsQuery, false, this.range);
    var featuredAuctions = RestFactory.featuredAuctions(auctionsQuery, false, this.range);

    this.loading = true;

    $q.all({
      lots: featuredLots,
      auctions: featuredAuctions
    })
      .then(
        (res) => {
          let totalAuctions = res.auctions.data.length;
          let auctions = res.auctions.data;

          let totalLots = res.lots.data.length;
          let lots = res.lots.data;

          let mergedItems = auctions.concat(lots);
          this.model = [];

          //generate random numbers as for the range length.
          let itemsLength = mergedItems.length;
          if (itemsLength > this.range) {
            let randomNumbers = [];
            while (randomNumbers.length < this.range) {
              var randomNum = Math.round(Math.random() * (itemsLength -1));
              if (randomNumbers.indexOf(randomNum) > -1) continue;
              randomNumbers.push(randomNum);
              this.model.push(mergedItems[randomNum]);
            }
          }
          else {
            this.model = mergedItems;
          }

        },
        (err) => console.log(err)
      )
      .finally(() => {
        this.loading = false;
      })

//    featuredLots.then(
//      (res) => {
//        this.lots = res.data;
//      })
//      .finally(() => {
//        this.loading = false;
//      });


    //RootScope events
    $scope.$on('startLoading', function (ev, data) {
      this.loading = true;
    });

    $scope.$on('endLoading', function (ev, data) {
      $t(function () {
        this.loading = false;
      }, 3000)
    });

    $scope.$on('AuctionItemChanged', function (ev, item, scope) {
      if (scope !== $scope) {
        ModelHelper.updateItem(item, this.auctions);
      }
    });


  }

  getLastBid(lot) {
    return lot.highest_bid && lot.highest_bid.decimal ?
      lot.highest_bid.formatted :
      lot.original_price.formatted;

  }

  getLastBidText(lot) {
    return lot.highest_bid && lot.highest_bid.decimal ?
      this.locale.lastBid : this.locale.openingBid;
  }

  lotIsOpen(lot) {

  }

  /**
   * Generate translatebale strings.
   */
  generateTranslations() {
    let that = this;
    Drupal.behaviors.angmod = (function (context) {
      //Translations go here.
      that.translations.login = Drupal.t('Login');
      that.translations.slogan = Drupal.t('Welcome to @siteName', {
        '@siteName': that.settings.siteName
      })

    })();
  }
}
