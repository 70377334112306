function FormatsFactory(AppSettings, $filter, LocaleFactory) {
  "ngInject";
  let factory = {};
  let filter = $filter;
  factory.name = 'Formats';
  const tz = AppSettings.timezone;
  moment.locale(AppSettings.language.language);

  let siteUrl = AppSettings.sitePath + AppSettings.basePath + AppSettings.pathPrefix;

  function formateTimestampToDate(timestamp, format) {
    timestamp *= 1000;
    format = format || 'dd/MM/yyyy';
    return filter('date')(new Date(timestamp), format);
  }

  Drupal.behaviors.angmod = (function (context) {


    factory.siteUrl = () => {
      "use strict";
      return siteUrl;
    };

    factory.getDateStatus = (date) => {

    };

    factory.lotsPlural = function (count) {
      count = count || 0;
      return Drupal.formatPlural(count,
        '1 ' + LocaleFactory.lot,
        '@count ' + LocaleFactory.lots);
    };

    factory.ClosesOn = (date, shortDate, shortTime) => {
      var _date = shortDate ? formateTimestampToDate(date, 'dd/MM/yy') : formateTimestampToDate(date);
      var _time = shortTime ? formateTimestampToDate(date, 'HH:mm') : formateTimestampToDate(date, 'HH:mm:ss');
      return Drupal.t('Closes on !date at !time', {
        '!date': _date,
        '!time': _time
      });
    };

    factory.startClosingOnOld = (date) => {

      var diff = AppSettings.dateDiff(date);

      if (diff <= 0) {
        return LocaleFactory.Closing;
      }

      var _date = formateTimestampToDate(date);
      var _time = formateTimestampToDate(date, 'HH:mm');

      return Drupal.t('Start closing on !date at !time', {
        '!date': _date,
        '!time': _time
      });
    };

    factory.startClosingOn = (timestamp) => {
      const today = moment().tz(tz);
      const time = timestamp * 1000;
      const passedDate = moment(time).tz(tz);
      const isToday = today.isSame(passedDate, 'd');
      if (isToday) {
        return Drupal.t('Closes today at !time', {'!time': passedDate.format('HH:mm')});
      }
      return Drupal.t('Start closing on !date at !time', {
        '!date': passedDate.format('DD/MM/YYYY'),
        '!time': passedDate.format('HH:mm')
      });
    };

    factory.formatStartEndDates = (start, end, dateFormat, timeFormat) => {
      "use strict";
      var dayFormat = dateFormat || 'dd/MM/yyyy';
      var hourFormat = timeFormat || 'HH:mm';

      var _dayStart = formateTimestampToDate(start, dayFormat);
      var _hourStart = formateTimestampToDate(start, hourFormat);
      var _dayEnd, _hourEnd = null;

      if (end && end != start) {
        _dayEnd = formateTimestampToDate(end, dayFormat);
        _hourEnd = formateTimestampToDate(end, hourFormat);

        if (_dayStart == _dayEnd) {

          return "<div>" + _dayStart + "<span class='date-separator'>&nbsp;&nbsp;-&nbsp;&nbsp;</span> " + _hourStart + "<span class='date-separator'>/</span>" + _hourEnd + "</div>";
        }

        return Drupal.t("From !start <br>To !end", {
          "!start": _dayStart + " " + _hourStart, "!end": _dayEnd + " " + _hourStart
        });
      }

      return _dayStart + "<span class='date-separator'>&nbsp;&nbsp;-&nbsp;&nbsp;</span>" + _hourStart;
    };

    factory.formatDate = (timestamp, dateFormat, timeFormat) => {
      var date = timestamp * 1000;
      var _date = filter('date')(new Date(date), dateFormat || 'dd/MM/yyyy');
      var _dateShort = filter('date')(new Date(date), timeFormat || 'dd/MM/yy');
      var _time = filter('date')(new Date(date), timeFormat || 'HH:mm:ss');
      var _timeShort = filter('date')(new Date(date), timeFormat || 'HH:mm');
      return {
        date: _date,
        time: _time,
        timeShort: _timeShort,
        fullDate: Drupal.formatString('!date !time',
          {'!date': _date, '!time': _time}),
        shortDate: _dateShort + ' ' + _timeShort
      };
    };

    factory.lastBidFormat = (amount) => {
      "use strict";
      return Drupal.t('Last bid: !amount', {"!amount": amount});
    };

    factory.bidsCount = (count) => {
      "use strict";
      return Drupal.formatPlural(count,
        '1 ' + LocaleFactory.bid,
        '@count ' + LocaleFactory.bids)
    };


  }.bind(this))();

  return factory;
}

export default FormatsFactory;
