import AllLotsComponent from './allLots.component';

let AllLotsModule = angular.module('app.shared.components.lot.alllots', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('allLots', AllLotsComponent);

export default AllLotsModule;
