import CategoriesComponent from './categories.component';

let CategoriesModule = angular.module('app.shared.components.categories', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('categories', CategoriesComponent);

export default CategoriesModule;
