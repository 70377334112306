import NewsItemComponent from './newsItem.component';

let NewsItemModule = angular.module('app.shared.components.news.newsitem', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('newsItem', NewsItemComponent);

export default NewsItemModule;
