import UserLotsController from './userLots.controller';

let UserLotsComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'userLots.html',
    controller: UserLotsController,
    controllerAs: "vm",
    scope: {}
  }

};

export default UserLotsComponent;
