class SearchPageController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $stateParams,
              $sce,
              AppSettings,
              RestFactory,
              LocaleFactory,
              FormatsFactory) {

    //common features.
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.R = RestFactory;
    this.state = $stateParams;
    this.name = 'SearchPageController';

    //Custom code.
    this.currentPage = 1;
    this.itemsPerPage = 9;
    this.term = $stateParams.term;
    this.items = [];

    if(this.term.length >= 3)
      this.searchTerm(this.term);
  }

  searchTerm(term, page) {
    page = page || 1;
    this.loading = true;
    this.items = [];
    this.R.Factory().all('search_open/' + term).getList({
      style: 'medium',
      page: page,
      range: this.itemsPerPage
    }).then(
      (res) => {
        this.itemsResultTotal = res.count;
        this.currentPage = page;
        let items = res.data.length > 0 ? res.data[0] : [];

        if(items.length < 1)
          this.loading = false;

        angular.forEach(items, (e, i) => {
          e.type == 'lot' ? this.getLot(e) : this.getAuction(e);
          if(i == (res.data.length -1))
            this.loading = false;
        });
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    )
  }

  getLot(item) {

    if (item.type == 'lot') {
      item.loading = true;
      this.R.lot(item.entity_id).then(
        (res) => {
          item = res.data[0]
          this.items.push(item);
        },
        (err) => {
          console.log(err);
          item.loaded = false;
        }
      ).finally(() => {
        item.loading = false
      });
    }
  }

  getAuction(item) {

    if (item.type == 'auction') {
      item.loading = true;
      this.R.auction(item.entity_id).then(
        (res) => {
          item = res.data[0]
          this.items.push(item);
        },
        (err) => {
          console.log(err);
          item.loaded = false;
        }
      ).finally(() => {
        item.loading = false
      });
    }
  }

  pageChanged(page) {
    this.searchTerm(this.term, page);
  }
}

export default SearchPageController;
