function AppSettings($rootScope, lodash) {
  "ngInject";

  let settings = Drupal.settings.angmod;
  let _ = lodash;

  Drupal.behaviors.angmod = (function (context) {
    settings.name = 'AppSettings';
    settings.lang = settings.lang || {};
    settings.basePath = Drupal.settings.basePath;
    settings.pathPrefix = Drupal.settings.pathPrefix;

    /**
     * Global Settings
     *
     * when a value is set to 0 (zero) it means unlimited.
     */

    //Deafult number of items per page
    //fallback if can't get the value from the variables.
    settings.pagesRange = 6;
    //Number of news in the latest news block
    settings.latestNewsItems = 3;
    //Number of featured lots
    settings.featuredLotsItems = 3;


    //Track currents search text and category filter
    settings.searchText = '';
    settings.category = '';
    settings.categoryName = '';

    //Reset search filters
    settings.resetFilters = () => {
      "use strict";
      settings.searchText = '';
      settings.category = '';
      settings.categoryName = '';
    };

    //TODO:REMOVE
    settings.lang.noItemFound = Drupal.t('No item available at the moment.');

    settings.isAdmin = () => {
      "use strict";
      return _.includes(settings.user.roles, 'administrator');

    };

    settings.isAuthenticated = () => {
      "use strict";
      return parseInt(settings.user.id) > 0;
    };

    settings.isBidder = () => {
      "use strict";
      return _.includes(settings.user.roles, 'bidder');
    };

    settings.dateDiff = (date) => {
      "use strict";
      date = date * 1000;
      var now = moment().tz(settings.timezone).unix();
//      var now = new Date();
//      now = now.getTime();
      var diff = date - now;
      return diff;
    };

    //Add an item to the favourite list
    settings.addLotToFavourite = (id, listName) => {
    };

    //Get the correct expiring date time
    settings.getExpiringDateTime = (date, scope) => {
      scope.expireDate = settings.dateDiff(date);
      return scope.expireDate;
    };

    //Check whether an item date is expired
    settings.itemExpired = (date, scope) => {
      return settings.getExpiringDateTime(date, scope) <= 0;
    };

    $rootScope.$on('$stateChangeStart', function () {
      settings.search = null;
      settings.category = null;
    });

  }.bind(this))();

  return settings;
}

export default AppSettings;
