class LotSinglePageController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              AppSettings,
              $http,
              LocaleFactory,
              RestFactory,
              lodash,
              Restangular,
              $timeout,
              $stateParams,
              $state,
              FormatsFactory) {


    //common features.
    this.name = 'LotSinglePageController';
    this.settings = AppSettings;
    this.lang = AppSettings.language.language;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.Restangular = Restangular;
    this.timeout = $timeout;
    $scope.$state = $state;
    this.state = $state;
    this.scope = $scope;
    this._ = lodash;
    this.R = RestFactory;
    this.http = $http;
    this.rootScope = $rootScope;

    //Custom code.
    this.lotId = $stateParams.id;
    this.lot = {};
    this.lotPhotos = [];
    this.lotLoading = false;

    this.auction = {};
    this.auctionLoaded = false;
    this.homeLink = '/' + this.settings.pathPrefix;
    this.lotEditLonk = FormatsFactory.siteUrl + 'node/' + this.lot.id + '/edit';
//    this.auctionLink = '/' + this.settings.pathPrefix + "auctions/#auction/" + 2;

    this.relatedLots = [];
    this.relatedLotdLoading = false;
    this.relatedLotsLen = 0;
    this.currentLot = null;
    this.currentLotIndex = null;

    this.getLot(this.lotId);

    //Component variables
    this.shortDateLang = this.lang + 'short';
    this.expiring = false;
    this.lastMinute = false;
    this.expired = false;
    this.awarded = false;

    //Date and time
    this.serverTime = null;
    this.expiringTime = null;
    this.timeOffSet = 0;


    this.leading = false;
    this.leadingMessage = '';

    //manage the browser tab and reload when focused
    $scope.$on('LotPageTabFocused', () => {
      this.getLot(this.lotId);
    });

    $scope.$on('timer-tick', (ev, args) => {
      var millis = args.millis;

      millis <= 180000 ?
        this.expiring = true :
        this.expiring = false;

      millis <= 60000 ?
        this.lastMinute = true :
        this.lastMinute = false;

      if (millis <= 0 || millis == NaN) {
        this.timeCompleted()
      }


      if (!$rootScope.$root.$$phase) {
        $scope.$apply();
      }
    });

    $scope.$on('BidPlaced', (ev, data) => {
      //favourite result is taken from the bidder not the current user
      //so we need to copy and paste the value.
      var isFavourite = angular.copy(this.lot.favourite);
      if (data.id == this.lot.id) {

        this.lot = data;
        this.lot.favourite = isFavourite;
        this.lot.expired = this.expired = false;
        this.serverTime = data.server_time.time;
        this.updateValues(this.lot);
        if (!$rootScope.$root.$$phase) {
          $scope.$apply();
        }
      }


    });

  }

  timeCompleted() {
    this.timeout(() => {
      console.log('lot expired, reloading...');
      this.getLot(this.lotId);
      this.rootScope.$broadcast('LotExpired', this.lot);
      if (!this.rootScope.$root.$$phase) {
//        this.scope.$digest();
      }
    }, 1000);
//      this.expired = true;
//      this.expiring = false;
//      this.lastMinute = false;
//      this.lot.expired = true;
//      this.expired = true;
  }

  getLot(id) {
    this.lotLoading = true;
    let lot = this.R.lot(id, 'square,gallery_1200');

    this.serverTime = moment().unix();
    this.http({
      method: 'GET',
      'url': this.settings.restPath + '/users/me'
    })
      .then(
        (res) => {
          let headers = res.headers();
          let serverDate = headers.date;
          this.serverTime = moment(serverDate).tz(this.settings.timezone).unix();
          let requestStartTime = moment().unix();
          let requestEndTime = null;

          lot.then(
            (lotRes) => {
              requestEndTime = moment().unix();
              this.timeOffSet = requestEndTime - requestStartTime;
              this.lot = lotRes.data[0];
              this.serverTime = parseInt(this.serverTime, 10);
              this.serverTime += this.timeOffSet;
              this.getAuction();
              this.updateValues(this.lot);

              this.lotPhotos = [];
              angular.forEach(this.lot.photos, (photo) => {
                this.lotPhotos.push({
                  thumb: photo.styled_url || photo.url,
                  img: photo.gallery_1200_url,
                  description: photo.title
                });
              });
            },
            (err) => console.log(err)
          )
            .finally(() => {
              this.lotLoading = false;
            });
        },
        (err) => console.log(err)
      );
  };

  updateValues(lot) {
    this.expiringTime = moment(parseInt(lot.timeout * 1000, 10)).unix();
    this.countdown = parseInt(this.expiringTime - this.serverTime, 10);
    this.expired = this.countdown < 0;

    this.scope.$broadcast('timer-reset');
    this.scope.$broadcast('timer-set-countdown', this.countdown);
    this.scope.$broadcast('timer-start');

    this.lotEditLink = this.formats.siteUrl() + 'node/' + lot.id + '/edit';

    this.leading = lot.leading.user_leads;
    this.leadingMessage = this.leading ?
      this.locale.isLeaderMessage :
      this.locale.isNotLeaderMessage;

    this.awarded = lot.buy_status !== '1' && lot.buy_status !== '3';

    this.forSale = lot.buy_status === '0';
    this.awarded = lot.buy_status === '1';
    this.unsold = lot.buy_status === '5';
    this.ordering = !this.awarded && !this.forSale && !this.unsold;


    //Selling statuses
    this.sellStatus = {
      forSale: this.forSale,
      awarded: this.awarded || this.ordering,
      unsold: this.unsold,
      ordering: this.ordering
    };

    //item status
    this.itemStatus = {
      open: lot.item_status === '0' && this.auction.item_status === '0',
      closed: lot.item_status === '1' || this.auction.item_status === '1',
      suspended: lot.item_status === '2' || this.auction.item_status === '2',
      expired: this.expired
    };

    //Auction status
    this.auctionStatus = {
      open: this.auction.item_status === '0',
      closed: this.auction.item_status === '1',
      suspended: this.auction.item_status === '2'
    };

    if (this.awarded && this.leading) {
      this.leadingMessage = this.locale.wonMessage;
    }

    if (this.awarded && !this.leading) {
      this.leadingMessage = this.locale.lostMessage;
    }
  }

  getAuction(auctionId) {
    //check if we have a valid lot.
    if (this.lot.id) {
      this.auction = this.lot.auction; //res.data[0];
      this.auctionLink = this.formats.siteUrl() + "#/auction/" + this.auction.id + '/';
      this.relatedLots = this.auction.lots;
      this.relatedLotsLen = this.relatedLots.length;
      this.setCurrentLotIndex();
      this.auctionLoaded = true;
    }
  }

  setCurrentLotIndex() {
    let index = this._.findIndex(this.relatedLots, (l) => {
      return l == this.lot.id;
    });
    this.currentLotIndex = index;
    this.currentLot = this.relatedLots[index];
  }

  nextLotId() {
    let idx = this.currentLotIndex;

    if (idx < (this.relatedLots.length - 1)) {
      idx += 1;
    }

    return this.state.href('lot', {
      id: this.relatedLots[idx]
    });
  }

  prevLotId() {
    let idx = this.currentLotIndex;

    if (idx > 0) {
      idx -= 1;
    }

    return this.state.href('lot', {
      id: this.relatedLots[idx]
    });

  }

  changeLot() {
  }

  addLotToFavourite(id) {

    this.R.flag(id).then(
      (res) => {
        this.lot = res.data[0]['node'];
      }
    )

  }
}

export default LotSinglePageController;
