import AuctionListComponent from './auctionList/auctionList';
import AuctionItemComponent from './auctionitem/auctionItem';
import AuctionSinglePageComponent from './auctionSinglePage/auctionSinglePage';
import AuctionInfo from './auctionInfo/auctionInfo';

let AuctionComponentsModule =
  angular.module('app.shared.components.auction', [
  AuctionListComponent.name,
  AuctionItemComponent.name,
  AuctionSinglePageComponent.name,
  AuctionInfo.name
]);

export default AuctionComponentsModule;
