import CategoriesController from './categories.controller';

let CategoriesComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'categories.html',
    controller: CategoriesController,
    controllerAs: "vm",
    scope: {
      tid: "="
    }
  }

};

export default CategoriesComponent;
