import UserMyLotsController from './userMyLots.controller';

let UserMyLotsComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'userMyLots_v1.html',
    controller: UserMyLotsController,
    controllerAs: "vm",
    scope: {}
  }

};

export default UserMyLotsComponent;
