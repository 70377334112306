import UserWonBidsComponent from './userWonBids.component';

let UserWonBidsModule = angular.module('app.shared.components.user.userwonbids', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('userWonBids', UserWonBidsComponent);

export default UserWonBidsModule;
