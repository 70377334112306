class UserListTabController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              $state,
              $stateParams,
              RestFactory,
              AppSettings,
              LocaleFactory,
              FormatsFactory) {

    //common features.
    this.name = 'UserListTabController';
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.R = RestFactory;

    //Custom code.
    this.uid = $scope.uid || AppSettings.pathArgument1;

    this.lotsCount = 0;
    this.favsCount = 0;
    this.awardedCount = 0;

    this.loading = false;
    this.favsLoading = false;
    this.wonLoading = false;

    this.getFavouritesLots();
    this.getWonLots();
    this.getUserLots();

  }


  getUserLots() {
    this.loading = true;
    this.R.userLots(this.uid).then(
      (res) => {
        this.lotsCount = res.count;
//        angular.forEach(res.data, (lot) => {
//          lot.awarded = lot.order && (parseInt(lot.order.uid) == this.uid);
//          lot.awarded ? this.awardedCount += 1 : '';
//        });
      },
      (err) => {
      }
    ).finally(() => {
      this.loading = false;
    });


  }

  getWonLots() {
    this.wonLoading = true;
    this.R.userWonLots(this.uid)
      .then(
        (res) => {
          this.awardedCount = res.count;
        }
      ).finally(() => {
        this.wonLoading = false;
      }
    )
  }

  getFavouritesLots() {
    this.favsLoading = true;
    this.R.flaggedLots(this.uid)
      .then(
        (res) => {
          this.favsCount = res.count
        },
        (err) => {
        }
      )
      .finally(() => {
        this.favsLoading = false;
      });
  }
}

export default UserListTabController;
