class NavbarController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              AppSettings,
              $sce,
              LocaleFactory,
              $cookies,
              EventHelper) {
    //common features.
    this.rootScope = $rootScope;
    this.scope = $scope;
    this.settings = AppSettings;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.EventHelper = EventHelper;
    this.locale = LocaleFactory;
    this.$cookies = $cookies;
    this.inputText = this.text || this.locale.Search;
    this.name = 'NavbarController';
    this.activeViewMode = $cookies.get('auctionActiveViewMode') || 'list';
    this.showViewMode = true;
    AppSettings.resetFilters();


    this.changeViewMode = (mode) => {
      this.broadcastViewModeChanged(mode);
    };

    $scope.$on('auctionFiltersChanged', (ev, data) => {
      this.showViewMode = data !== 'live';
    })
  }

  doSearch() {
    let text = this.scope.searchText;
    if(angular.isDefined(text) && text.length >= 3) {
      this.EventHelper.broadcastGlobalEvent('doSearch', {
        'scope': this,
        'searchText': text,
        'type': this.type
      });

      this.settings.searchText = text;
      this.scope.searchText = '';

    }
  }

  cancelSearch() {
    this.EventHelper.broadcastGlobalEvent('cancelSearch', {
      'scope': this,
      'type': this.type
    });

    this.settings.searchText = null;
    this.settings.category = null;
    this.settings.categoryName = null;
  }

  broadcastViewModeChanged(mode) {
    this.activeViewMode = mode;
    var today = new Date();
    var expire = new Date();
    expire.setDate(today.getDate() + 30);
    this.$cookies.put('auctionActiveViewMode', mode, {expires: expire });
    this.EventHelper.broadcastGlobalEvent('AuctionViewModeChanged', mode, this);
  }

  formatArrayToString(array) {
    return array.split(',');
  }
}

export default NavbarController;
