class AuctionSinglePageController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $stateParams,
              AppSettings,
              $sce,
              $timeout,
              LocaleFactory,
              FormatsFactory,
              RestFactory) {

    //common features.
    this.rest = RestFactory
    this.params = $stateParams;
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.$timeout = $timeout;
    this.loading = false;
    this.galleryPhotos = [];

    this.timelineStatus = {
      0: this.locale.PastAuction,
      1: this.locale.ActualAuctions,
      2: this.locale.FutureAuction
    };

    this.timeline = '';
    this.timelineIndex = '';

    this.name = 'AuctionSinglePageController';

    this.auction = [];

    this.expired = false;
    this.open = false;
    this.suspended = false;
    this.closed = false;

    this.getAuction()
  }

  getAuction() {
    this.loading = true;
    let auction = this.rest.auction(this.params.id, 'square,gallery_1200');
    auction
      .then((res) => {
          let auction = res.data[0];
          this.auction = auction;
          this.expired = auction.expired;
          this.openingDateStatus = this.formats.getDateStatus(auction.opening_date);
          this.closingDateStatus = this.formats.getDateStatus(auction.closing_date);

          this.timelineIndex = parseInt(auction.timeline);
          this.timeline = this.timelineStatus[this.timelineIndex];
          this.open = auction.item_status == '0';
          this.closed = auction.item_status == '1';
          this.suspended = auction.item_status == '2';

          this.galleryPhotos = [];
          angular.forEach(this.auction.gallery, (photo) => {
            this.galleryPhotos.push({
              thumb: photo.styled_url || photo.url,
              img: photo.gallery_1200_url,
              title: photo.title
            });
          });


        }
      )
      .finally(() => {
        this.loading = false;
      });
  };
}

export default AuctionSinglePageController;
