class UserLotsController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              $cookies,
              RestFactory,
              AppSettings,
              LocaleFactory,
              FormatsFactory) {

    //common features.
    this.name = 'UserLotsController';
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.R = RestFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;


    //Custom code.
    this.viewMode = $cookies.get('UserViewMode') || 'list';
    this.pageRange = 2; //AppSettings.auctions_home_range || 6;

    this.lots = [];
    this.favouriteLots = [];
    this.loading = false;
    this.favouriteLoading = false;
    this.itemCount = 0;
    this.favouriteCount = 0;
    this.awardedCount = 0;
    this.uid = parseInt(this.settings.pathArgument1);

//    this.getUserLots();
//    this.getFavouritesLots();

    //EVENTS
    $scope.$on('UserViewModeChanged', (ev, mode, scope) => {
      this.viewMode = mode;
    });

  }

  getUserLots(page) {
    this.loading = true;
    this.R.userLots(this.uid, this.pageRange, page).then(
      (res) => {
        this.itemCount = res.count;
        this.lots = [];
        angular.forEach(res.data, (lot) => {
          lot.awarded = lot.order && (parseInt(lot.order.uid) == this.uid);
          lot.awarded ? this.awardedCount += 1 : '';
          this.lots.push(lot);
        });
      },
      (err) => {
      }
    ).finally(() => {
      this.loading = false;
    });


  }

  getFavouritesLots() {
    this.favouriteLoading = true;
    this.R.flaggedLots(this.uid)
      .then(
        (res) => {
          this.favouriteLots = res.data;
          this.favouriteCount = res.count
        },
        (err) => {
        }
      )
      .finally(() => {
        this.favouriteLoading = false;
      });

  }

  pageChanged(page) {
   this.getUserLots(page);
  }
}

export default UserLotsController;
