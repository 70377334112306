import LotSinglePageController from './lotSinglePage.controller';

let LotSinglePageComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'lotSinglePagev2.html',
    controller: LotSinglePageController,
    controllerAs: "vm",
    scope: {},
    link: function(scope, element, attrs) {
      "use strict";
      let $ = jQuery;

      $(window).on('focus', function(e) {
        scope.$broadcast('LotPageTabFocused');
      })
    }
  }

};

export default LotSinglePageComponent;
