function WebSocketFactory($rootScope, AppSettings, LocaleFactory, FormatsFactory, $websocket) {
  "ngInject";
  let factory = {};
  let settings = AppSettings;
  let locale = LocaleFactory;
  let formats = FormatsFactory;

  factory.name = 'WebSocket';

  let protocol = AppSettings.web_socket.protocol;
  let host = AppSettings.web_socket.host;
  let port = AppSettings.web_socket.port;
  let address = protocol + host + ':' + port;
  var ws = $websocket(address);


  ws.onMessage((message) => {
    "use strict";
    let data;
    if (message.data) {
      try {
        data = JSON.parse(message.data);
        let bid = data.bid;
        let lot = angular.copy(data.data);
        lot.leading.leading_uid = bid.leader_uid;
        lot.leading.leading_username = bid.leader_name;
        var l_uid  = parseInt(bid.leader_uid);
        var c_uid  = parseInt(AppSettings.user.id);
        lot.leading.user_leads = l_uid === c_uid;
        if(data.type == 'final_bid') {
          $rootScope.$broadcast('BidPlaced', lot);
        }

      }
      catch (e) {
        data = message.data;
      }
    }
  });


  return factory;
}

export default WebSocketFactory;
