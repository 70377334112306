import HomeComponent from './home.component';

let HomeModule = angular.module('app.components.home', [
//    'ui.router'
    //Add module dependencies here
    //in the format.
    /*OtherModule.name*/
  ])
  .config(($stateProvider, $urlRouterProvider, $locationProvider) => {
    "ngInject";

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('home', { //App Home Page
        url: '/',
        template: "<home></home>",
      })
      .state('auction', { // Single Auction Page
        url: '/auction/:id/:page',
        template: "<auction-single-page></auction-single-page>",
      })
      .state('lot', { // Single Lot Page
        url: '/lot/:id',
        template: "<lot-single-page>",
      })
      .state('search', { // Search Page
        url: '/search/:term?',
        template: "<search-page>",
      })
      .state('404', {
        url: '/404',
        template: '<strong>Page Not Found (404)</strong>'
      });

//    $locationProvider.html5Mode({
//      enabled: true,
//    });

  })
  .directive('home', HomeComponent);

export default HomeModule;
