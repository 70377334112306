import AuctionListController from './auctionList.controller';

let AuctionListComponent = (AppSettings) => {
  "use strict";
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'auctionList_v1.html',
    controller: AuctionListController,
    controllerAs: "vm",
    scope: {
      range: "@"
    },
  };
}

export default AuctionListComponent;
