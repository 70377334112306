function LocaleFactory($filter, AppSettings) {
  "ngInject";
  let factory = {};
  let settings = AppSettings;

  Drupal.behaviors.angmod = (function (context) {
    factory.name = 'Locale';
    factory.slogan = Drupal.t('Welcome to @siteName', {
      '@siteName': AppSettings.siteName
    });

    //Common Lot's strings.
    factory.Lot = Drupal.t('Lot');
    factory.lot = Drupal.t('lot');
    factory.Lots = Drupal.t('Lots');
    factory.lots = Drupal.t('lots');
    factory.MyLots = Drupal.t('My lots');
    factory.nextLot = Drupal.t('Next lot');
    factory.previousLot = Drupal.t('Previous lot');
    factory.FeaturedLots = Drupal.t('Featured lots');
    factory.Featured = Drupal.t('Featured');
    factory.PastLots = Drupal.t('Past lots');
    factory.PastLot = Drupal.t('Past lot');
    factory.FutureLots = Drupal.t('Future lots');
    factory.FutureLot = Drupal.t('Future lot');
    factory.ActualLots = Drupal.t('Current lots');
    factory.ActualLot = Drupal.t('Current lot');


    //BID
    factory.NextMinBid = Drupal.t('Next minimum bid');
    factory.Multiple = Drupal.t('Bid must be a mutiple of');
    factory.CurrentBid = Drupal.t('Current bid');
    factory.PlaceABid = Drupal.t('Place a bid');
    factory.PlaceAnAutoBid = Drupal.t('Place an auto bid');
    factory.Bid = Drupal.t('Bid');
    factory.AutoBid = Drupal.t('Auto Bid');
    factory.OpeningBid = Drupal.t('Opening bid');
    factory.bidStep = Drupal.t('Bid step');
    factory.numberOfBids = Drupal.t('Number of bids');
    factory.auctionCost = Drupal.t('Auction cost');
    factory.VAT = Drupal.t('VAT');
    factory.auctionCostVAT = Drupal.t('Auction cost VAT');
    factory.Price = Drupal.t('Price');
    factory.Total = Drupal.t('Total');

    factory.invalidAmount = Drupal.t('The amount is not valid.');
    factory.confirmBidTitle = Drupal.t('Confirm your bid.');
    factory.confirmBidContent = Drupal.t('Before proceding please review the the bid details.');
    factory.confirmBidOk = Drupal.t('The amount is not valid.');
    factory.confirmBidCancel = Drupal.t('The amount is not valid.');


    //Common Auction's strings.
    factory.Auction = Drupal.t('Auction');
    factory.auction = Drupal.t('auction');
    factory.Auctions = Drupal.t('Auctions');
    factory.auctions = Drupal.t('auctions');
    factory.AllAuctions = Drupal.t('All auctions');
    factory.PastAuction = Drupal.t('Past auction');
    factory.FutureAuctions = Drupal.t('Open Soon');
    factory.FutureAuction = Drupal.t('Opens Soon');
    factory.ActualAuctions = Drupal.t('Current auctions');

    factory.OurAuctions = Drupal.t('Our auction');
    factory.RetailAuctions = Drupal.t('Retails');
    factory.AcquisitionAuctions = Drupal.t('Acquisitions');
    factory.PastAuctions = Drupal.t('Past auctions');

    factory.downloadCatalog = Drupal.t('download catalog');


    //Common Strings translation
    factory.home = Drupal.t('Home');
    factory.ClosesOn = Drupal.t('Closes on');
    factory.closesOn = Drupal.t('closes on');
    factory.closing = Drupal.t('closing');
    factory.Closing = Drupal.t('Closing');
    factory.closed = Drupal.t('closed');
    factory.Closed = Drupal.t('Closed');
    factory.Close = Drupal.t('Close');
    factory.Suspended = Drupal.t('Suspended');
    factory.Expired = Drupal.t('Expired');
    factory.Awarded = Drupal.t('Awarded');
    factory.wonMessage = Drupal.t('Congratulation. You won this auction');
    factory.lostMessage = Drupal.t('Unfortunatly this auction was awarded by onother');

    factory.isLeaderMessage = Drupal.t("Currently you are the bid leader");
    factory.isNotLeaderMessage = Drupal.t("You are not the bid leader");

    factory.Description = Drupal.t('Description');
    factory.lastBid = Drupal.t('Last bid');
    factory.openingBid = Drupal.t('Opening bid');
    factory.GeneralInformation = Drupal.t('General Information');
    factory.Categories = Drupal.t("Categories");
    factory.OpeningDate = Drupal.t("Opening date");
    factory.ClosingDate = Drupal.t("Closing date");
    factory.VisitingDate = Drupal.t("Viewing days");
    factory.PickUpDate = Drupal.t("Pick-up date");
    factory.Location = Drupal.t("Location");
    factory.LatestNews = Drupal.t('Latest news');


    //Wide-site strings
    factory.Accept = Drupal.t('Accept');
    factory.Cancel = Drupal.t('Cancel');
    factory.User = Drupal.t('User');
    factory.Company = Drupal.t('Company');
    factory.Login = Drupal.t('Login');
    factory.Register = Drupal.t('Register');
    factory.Company = Drupal.t('Company');
    factory.Phone = Drupal.t('Phone');
    factory.VAT = Drupal.t('VAT');
    factory.Live = Drupal.t('Live');
    factory.LivePageTitel = Drupal.t('Live bid on your items !');

    factory.Bailiff = Drupal.t("Bailiff");
    factory.Curator = Drupal.t("Curator");


    factory.Search = Drupal.t('Search');
    factory.SearchResults = Drupal.t('Search results');
    factory.search = Drupal.t('search');
    factory.searchForAuctions = Drupal.t('Search for auctions');
    factory.searchInThisAuction = Drupal.t('Search in this auctions');
    factory.searchForLots = Drupal.t('Search for lots');
    factory.noItemFound = Drupal.t('No item available at the moment.');
    factory.cancelSearch = Drupal.t('Clear current search query and category filters');
    factory.MoreInfo = Drupal.t('More info');
    factory.ListView = Drupal.t('List view');
    factory.GalleryView = Drupal.t('Gallery view');
    factory.YouAreHere = Drupal.t('You are here');
    factory.FAQ = Drupal.t("Frequently Asked Questions");
    factory.News = Drupal.t('News');
    factory.MyProfile = Drupal.t('My Profile');
    factory.Username = Drupal.t("Username");
    factory.Active = Drupal.t("Active");
    factory.Bidder = Drupal.t("Bidder");
    factory.gsmAuthenticated = Drupal.t("GSM Authenticated");
    factory.City = Drupal.t("City");
    factory.Country = Drupal.t("Country");
    factory.Address = Drupal.t("Address");
    factory.Edit = Drupal.t("Edit");
    factory.ClientNumber = Drupal.t("Client number");
    factory.YourClientNumber = Drupal.t("Your Client number");
    factory.YourList = Drupal.t("Your list");
    factory.AllLots = Drupal.t('All lots');
    factory.lotsBidOn = Drupal.t('Lots bid on');
    factory.WonBids = Drupal.t('Won bids');
    factory.FavouriteLots = Drupal.t('Favourite lots');
    factory.TypeHereForSearchingAuctionsOrLots = Drupal.t('Type here for searching auctions or lots');
    factory.Last10days = Drupal.t('Last 10 days');
    factory.Last15days = Drupal.t('Last 15 days');
    factory.Last30days = Drupal.t('Last 30 days');
    factory.AllTime = Drupal.t('All time');
    //Common bid strings.
    factory.registerToBid = Drupal.t('In order to place bids you must be a registered user and have a verified account');
    factory.Bid = Drupal.t('Bid');
    factory.bid = Drupal.t('bid');
    factory.Bids = Drupal.t('Bids');
    factory.bids = Drupal.t('bids');
    factory.proxyDefinitionTitle = Drupal.t('Proxy Bidding');
    factory.itemsFoundCount = (total) => {
      "use strict";
      return Drupal.formatPlural(total,
        Drupal.t('Found 1 item'), Drupal.t('Found @num items', {'@num': total}));
    };
  }.bind(this))();

  return factory;
}

export default LocaleFactory;
