import FeatutedListController from './featuredLots.controller';

let FeaturedLotsComponent = (AppSettings) => {
  "use strict";
  "ngInject";
  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'featuredLots_v2.html',
    controller: FeatutedListController,
    controllerAs : 'vm',
    scope: {}
  }
};

export default FeaturedLotsComponent;
