import FeaturedLots from './featuredLots/featuredLots';
import LotItems from './lotItems/lotItem'
import LotList from './lotList/lotList';
import LotSinglePage from './lotSinglePage/lotSinglePage';
import AllLots from './allLots/allLots';
import LotBid from './lotBid/lotBid';

let LotComponentsModule = angular.module('app.shared.components.lot', [
  LotList.name,
  AllLots.name,
  FeaturedLots.name,
  LotItems.name,
  LotSinglePage.name,
  LotBid.name
]);

export default LotComponentsModule;
