class AuctionItemController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              AppSettings,
              $sce,
              $filter,
              LocaleFactory,
              FormatsFactory) {

    //common features.
    this.filter = $filter;
    this.locale = LocaleFactory;
    this.format = FormatsFactory;
    this.settings = AppSettings;

    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.name = 'AuctionItemController';

  }

}

export default AuctionItemController;
