import AuctionItemController from './auctionItem.controller';

let AuctionItemComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'auctionItem.html',
    controller: AuctionItemController,
    controllerAs: "vm",
    scope: {
      auction: "=",
    }
  }

};

export default AuctionItemComponent;
