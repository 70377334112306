import AuctionsComponents from './auction/auction.components';
import LotsComponents from './lot/lot.components';
import CategoryComponent from './category/category';
import CategoriesComponent from './categories/categories';
import NewsListComponent from './news/newsList/newsList';
import NewsItemComponent from './news/newsItem/newsItem'
import UserComponents from './user/user-components';

let SharedComponentsModule = angular.module('app.shared.components', [
  CategoryComponent.name,
  CategoriesComponent.name,
  AuctionsComponents.name,
  LotsComponents.name,
  NewsListComponent.name,
  NewsItemComponent.name,
  UserComponents.name
]);

export default SharedComponentsModule;
