class ProfileController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              lodash,
              RestFactory,
              AppSettings,
              LocaleFactory,
              FormatsFactory) {

    //common features.
    this.name = 'ProfileController';
    this.scope = $scope;
    this._ = lodash;
    this.rootScope = $rootScope;
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.R = RestFactory;
    this.t = Drupal.t;


    //Custom code.
    this.user = {};

    this.getUser();

  }

  getUser() {
    if(this.settings.pathArgument0 == 'user') {
      let uid = this.settings.pathArgument1;
      this.loading = true;
      this.R.User(uid).then(
        (res) => {
          this.user = res.data[0];
        },
        (err) => {
        }
      ).finally(() => { this.loading = false });
    }
  }

}

export default ProfileController;
