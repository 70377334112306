import NewsListComponent from './newsList.component';

let NewsListModule = angular.module('app.shared.components.news.newslist', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('newsList', NewsListComponent);

export default NewsListModule;
