class NewsItemController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $mdDialog,
              $sce,
              AppSettings,
              LocaleFactory,
              FormatsFactory) {


    //common features.
    this.settings = AppSettings;
    this.Dialog = $mdDialog;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.name = 'NewsItemController';
    this.scope = $scope;
    this.item = $scope.item;

    //Custom code.


  }

  showDialog() {

    this.Dialog.show({
        locals: {
          item: this.item,
          dialog: this.Dialog
        },
        templateUrl: this.settings.templates + 'newsDialog.html',
        parent: angular.element(document.body),
        controller: function ($scope,
                              $mdDialog,
                              LocaleFactory,
                              item,
                              dialog,
                              AppSettings) {

          "ngInject";

          $scope.locale = LocaleFactory;
          $scope.settings = AppSettings;
          $scope.item = item;

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }
      })
      .then(
        (res) => {
        }
      );//then

  }//open dialog



}

export default NewsItemController;
