import UserWonBidsController from './userWonBids.controller';

let UserWonBidsComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'userWonBids.html',
    controller: UserWonBidsController,
    controllerAs: "vm",
    scope: {}
  }

};

export default UserWonBidsComponent;
