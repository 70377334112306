class UserLiveItemController {
  /*@ngInject*/

  constructor (
    $rootScope,
    $scope,
    $sce,
    AppSettings,
    LocaleFactory,
    FormatsFactory
    ) {

      //common features.
      this.settings = AppSettings;
      this.locale = LocaleFactory;
      this.formats = FormatsFactory;
      this.trustAsHtml = $sce.trustAsHtml;
      this.t = Drupal.t;
      this.name = 'UserLiveItemController';
      this.lot = $scope.lot;
      this.user = $scope.user;
      this.isLive = $scope.live;

      //Custom code.

  }
}

export default UserLiveItemController;
