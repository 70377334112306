import UserProfile from './profile/profile';
import UserLots from './userLots/userLots';
import UserFavs from './userFavLots/userFavLots';
import UserMyLots from './userMyLots/userMyLots';
import UserListTab from './userListTab/userListTab'
import UserWonBids from './userWonBids/userWonBids';
import UserLive from './userLive/userLive';
import UserLiveItem from './userLiveItem/userLiveItem';

let UserComponentsModule = angular.module('app.shared.components.user', [
  UserProfile.name,
  UserLots.name,
  UserFavs.name,
  UserMyLots.name,
  UserListTab.name,
  UserWonBids.name,
  UserLive.name,
  UserLiveItem.name,
]);

export default UserComponentsModule;
