class AuctionInfoController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              AppSettings,
              LocaleFactory,
              FormatsFactory) {

    //common features.
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.sce = $sce;
    this.t = Drupal.t;
    this.name = 'AuctionInfoController';

    //Custom code.
    this.endTime = $scope.endTime || $scope.auction.closing_date;
    this.startTime = $scope.startTime || $scope.auction.opening_date;

  }

  getFormattedHtmlDate(date) {
    var output = this.formats.formatStartEndDates(date.start, date.end, 'd MMMM yyyy');
    return this.trustAsHtml(output);
  }
}

export default AuctionInfoController;
