import SearchPageComponent from './searchPage.component';

let SearchPageModule = angular.module('app.shared.components.searchpage', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('searchPage', SearchPageComponent);

export default SearchPageModule;
