class UserFavLotsController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              RestFactory,
              $cookies,
              AppSettings,
              LocaleFactory,
              FormatsFactory) {


    //common features.
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.R = RestFactory;
    this.name = 'UserFavLotsController';

    //Custom code.
    this.viewMode = $cookies.get('UserViewMode') || 'list';
    this.lots = [];
    this.itemCount = 0;
    this.pageRange = 21;
    this.loading = false;

    this.getFavLots();

    //EVENTS
    $scope.$on('UserViewModeChanged', (ev, mode, scope) => {
      this.viewMode = mode;
    });

  }

  getFavLots(page) {
    page = page || 1;
    this.loading = true;
    this.uid = this.settings.pathArgument1;
    this.R.flaggedLots(this.uid, this.pageRange, page)
      .then(
        (res) => {
          this.itemCount = res.count;
          this.lots = res.data;
        }
      )
      .finally(()=> {
        this.loading = false;
      })
  }

  changePage(page) {
    this.getFavLots(page)
  }
}

export default UserFavLotsController;
