import LotSinglePageComponent from './lotSinglePage.component';

let LotSinglePageModule = angular.module('app.shared.components.lot.lotsinglepage', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('lotSinglePage', LotSinglePageComponent);

export default LotSinglePageModule;
