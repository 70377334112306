class UserWonBidsController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              RestFactory,
              $cookies,
              $sce,
              AppSettings,
              LocaleFactory,
              FormatsFactory) {


    //common features.
    this.name = 'UserWonBidsController';
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.R = RestFactory;
    this.uid = this.settings.pathArgument1;
    this.viewMode = $cookies.get('UserViewMode') || 'list';

    //Custom code.
    this.loading = false;
    this.itemCount = 0;
    this.pageRange = 9;
    this.lots = [];

    this.getWonBids();
    //EVENTS
    $scope.$on('UserViewModeChanged', (ev, mode, scope) => {
      this.viewMode = mode;
    });

  }

  getWonBids(page) {
    page = page || 1;

    this.loading = true;
    let query = {
      'filter[order]': this.uid
    }

    this.R.userWonLots(this.uid, this.pageRange, page).then(
      (res) => {
        this.itemCount = res.count;
        this.lots = res.data;
      },
      (err) => {
      }
    ).finally(() => {
      this.loading = false;
    });

  }
}

export default UserWonBidsController;
