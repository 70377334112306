import AuctionItemComponent from './auctionItem.component';

let AuctionItemModule = angular.module('app.shared.components.auction.auctionitem', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
  'ngLodash'
])
  .directive('auctionItem', AuctionItemComponent);

export default AuctionItemModule;
