import UserLiveController from './userLive.controller';

let UserLiveComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'userLive.html',
    controller: UserLiveController,
    controllerAs: "vm",
    scope: {}
  }

};

export default UserLiveComponent;
