class UserLiveController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              $state,
              $stateParams,
              RestFactory,
              AppSettings,
              LocaleFactory,
              FormatsFactory) {


    //common features.
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.name = 'UserLiveController';
    this.R = RestFactory;

    //Custom code.
    this.uid = $scope.uid || parseInt(AppSettings.user.id);
    this.loading = false;
    this.lotsCount = 0;
    this.lots = [];

    this.pageRange = AppSettings.variables.all_lots_range || 12;
    console.log(this.pagesRange);
    this.hidePaging = false;

    this.getUser(this.uid);
    this.getUserLots(1);
  }

  getUser(uid) {
    this.R.User(uid).then(
      (res) => {
        this.user = res.data[0];
      }
    );
  }
  getUserLots(page) {
    this.loading = true;
    this.R.userLiveLots(this.uid, this.pageRange, page,  {sort: 'timeout'} ).then(res => {
      this.lots = res.data;
      this.lotsCount = res.count;
    }, err => {
      console.log(err);
    }).finally(() => { this.loading = false; });
  }
  pageChanged(page) {
    console.log(page);
    this.getUserLots(page);
  }
}

export default UserLiveController;
