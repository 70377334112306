import UserLiveItemController from './userLiveItem.controller';

let UserLiveItemComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'userLiveItem.html',
    controller: UserLiveItemController,
    controllerAs: "vm",
    scope: {
      lot: "=",
      user: "=",
      live: "=",
    }
  }

};

export default UserLiveItemComponent;
