import AllLotsController from './allLots.controller';

let AllLotsComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'allLots_v1.html',
    controller: AllLotsController,
    controllerAs: "vm",
    scope: {}
  }

};

export default AllLotsComponent;
