class UserMyLotsController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              AppSettings,
              RestFactory,
              $cookies,
              LocaleFactory,
              FormatsFactory) {

    //common features.
    this.name = 'UserLotsController';
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.R = RestFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;


    //Custom code.
    this.viewMode = $cookies.get('UserViewMode') || 'list';
    this.pageRange = 6;
    this.selectedTime = 1;
    this.startDate = moment().unix();
    this.endDate = moment().subtract(10, 'days').unix();

    this.lots = [];
    this.loading = false;
    this.itemCount = 0;
//    this.awardedCount = 0;
    this.uid = parseInt(this.settings.pathArgument1);

    this.getUser(this.uid);
    this.getUserLots();

    //EVENTS
    $scope.$on('UserViewModeChanged', (ev, mode, scope) => {
      this.viewMode = mode;
    });

  }

  searchPeriod(start, end, time) {
    this.startDate = null;
    this.endDate = null;
    this.selectedTime = time;

    if (start) {
      this.startDate = moment().subtract(start, 'days').unix();
    }

    if (end) {
      this.endDate = moment().subtract(end, 'days').unix();
    }

    this.getUserLots(1);
  }


  getUser(uid) {
    this.R.User(uid).then(
      (res) => {
        this.user = res.data[0];
      }
    );
  }

  getUserLots(page) {
    this.loading = true;
    let query = {};
    if (this.startDate) {
      query['starting_date'] = this.startDate;
    }

    if (this.endDate) {
      query['ending_date'] = this.endDate;
    }

    this.R.userLots(this.uid, this.pageRange, page, query).then(
      (res) => {
        this.itemCount = res.count;
        this.lots = res.data;
//        this.lots = [];
//        angular.forEach(res.data, (lot) => {
//          lot.awarded = lot.order && (parseInt(lot.order.uid) == this.uid);
//          lot.awarded ? this.awardedCount += 1 : '';
//          this.lots.push(lot);
//        });
      },
      (err) => {
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  pageChanged(page) {
    this.getUserLots(page);
  }
}
export default UserMyLotsController;
