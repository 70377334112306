class CategoriesController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              AppSettings,
              RestFactory,
              LocaleFactory,
              FormatsFactory) {

    Drupal.behaviors.angmod = (function (context) {

      //common features.
      this.name = 'CategoriesController';
      this.R = RestFactory;
      this.settings = AppSettings;
      this.locale = LocaleFactory;
      this.formats = FormatsFactory;
      this.trustAsHtml = $sce.trustAsHtml;
      this.t = Drupal.t;
      this.scope = $scope;
      this.tid = $scope.tid;


      //Custom code.
      this.categories = [];
      this.getCategories();


    }.bind(this))();
  }

  getCategories() {
    this.R.parentCategories().then(
      (res) => {
        this.categories = res.data;
      }
    );
  }
}

export default CategoriesController;
