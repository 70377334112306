import AuctionInfoController from './auctionInfo.controller';

let AuctionInfoComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'auctionInfo.html',
    controller: AuctionInfoController,
    controllerAs: "vm",
    scope: {
      'auction': "=",
      'endTime': "=",
      'startTime': "="
    }
  }

};

export default AuctionInfoComponent;
