import LotListController from './lotList.controller';

let LotListComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'lotList_v2.html',
    controller: LotListController,
    controllerAs: "vm",
    scope: {
      range: "@",
      sort: '@'
    }
  }
};

export default LotListComponent;
