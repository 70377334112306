class NewsListController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              AppSettings,
              RestFactory,
              LocaleFactory,
              FormatsFactory) {

    Drupal.behaviors.angmod = (function (context) {

      //common features.
      this.settings = AppSettings;
      this.locale = LocaleFactory;
      this.formats = FormatsFactory;
      this.trustAsHtml = $sce.trustAsHtml;
      this.t = Drupal.t;
      this.name = 'NewsListController';
      this.R = RestFactory;

      //Custom code.
      this.range = AppSettings.variables.news_range || 3;
      this.count = 0;

      this.loading = false;
      this.news = [];

      this.fetchNews(this.range)


    }.bind(this))();
  }

  fetchNews(range, page) {
    this.loading = true;
    this.R.news({}, null, range, 1).then(
      (res) => {
        this.news = res.data;
        this.count = res.count;
      },
      (err) => {
        this.count = 0;
      }
    ).finally(() => { this.loading = false; })

  }
}

export default NewsListController;
