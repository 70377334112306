import UserFavLotsComponent from './userFavLots.component';

let UserFavLotsModule = angular.module('app.shared.components.user.userfavlots', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('userFavLots', UserFavLotsComponent);

export default UserFavLotsModule;
