import AuctionSinglePageComponent from './auctionSinglePage.component';

let AuctionSinglePageModule = angular.module('app.shared.components.auction.auctionsinglepage', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('auctionSinglePage', AuctionSinglePageComponent);

export default AuctionSinglePageModule;
