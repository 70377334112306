let CapitalizeModule = angular.module('app.shared.filters.capitalize', [])
  .filter('capitalize', () => {
    "ngInject";
    return (input, all) => {
      var reg = (all) ? /([^\W_]+[^\s-]*) */g : /([^\W_]+[^\s-]*)/;
      return (!!input) ? input.replace(reg, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }) : '';
    }
  });

export default CapitalizeModule;
