import LotBidComponent from './lotBid.component';

let LotBidModule = angular.module('app.shared.components.lot.lotbid', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
  'ui.utils.masks'
])
  .directive('lotBid', LotBidComponent);

export default LotBidModule;
