(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AppComponent = function AppComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'app.auction_v1.html'
  };
};

exports.default = AppComponent;

},{}],2:[function(require,module,exports){
'use strict';

var _appComponent = require('./app.component.js');

var _appComponent2 = _interopRequireDefault(_appComponent);

var _components = require('./components/components');

var _components2 = _interopRequireDefault(_components);

var _shared = require('./../../shared/shared.module');

var _shared2 = _interopRequireDefault(_shared);

var _searchBar = require('./../../shared/components/searchBar/searchBar');

var _searchBar2 = _interopRequireDefault(_searchBar);

var _searchPage = require('./../../shared/components/searchPage/searchPage');

var _searchPage2 = _interopRequireDefault(_searchPage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('app.auction', ['ui.router', 'ngAnimate', 'ngSanitize', 'ngMaterial', 'timer', 'bw.paging', 'jkuri.gallery',
//    'thatisuday.ng-image-gallery',
_shared2.default.name, _components2.default.name, _searchPage2.default.name, _searchBar2.default.name]).directive('app', _appComponent2.default).config(function ($httpProvider, $mdIconProvider, $mdThemingProvider) {
  "use strict";

  //Theming

  $mdThemingProvider.definePalette('BopaPalette', {
    '50': '#ffffff',
    '100': '#f9e4e0',
    '200': '#efbbb1',
    '300': '#e38776',
    '400': '#de715c',
    '500': '#d95b43',
    '600': '#d3462b',
    '700': '#b93d25',
    '800': '#a03520',
    '900': '#862c1b',
    'A100': '#ffffff',
    'A200': '#f9e4e0',
    'A400': '#de715c',
    'A700': '#b93d25',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': '50 100 200 300 400 A100 A200 A400'
  });

  $mdThemingProvider.theme('default').primaryPalette('BopaPalette');
  //      .accentPalette('BopaPalette');
}).run(['$rootScope', '$state', function ($rootScope, $state, $stateParams) {
  "ngInject";

  $rootScope.$state = $state;
  $rootScope.$stateParams = $stateParams;

  $rootScope.$on('$stateChangeStart', function () {
    $rootScope.stateIsLoading = true;
  });

  $rootScope.$on('$stateChangeSuccess', function () {
    $rootScope.stateIsLoading = false;
  });
}]);

},{"./../../shared/components/searchBar/searchBar":58,"./../../shared/components/searchPage/searchPage":61,"./../../shared/shared.module":106,"./app.component.js":1,"./components/components":3}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _navbar = require('./navbar/navbar');

var _navbar2 = _interopRequireDefault(_navbar);

var _home = require('./home/home');

var _home2 = _interopRequireDefault(_home);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AppComponents = angular.module('app.components', [_home2.default.name, _navbar2.default.name]);

exports.default = AppComponents;

},{"./home/home":6,"./navbar/navbar":9}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _home = require('./home.controller');

var _home2 = _interopRequireDefault(_home);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HomeComponent = function HomeComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'auctions.home.html',
    controller: _home2.default,
    controllerAs: "vm",
    scope: {}
  };
};

exports.default = HomeComponent;

},{"./home.controller":5}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HomeController =
/*@ngInject*/

function HomeController($rootScope, $scope, AppSettings, $sce, LocaleFactory) {
  _classCallCheck(this, HomeController);

  //common features.
  this.settings = AppSettings;
  this.trustAsHtml = $sce.trustAsHtml;
  this.t = Drupal.t;
  this.locale = LocaleFactory;
  this.name = 'HomeController';
};

exports.default = HomeController;

},{}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _home = require('./home.component');

var _home2 = _interopRequireDefault(_home);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HomeModule = angular.module('app.components.home', [
  //    'ui.router'
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).config(function ($stateProvider, $urlRouterProvider, $locationProvider) {
  "ngInject";

  $urlRouterProvider.otherwise('/');

  $stateProvider.state('home', { //App Home Page
    url: '/',
    template: "<home></home>"
  }).state('auction', { // Single Auction Page
    url: '/auction/:id/:page',
    template: "<auction-single-page></auction-single-page>"
  }).state('lot', { // Single Lot Page
    url: '/lot/:id',
    template: "<lot-single-page>"
  }).state('search', { // Search Page
    url: '/search/:term?',
    template: "<search-page>"
  }).state('404', {
    url: '/404',
    template: '<strong>Page Not Found (404)</strong>'
  });

  //    $locationProvider.html5Mode({
  //      enabled: true,
  //    });
}).directive('home', _home2.default);

exports.default = HomeModule;

},{"./home.component":4}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _navbar = require('./navbar.controller');

var _navbar2 = _interopRequireDefault(_navbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NavbarComponent = function NavbarComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'auctions.navbar_v1.html',
    controller: _navbar2.default,
    controllerAs: "vm",
    scope: {
      type: "@", //The node types to search for.
      text: "@",
      global: "@" //whether to search for all open items or current auction
    },
    bindToController: true
  };
};

exports.default = NavbarComponent;

},{"./navbar.controller":8}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var NavbarController = function () {
  /*@ngInject*/

  function NavbarController($rootScope, $scope, AppSettings, $sce, LocaleFactory, $cookies, EventHelper) {
    var _this = this;

    _classCallCheck(this, NavbarController);

    //common features.
    this.rootScope = $rootScope;
    this.scope = $scope;
    this.settings = AppSettings;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.EventHelper = EventHelper;
    this.locale = LocaleFactory;
    this.$cookies = $cookies;
    this.inputText = this.text || this.locale.Search;
    this.name = 'NavbarController';
    this.activeViewMode = $cookies.get('auctionActiveViewMode') || 'list';
    this.showViewMode = true;
    AppSettings.resetFilters();

    this.changeViewMode = function (mode) {
      _this.broadcastViewModeChanged(mode);
    };

    $scope.$on('auctionFiltersChanged', function (ev, data) {
      _this.showViewMode = data !== 'live';
    });
  }

  _createClass(NavbarController, [{
    key: 'doSearch',
    value: function doSearch() {
      var text = this.scope.searchText;
      if (angular.isDefined(text) && text.length >= 3) {
        this.EventHelper.broadcastGlobalEvent('doSearch', {
          'scope': this,
          'searchText': text,
          'type': this.type
        });

        this.settings.searchText = text;
        this.scope.searchText = '';
      }
    }
  }, {
    key: 'cancelSearch',
    value: function cancelSearch() {
      this.EventHelper.broadcastGlobalEvent('cancelSearch', {
        'scope': this,
        'type': this.type
      });

      this.settings.searchText = null;
      this.settings.category = null;
      this.settings.categoryName = null;
    }
  }, {
    key: 'broadcastViewModeChanged',
    value: function broadcastViewModeChanged(mode) {
      this.activeViewMode = mode;
      var today = new Date();
      var expire = new Date();
      expire.setDate(today.getDate() + 30);
      this.$cookies.put('auctionActiveViewMode', mode, { expires: expire });
      this.EventHelper.broadcastGlobalEvent('AuctionViewModeChanged', mode, this);
    }
  }, {
    key: 'formatArrayToString',
    value: function formatArrayToString(array) {
      return array.split(',');
    }
  }]);

  return NavbarController;
}();

exports.default = NavbarController;

},{}],9:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _navbar = require('./navbar.component');

var _navbar2 = _interopRequireDefault(_navbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NavBarModule = angular.module('app.components.navbar', ['ui.router', 'ngCookies']).directive('navbar', _navbar2.default);

exports.default = NavBarModule;

},{"./navbar.component":7}],10:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _auctionList = require('./auctionList/auctionList');

var _auctionList2 = _interopRequireDefault(_auctionList);

var _auctionItem = require('./auctionitem/auctionItem');

var _auctionItem2 = _interopRequireDefault(_auctionItem);

var _auctionSinglePage = require('./auctionSinglePage/auctionSinglePage');

var _auctionSinglePage2 = _interopRequireDefault(_auctionSinglePage);

var _auctionInfo = require('./auctionInfo/auctionInfo');

var _auctionInfo2 = _interopRequireDefault(_auctionInfo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AuctionComponentsModule = angular.module('app.shared.components.auction', [_auctionList2.default.name, _auctionItem2.default.name, _auctionSinglePage2.default.name, _auctionInfo2.default.name]);

exports.default = AuctionComponentsModule;

},{"./auctionInfo/auctionInfo":13,"./auctionList/auctionList":16,"./auctionSinglePage/auctionSinglePage":19,"./auctionitem/auctionItem":22}],11:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _auctionInfo = require('./auctionInfo.controller');

var _auctionInfo2 = _interopRequireDefault(_auctionInfo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AuctionInfoComponent = function AuctionInfoComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'auctionInfo.html',
    controller: _auctionInfo2.default,
    controllerAs: "vm",
    scope: {
      'auction': "=",
      'endTime': "=",
      'startTime': "="
    }
  };
};

exports.default = AuctionInfoComponent;

},{"./auctionInfo.controller":12}],12:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AuctionInfoController = function () {
  /*@ngInject*/

  function AuctionInfoController($rootScope, $scope, $sce, AppSettings, LocaleFactory, FormatsFactory) {
    _classCallCheck(this, AuctionInfoController);

    //common features.
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.sce = $sce;
    this.t = Drupal.t;
    this.name = 'AuctionInfoController';

    //Custom code.
    this.endTime = $scope.endTime || $scope.auction.closing_date;
    this.startTime = $scope.startTime || $scope.auction.opening_date;
  }

  _createClass(AuctionInfoController, [{
    key: 'getFormattedHtmlDate',
    value: function getFormattedHtmlDate(date) {
      var output = this.formats.formatStartEndDates(date.start, date.end, 'd MMMM yyyy');
      return this.trustAsHtml(output);
    }
  }]);

  return AuctionInfoController;
}();

exports.default = AuctionInfoController;

},{}],13:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _auctionInfo = require('./auctionInfo.component');

var _auctionInfo2 = _interopRequireDefault(_auctionInfo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AuctionInfoModule = angular.module('app.shared.components.auction.auctioninfo', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('auctionInfo', _auctionInfo2.default);

exports.default = AuctionInfoModule;

},{"./auctionInfo.component":11}],14:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _auctionList = require("./auctionList.controller");

var _auctionList2 = _interopRequireDefault(_auctionList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AuctionListComponent = function AuctionListComponent(AppSettings) {
  "use strict";
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'auctionList_v1.html',
    controller: _auctionList2.default,
    controllerAs: "vm",
    scope: {
      range: "@"
    }
  };
};

exports.default = AuctionListComponent;

},{"./auctionList.controller":15}],15:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AuctionListController = function () {
  /*@ngInject*/
  function AuctionListController($rootScope, $scope, $cookies, AppSettings, $sce, RestFactory, EventHelper, LocaleFactory) {
    var _this = this;

    _classCallCheck(this, AuctionListController);

    //common features.
    this.name = 'AuctionListController';
    this.$cookies = $cookies;
    this.settings = AppSettings;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.scope = $scope;
    this.eventHelper = EventHelper;
    this.locale = LocaleFactory;
    this.noItemFound = LocaleFactory.noItemFound;
    this.viewMode = this.$cookies.get('auctionActiveViewMode') || 'list';
    this.loading = null;
    this.R = RestFactory;
    this.requestContext = 'controller';
    this.searchedText = '';
    this.showLiveButton = false;
    this.auctions = [];
    this.range = $scope.range || AppSettings.pagesRange;
    this.itemCount = 0;
    this.context = 'auctions';
    //    this.fetchAuctions(1);
    this.query = {};
    this.showingPeriod = this.locale.OurAuction;
    this.periodClass = 'actual';
    this.filterAuctions('our');
    this.pastAuctionsDays = AppSettings.variables.past_auctions_days || 90;
    var isBidder = AppSettings.isBidder();
    if (isBidder) {
      var uid = AppSettings.user.id;
      RestFactory.userLiveLots(uid, 1, 1, { 'fields': 'id,nid' }).then(function (res) {
        _this.showLiveButton = res.count && res.count > 0;
      }, function (err) {
        console.log(err);
      });
    }

    //RootScope events
    $scope.$on('AuctionViewModeChanged', function (ev, mode, scope) {
      _this.viewMode = mode;
    });

    $scope.$on('cancelSearch', function (ev, data) {
      if (data.type == 'auctions') {
        _this.searchedText = '';
        _this.fetchAuctions();
      }
    });

    $scope.$on('doSearch', function (ev, data) {
      _this.searchedText = data.searchText;
      _this.requestContext = 'search';

      if (_this.searchedText.length >= 3 && data.type == 'auctions') {
        _this.searchAuctions();
      } //if
    }); //$on doSearch;

  }

  _createClass(AuctionListController, [{
    key: 'showLive',
    value: function showLive() {
      this.context = 'live';
      this.periodClass = 'live';
      this.periodViewClass = 'live';
      this.eventHelper.broadcastGlobalEvent('auctionFiltersChanged', 'live', this);
    }
  }, {
    key: 'filterAuctions',
    value: function filterAuctions(filter) {

      // time-line: 0=Past, 1=Current, 2=Future
      // 1. Our auctions: time=current+future, taxonomy=all taxonomy without retails and acquisitions
      // 22/01/2017: (Changed after client request to also show retails and acquisitions)
      // 02/07/2018: (Changed after client request to also show retails and acquisitions)
      // 2. Retails: time=current+future, taxonomy=retails(winkelverkopen), tid=131
      // 3. Acquisitions: time=current+future, taxonomy=acquisitions(overnames), tid=136
      // 4. Past: time=past, taxonomy=all
      this.context = 'auctions';
      this.settings.searchText = '';
      this.settings.categoryName = '';
      this.query = {};
      this.eventHelper.broadcastGlobalEvent('auctionFiltersChanged', filter, this);
      switch (filter) {
        case 'our':
          this.query['timeline'] = '1,2';
          // this.query['reason'] = '136';
          // this.query['reasonOperator'] = 'NOT IN';
          this.query['sort'] = 'timeline,closing_date';
          this.period = this.locale.OurAuctions;
          this.periodClass = 'our';
          break;
        case 'retails':
          this.query['timeline'] = '1,2';
          this.query['reason'] = '131';
          this.query['sort'] = 'timeline,closing_date';
          this.period = this.locale.RetailAuctions;
          this.periodClass = 'retails';
          break;
        case 'acquisitions':
          this.query['timeline'] = '1,2';
          this.query['reason'] = '136';
          this.query['sort'] = 'timeline,closing_date';
          this.period = this.locale.AcquisitionAuctions;
          this.periodClass = 'acquisitions';
          break;
        case 'past':
          var days = parseInt(this.pastAuctionsDays, 10);
          var startFrom = moment().subtract(days, 'd').unix();
          this.query['timeline'] = '0';
          this.query['filter[closing_date][value]'] = startFrom;
          this.query['filter[closing_date][operator]'] = ">=";
          this.query['sort'] = '-closing_date';
          // this.query['range'] = 10;
          this.period = this.locale.PastAuctions;
          this.periodClass = 'past';
          break;

      }
      this.fetchAuctions();
    }
  }, {
    key: 'pageChanged',
    value: function pageChanged(page) {
      switch (this.requestContext) {
        case 'controller':
          this.fetchAuctions(page);
          break;
        case 'search':
          this.searchAuctions(page);
          break;
      }
    }
  }, {
    key: 'searchAuctions',
    value: function searchAuctions(page) {
      var _this2 = this;

      page = page || 1;

      var query = {
        'filter[type]': 'auction'
      };

      this.loading = true;
      this.periodViewClass = '';
      var count = 0;

      this.R.search(this.searchedText, query, this.range, page).then(function (res) {
        var auctions = [];
        if (res.data) {
          angular.forEach(res.data[0], function (item) {
            item.resource[0].relevance = item.relevance;
            auctions.push(item.resource[0]);
            count = item.count;
          });
          _this2.auctions = auctions;
          _this2.showingPeriod = _this2.locale.AllAuctions;
        } //if res.data
        else {
            _this2.auctions = [];
            count = 0;
          } //else res.data
      }).finally(function () {
        _this2.loading = false;
        _this2.itemCount = parseInt(count);
      });
    }
  }, {
    key: 'fetchAuctions',
    //search Auctions

    value: function fetchAuctions(page) {
      var _this3 = this;

      page = page || 1;
      var Auctions = this.R.auctions(this.query, null, this.range, page);
      this.loading = true;

      Auctions.then(function (res) {
        _this3.auctions = res.data;
        _this3.itemCount = res.count;
        _this3.showingPeriod = _this3.period;
        _this3.periodViewClass = _this3.periodClass;
      }).finally(function () {
        _this3.loading = false;
      });
    }
  }]);

  return AuctionListController;
}();

exports.default = AuctionListController;

},{}],16:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _auctionList = require('./auctionList.component');

var _auctionList2 = _interopRequireDefault(_auctionList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AuctionListModule = angular.module('app.shared.components.auction.list', ['ngAnimate', 'ngMaterial', 'ngCookies', 'ngAnimate']).directive('auctionList', _auctionList2.default);

exports.default = AuctionListModule;

},{"./auctionList.component":14}],17:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _auctionSinglePage = require('./auctionSinglePage.controller');

var _auctionSinglePage2 = _interopRequireDefault(_auctionSinglePage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AuctionSinglePageComponent = function AuctionSinglePageComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'auctionSinglePage_v2.html',
    controller: _auctionSinglePage2.default,
    controllerAs: "vm",
    scope: {}
  };
};

exports.default = AuctionSinglePageComponent;

},{"./auctionSinglePage.controller":18}],18:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AuctionSinglePageController = function () {
  /*@ngInject*/

  function AuctionSinglePageController($rootScope, $scope, $stateParams, AppSettings, $sce, $timeout, LocaleFactory, FormatsFactory, RestFactory) {
    _classCallCheck(this, AuctionSinglePageController);

    //common features.
    this.rest = RestFactory;
    this.params = $stateParams;
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.$timeout = $timeout;
    this.loading = false;
    this.galleryPhotos = [];

    this.timelineStatus = {
      0: this.locale.PastAuction,
      1: this.locale.ActualAuctions,
      2: this.locale.FutureAuction
    };

    this.timeline = '';
    this.timelineIndex = '';

    this.name = 'AuctionSinglePageController';

    this.auction = [];

    this.expired = false;
    this.open = false;
    this.suspended = false;
    this.closed = false;

    this.getAuction();
  }

  _createClass(AuctionSinglePageController, [{
    key: 'getAuction',
    value: function getAuction() {
      var _this = this;

      this.loading = true;
      var auction = this.rest.auction(this.params.id, 'square,gallery_1200');
      auction.then(function (res) {
        var auction = res.data[0];
        _this.auction = auction;
        _this.expired = auction.expired;
        _this.openingDateStatus = _this.formats.getDateStatus(auction.opening_date);
        _this.closingDateStatus = _this.formats.getDateStatus(auction.closing_date);

        _this.timelineIndex = parseInt(auction.timeline);
        _this.timeline = _this.timelineStatus[_this.timelineIndex];
        _this.open = auction.item_status == '0';
        _this.closed = auction.item_status == '1';
        _this.suspended = auction.item_status == '2';

        _this.galleryPhotos = [];
        angular.forEach(_this.auction.gallery, function (photo) {
          _this.galleryPhotos.push({
            thumb: photo.styled_url || photo.url,
            img: photo.gallery_1200_url,
            title: photo.title
          });
        });
      }).finally(function () {
        _this.loading = false;
      });
    }
  }]);

  return AuctionSinglePageController;
}();

exports.default = AuctionSinglePageController;

},{}],19:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _auctionSinglePage = require('./auctionSinglePage.component');

var _auctionSinglePage2 = _interopRequireDefault(_auctionSinglePage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AuctionSinglePageModule = angular.module('app.shared.components.auction.auctionsinglepage', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('auctionSinglePage', _auctionSinglePage2.default);

exports.default = AuctionSinglePageModule;

},{"./auctionSinglePage.component":17}],20:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _auctionItem = require('./auctionItem.controller');

var _auctionItem2 = _interopRequireDefault(_auctionItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AuctionItemComponent = function AuctionItemComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'auctionItem.html',
    controller: _auctionItem2.default,
    controllerAs: "vm",
    scope: {
      auction: "="
    }
  };
};

exports.default = AuctionItemComponent;

},{"./auctionItem.controller":21}],21:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AuctionItemController =
/*@ngInject*/

function AuctionItemController($rootScope, $scope, AppSettings, $sce, $filter, LocaleFactory, FormatsFactory) {
  _classCallCheck(this, AuctionItemController);

  //common features.
  this.filter = $filter;
  this.locale = LocaleFactory;
  this.format = FormatsFactory;
  this.settings = AppSettings;

  this.trustAsHtml = $sce.trustAsHtml;
  this.t = Drupal.t;
  this.name = 'AuctionItemController';
};

exports.default = AuctionItemController;

},{}],22:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _auctionItem = require('./auctionItem.component');

var _auctionItem2 = _interopRequireDefault(_auctionItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AuctionItemModule = angular.module('app.shared.components.auction.auctionitem', [
//Add module dependencies here
//in the format.
/*OtherModule.name*/
'ngLodash']).directive('auctionItem', _auctionItem2.default);

exports.default = AuctionItemModule;

},{"./auctionItem.component":20}],23:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _categories = require('./categories.controller');

var _categories2 = _interopRequireDefault(_categories);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CategoriesComponent = function CategoriesComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'categories.html',
    controller: _categories2.default,
    controllerAs: "vm",
    scope: {
      tid: "="
    }
  };
};

exports.default = CategoriesComponent;

},{"./categories.controller":24}],24:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CategoriesController = function () {
  /*@ngInject*/

  function CategoriesController($rootScope, $scope, $sce, AppSettings, RestFactory, LocaleFactory, FormatsFactory) {
    _classCallCheck(this, CategoriesController);

    Drupal.behaviors.angmod = function (context) {

      //common features.
      this.name = 'CategoriesController';
      this.R = RestFactory;
      this.settings = AppSettings;
      this.locale = LocaleFactory;
      this.formats = FormatsFactory;
      this.trustAsHtml = $sce.trustAsHtml;
      this.t = Drupal.t;
      this.scope = $scope;
      this.tid = $scope.tid;

      //Custom code.
      this.categories = [];
      this.getCategories();
    }.bind(this)();
  }

  _createClass(CategoriesController, [{
    key: 'getCategories',
    value: function getCategories() {
      var _this = this;

      this.R.parentCategories().then(function (res) {
        _this.categories = res.data;
      });
    }
  }]);

  return CategoriesController;
}();

exports.default = CategoriesController;

},{}],25:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _categories = require('./categories.component');

var _categories2 = _interopRequireDefault(_categories);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CategoriesModule = angular.module('app.shared.components.categories', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('categories', _categories2.default);

exports.default = CategoriesModule;

},{"./categories.component":23}],26:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _category = require('./category.controller');

var _category2 = _interopRequireDefault(_category);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CategoryComponent = function CategoryComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'category.html',
    controller: _category2.default,
    controllerAs: "vm",
    scope: {
      item: "="
    }
  };
};

exports.default = CategoryComponent;

},{"./category.controller":27}],27:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CategoryController = function () {
  /*@ngInject*/

  function CategoryController($rootScope, $scope, $sce, AppSettings, RestFactory, lodash, $stateParams, LocaleFactory, FormatsFactory) {
    _classCallCheck(this, CategoryController);

    Drupal.behaviors.angmod = function (context) {
      var _this = this;

      //common features.
      this.settings = AppSettings;
      this.locale = LocaleFactory;
      this.formats = FormatsFactory;
      this.trustAsHtml = $sce.trustAsHtml;
      this.t = Drupal.t;
      this.name = 'CategoryController';
      this.item = $scope.item;
      this.scope = $scope;
      this.rootScope = $rootScope;
      this.R = RestFactory;
      this._ = lodash;
      this.loading = false;

      //Custom code.
      this.children = [];

      if ($stateParams.category) {
        if ($stateParams.category == $scope.item.id) {
          $scope.item.selected = true;
          this.settings.category = $scope.item.id;
          this.settings.categoryName = $scope.item.title;
        }
      }

      $scope.$on('categoryChanged', function (ev, data) {
        //Already selected
        if ($scope.item.selected && data.id == $scope.item.id) {
          $scope.item.selected = false;
          this.settings.category = null;
          this.settings.categoryName = '';
        } else {
          $scope.item.selected = $scope.item.id === data.id;
        }
        AppSettings.searchText = '';
      });

      $scope.$on('cancelSearch', function (ev, data) {
        $scope.item.selected = false;
      });

      $scope.$on('doSearch', function (ev, data) {
        $scope.item.selected = false;
        _this.settings.category = null;
        _this.settings.categoryName = '';
      });
    }.bind(this)();
  }

  _createClass(CategoryController, [{
    key: 'categorySelected',
    value: function categorySelected() {
      if (this.item.selected) {
        this.rootScope.$broadcast('categoryChanged', 0);
        this.settings.category = null;
        this.settings.categoryName = null;
        this.item.selcted = false;
      } else {
        this.rootScope.$broadcast('categoryChanged', this.item);
        this.toggleChildren();
        this.settings.category = this.item.id;
        this.settings.categoryName = this.item.title;
        this.item.selected = true;
      }
    }
  }, {
    key: 'toggleChildren',
    value: function toggleChildren() {
      var _this2 = this;

      //if children in not empty we eant to remove them,
      if (this.children.length) {
        this.children = [];
        this.item.class = '';
      } else {
        //Else we need to fecth children
        this.loading = true;
        this.R.categories(this.item.tid).then(function (res) {
          //get tids of children
          if (res.data[0].children.length) {
            var tids = _this2._.map(res.data[0].children, function (t) {
              return t.id;
            });

            //get all children categories.
            _this2.R.categories(tids).then(function (res) {
              _this2.children = res.data;
              _this2.item.class = 'expanded';
            }).finally(function () {
              _this2.loading = false;
            });
          }
        });
      } //else-if
    }
  }]);

  return CategoryController;
}();

exports.default = CategoryController;

},{}],28:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _category = require('./category.component');

var _category2 = _interopRequireDefault(_category);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CategoryModule = angular.module('app.shared.components.category', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('category', _category2.default);

exports.default = CategoryModule;

},{"./category.component":26}],29:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _auction = require('./auction/auction.components');

var _auction2 = _interopRequireDefault(_auction);

var _lot = require('./lot/lot.components');

var _lot2 = _interopRequireDefault(_lot);

var _category = require('./category/category');

var _category2 = _interopRequireDefault(_category);

var _categories = require('./categories/categories');

var _categories2 = _interopRequireDefault(_categories);

var _newsList = require('./news/newsList/newsList');

var _newsList2 = _interopRequireDefault(_newsList);

var _newsItem = require('./news/newsItem/newsItem');

var _newsItem2 = _interopRequireDefault(_newsItem);

var _userComponents = require('./user/user-components');

var _userComponents2 = _interopRequireDefault(_userComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SharedComponentsModule = angular.module('app.shared.components', [_category2.default.name, _categories2.default.name, _auction2.default.name, _lot2.default.name, _newsList2.default.name, _newsItem2.default.name, _userComponents2.default.name]);

exports.default = SharedComponentsModule;

},{"./auction/auction.components":10,"./categories/categories":25,"./category/category":28,"./lot/lot.components":36,"./news/newsItem/newsItem":52,"./news/newsList/newsList":55,"./user/user-components":65}],30:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _allLots = require('./allLots.controller');

var _allLots2 = _interopRequireDefault(_allLots);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AllLotsComponent = function AllLotsComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'allLots_v1.html',
    controller: _allLots2.default,
    controllerAs: "vm",
    scope: {}
  };
};

exports.default = AllLotsComponent;

},{"./allLots.controller":31}],31:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AllLotsController = function () {
  /*@ngInject*/

  function AllLotsController($rootScope, $scope, $stateParams, $state, $cookies, AppSettings, $sce, LocaleFactory, RestFactory, FormatsFactory) {
    _classCallCheck(this, AllLotsController);

    Drupal.behaviors.angmod = function (context) {
      var _this = this;

      //common features.
      this.name = 'AllLotsController';
      this.settings = AppSettings;
      this.pageRange = AppSettings.variables.all_lots_range || 12;
      this.scope = $scope;
      this.locale = LocaleFactory;
      this.formats = FormatsFactory;
      this.R = RestFactory;
      this.state = $state;
      this.stateParams = $stateParams;
      this.trustAsHtml = $sce.trustAsHtml;
      this.t = Drupal.t;
      this.viewMode = $cookies.get('auctionActiveViewMode') || 'list';

      //Custom code.
      this.loading = false;
      this.lots = [];
      this.requestContext = 'controller';
      this.searchedText = '';
      this.showPager = true;
      this.itemCount = 0;

      this.page = $stateParams.page || 1;
      this.category = $stateParams.category || null;

      if ($stateParams.period == 'false') {
        $stateParams.period = 'actual';
      }

      this.periodClass = $stateParams.period ? $stateParams.period : 'actual';

      this.createPeriodQuery(this.periodClass);

      this.showingPeriod = this.locale.ActualLots;

      this.getAllLots();

      $scope.$on('AuctionViewModeChanged', function (ev, mode, scope) {
        _this.viewMode = mode;
      });

      $scope.$on('cancelSearch', function (ev, data) {
        if (data.type == 'lots') {
          _this.searchedText = '';
          _this.settings.category = null;
          _this.settings.categoryName = null;
          _this.query = {};
          _this.requestContext = 'controller';

          _this.showingPeriod = _this.locale.ActualLots;
          _this.periodClass = 'actual';
          //          this.filterLots('actual');
          _this.createPeriodQuery(_this.periodClass);
          _this.changeState(1, _this.periodClass, null);
          _this.getAllLots();
        }
      });

      $scope.$on('doSearch', function (ev, data) {
        _this.searchedText = data.searchText;
        _this.requestContext = 'search';

        if (_this.searchedText.length >= 3) {
          if (data.type == 'lots') {
            _this.query = {};
            _this.category = null;
            _this.settings.category = null;
            _this.settings.categoryName = null;
            _this.requestContext = 'search';
            _this.periodClass = _this.showingPeriod = false;
            _this.searchLots();
          }
        }
      });

      $scope.$on('categoryChanged', function (ev, data) {

        //        this.settings.categoryName = null;

        if (data) {
          //          this.query['filter[categories]'] = data.id;
          _this.category = data.id;

          //          if (this.settings.category) {
          //            this.settings.category = null;
          //            this.settings.categoryName = null;
          //          }
          //          else {
          _this.settings.category = data.id;
          _this.settings.categoryName = data.title;
          //          }

          _this.requestContext = 'controller';
          _this.page = 1;
          _this.createPeriodQuery(_this.periodClass || 'actual');
          _this.changeState(1, _this.periodClass, _this.category);
          return;
        } //if data
        else {
            _this.category = '0';
            _this.settings.searchText = null;
            _this.settings.category = null;
          }

        _this.page = 1;
        _this.requestContext = 'controller';
        _this.createPeriodQuery(_this.periodClass);
        _this.changeState(1, _this.periodClass, _this.category);
      }); //$scope.$on
    }.bind(this)();
  } //Constructor

  _createClass(AllLotsController, [{
    key: 'createPeriodQuery',
    value: function createPeriodQuery(period) {
      this.query = {};
      var now = moment();
      now.tz(this.settings.timezone);
      now = now.unix();

      switch (period) {
        case 'past':
          delete this.query['filter[auction.timeline]'];
          this.query['filter[timeout][value]'] = now;
          this.query['filter[timeout][operator]'] = "<";
          this.period = this.locale.PastLots;
          this.periodClass = 'past';
          break;
        case 'actual':
          this.query['filter[auction.timeline]'] = '1';
          this.query['filter[timeout][value]'] = now;
          this.query['filter[timeout][operator]'] = ">";
          this.period = this.locale.ActualLots;
          this.periodClass = 'actual';
          break;
        case 'future':
          this.query['filter[timeout][operator]'] = '>';
          this.query['filter[timeout][value]'] = now;
          this.query['filter[auction.timeline]'] = '2';
          this.period = this.locale.FutureLots;
          this.periodClass = 'future';
          break;
      }

      if (this.category !== '0') {
        this.query['filter[categories]'] = this.category;
      }
    }
  }, {
    key: 'changeState',
    value: function changeState(page, period, category) {
      this.state.go('home', {
        page: page,
        period: period,
        category: category
      });
    }
  }, {
    key: 'filterLots',
    value: function filterLots(period) {
      this.createPeriodQuery(period);
      this.changeState("1", period, this.category);
      //    this.getAllLots();
    }
  }, {
    key: 'getAllLots',
    value: function getAllLots() {
      var _this2 = this;

      this.showingPeriod = '';
      this.settings.searchText = null;
      this.loading = true;
      var currentPage = this.page;
      this.R.lots(this.query, null, this.pageRange, this.page).then(function (res) {
        _this2.lots = res.data;
        _this2.itemCount = res.count;
        _this2.showingPeriod = _this2.period;
        _this2.periodViewClass = _this2.periodClass;
        _this2.page = currentPage;
      }).finally(function () {
        _this2.showPager = true;
        _this2.loading = false;
      });
    }
  }, {
    key: 'searchLots',
    value: function searchLots(page) {
      var _this3 = this;

      page = page || 1;
      this.page = 1;

      this.category = null;
      this.settings.searchText = null;
      this.settings.category = null;
      this.settings.categoryName = null;
      this.showPager = false;
      this.showingPeriod = this.locale.SearchResults;

      var query = {
        'filter[type]': 'lot'
      };

      this.loading = true;
      var count = 0;

      this.R.search(this.searchedText, query, null, page).then(function (res) {
        var lots = [];
        if (res.data) {
          angular.forEach(res.data[0], function (item) {
            if (item.resource && item.auction) {
              item.resource[0].relevance = item.relevance;
              lots.push(item.resource[0]);
            }
          });
          _this3.periodClass = false;
          _this3.periodViewClass = '';
          _this3.showingPeriod = _this3.locale.SearchResults;
          _this3.lots = lots;
        } //if res.data
        else {
            _this3.lots = [];
          } //else res.data
      }).finally(function () {
        _this3.loading = false;
        _this3.itemCount = parseInt(count);
      });
    } //search Lots

  }, {
    key: 'pageChanged',
    value: function pageChanged(page) {
      switch (this.requestContext) {
        case 'controller':
          //        this.getAllLots(page);
          this.page = page;
          this.changeState(page, this.periodClass, this.category);
          break;
        case 'search':
          this.searchLots(page);
          break;
      }
    }
  }]);

  return AllLotsController;
}();

exports.default = AllLotsController;

},{}],32:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _allLots = require('./allLots.component');

var _allLots2 = _interopRequireDefault(_allLots);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AllLotsModule = angular.module('app.shared.components.lot.alllots', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('allLots', _allLots2.default);

exports.default = AllLotsModule;

},{"./allLots.component":30}],33:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _featuredLots = require("./featuredLots.controller");

var _featuredLots2 = _interopRequireDefault(_featuredLots);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FeaturedLotsComponent = function FeaturedLotsComponent(AppSettings) {
  "use strict";
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'featuredLots_v2.html',
    controller: _featuredLots2.default,
    controllerAs: 'vm',
    scope: {}
  };
};

exports.default = FeaturedLotsComponent;

},{"./featuredLots.controller":34}],34:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FeaturedLotsController = function () {
  /*@ngInject*/
  function FeaturedLotsController($rootScope, $scope, AppSettings, ModelHelper, EventHelper, $q, LocaleFactory, FormatsFactory, RestFactory, $sce) {
    var _this = this;

    _classCallCheck(this, FeaturedLotsController);

    //common features.
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.$q = $q;
    this.name = 'FetauredLotsController';
    this.loading = false;
    this.range = AppSettings.variables.featured_lots_range || 3;

    this.lots = [];
    this.auctions = [];

    var now = moment();
    now.tz(this.settings.timezone);
    now = now.unix();

    //auctions time line
    // 0: past
    // 1: current
    // 2: future
    var lotsQuery = {
      'sort': null,
      'random': '',
      'filter[auction.timeline][value]': '1',
      'filter[auction.timeline][operator]': '=',
      'filter[auction.item_status]': 0,
      'filter[timeout][value]': now,
      'filter[timeout][operator]': ">",
      'filter[status]': 1
    };

    var auctionsQuery = {
      'sort': null,
      'random': '',
      'filter[timeline][value]': '1',
      'filter[timeline][operator]': '=',
      'filter[item_status]': 0,
      'filter[status]': 1
    };

    var featuredLots = RestFactory.featuredLots(lotsQuery, false, this.range);
    var featuredAuctions = RestFactory.featuredAuctions(auctionsQuery, false, this.range);

    this.loading = true;

    $q.all({
      lots: featuredLots,
      auctions: featuredAuctions
    }).then(function (res) {
      var totalAuctions = res.auctions.data.length;
      var auctions = res.auctions.data;

      var totalLots = res.lots.data.length;
      var lots = res.lots.data;

      var mergedItems = auctions.concat(lots);
      _this.model = [];

      //generate random numbers as for the range length.
      var itemsLength = mergedItems.length;
      if (itemsLength > _this.range) {
        var randomNumbers = [];
        while (randomNumbers.length < _this.range) {
          var randomNum = Math.round(Math.random() * (itemsLength - 1));
          if (randomNumbers.indexOf(randomNum) > -1) continue;
          randomNumbers.push(randomNum);
          _this.model.push(mergedItems[randomNum]);
        }
      } else {
        _this.model = mergedItems;
      }
    }, function (err) {
      return console.log(err);
    }).finally(function () {
      _this.loading = false;
    });

    //    featuredLots.then(
    //      (res) => {
    //        this.lots = res.data;
    //      })
    //      .finally(() => {
    //        this.loading = false;
    //      });


    //RootScope events
    $scope.$on('startLoading', function (ev, data) {
      this.loading = true;
    });

    $scope.$on('endLoading', function (ev, data) {
      $t(function () {
        this.loading = false;
      }, 3000);
    });

    $scope.$on('AuctionItemChanged', function (ev, item, scope) {
      if (scope !== $scope) {
        ModelHelper.updateItem(item, this.auctions);
      }
    });
  }

  _createClass(FeaturedLotsController, [{
    key: 'getLastBid',
    value: function getLastBid(lot) {
      return lot.highest_bid && lot.highest_bid.decimal ? lot.highest_bid.formatted : lot.original_price.formatted;
    }
  }, {
    key: 'getLastBidText',
    value: function getLastBidText(lot) {
      return lot.highest_bid && lot.highest_bid.decimal ? this.locale.lastBid : this.locale.openingBid;
    }
  }, {
    key: 'lotIsOpen',
    value: function lotIsOpen(lot) {}

    /**
     * Generate translatebale strings.
     */

  }, {
    key: 'generateTranslations',
    value: function generateTranslations() {
      var that = this;
      Drupal.behaviors.angmod = function (context) {
        //Translations go here.
        that.translations.login = Drupal.t('Login');
        that.translations.slogan = Drupal.t('Welcome to @siteName', {
          '@siteName': that.settings.siteName
        });
      }();
    }
  }]);

  return FeaturedLotsController;
}();

exports.default = FeaturedLotsController;

},{}],35:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _featuredLots = require('./featuredLots.component');

var _featuredLots2 = _interopRequireDefault(_featuredLots);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FeaturedLotsModule = angular.module('app.shared.components.lot.featured', []).directive('featuredLots', _featuredLots2.default);

exports.default = FeaturedLotsModule;

},{"./featuredLots.component":33}],36:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _featuredLots = require('./featuredLots/featuredLots');

var _featuredLots2 = _interopRequireDefault(_featuredLots);

var _lotItem = require('./lotItems/lotItem');

var _lotItem2 = _interopRequireDefault(_lotItem);

var _lotList = require('./lotList/lotList');

var _lotList2 = _interopRequireDefault(_lotList);

var _lotSinglePage = require('./lotSinglePage/lotSinglePage');

var _lotSinglePage2 = _interopRequireDefault(_lotSinglePage);

var _allLots = require('./allLots/allLots');

var _allLots2 = _interopRequireDefault(_allLots);

var _lotBid = require('./lotBid/lotBid');

var _lotBid2 = _interopRequireDefault(_lotBid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LotComponentsModule = angular.module('app.shared.components.lot', [_lotList2.default.name, _allLots2.default.name, _featuredLots2.default.name, _lotItem2.default.name, _lotSinglePage2.default.name, _lotBid2.default.name]);

exports.default = LotComponentsModule;

},{"./allLots/allLots":32,"./featuredLots/featuredLots":35,"./lotBid/lotBid":39,"./lotItems/lotItem":43,"./lotList/lotList":46,"./lotSinglePage/lotSinglePage":49}],37:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lotBid = require('./lotBid.controller');

var _lotBid2 = _interopRequireDefault(_lotBid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LotBidComponent = function LotBidComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'lotBid_v2.html',
    controller: _lotBid2.default,
    controllerAs: "vm",
    scope: {
      lot: "=",
      expandableinfo: "=",
      collapsedinfo: "="
    }
  };
};

exports.default = LotBidComponent;

},{"./lotBid.controller":38}],38:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LotBidController = function () {
  /*@ngInject*/

  function LotBidController($rootScope, $scope, $sce, $timeout, lodash, AppSettings, $mdDialog, RestFactory, WebSocketFactory, LocaleFactory, FormatsFactory) {
    var _this = this;

    _classCallCheck(this, LotBidController);

    //common features.
    this.name = 'LotBidController';
    this.rootScope = $rootScope;
    this.scope = $scope;
    this.timeout = $timeout;
    this.settings = AppSettings;
    this.formats = FormatsFactory;
    this.locale = LocaleFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.Dialog = $mdDialog;
    this.t = Drupal.t;
    this.R = RestFactory;
    this.WS = WebSocketFactory;

    //Custom code.
    this.Lot = $scope.lot;
    this.hasErrors = false;
    this.hasMessages = false;
    this.messages = '';
    this.errors = '';
    this.loading = false;
    this.expired = false;
    this.pulse = false;
    this.expandableInfo = $scope.expandableinfo || false;
    this.collapsedInfo = $scope.collapsedinfo || false;
    this.infoExpanded = !this.collapsedInfo;

    //userStatus
    var _ = lodash;
    this.authenticated = _.includes(AppSettings.user.roles, 'authenticated user');
    this.bidder = _.includes(AppSettings.user.roles, 'bidder');

    //Lot statuses
    this.open = false;
    this.closed = false;
    this.suspended = false;
    this.awarded = false;
    this.auction = this.Lot.auction;

    var lotVAT = this.Lot.vat_rate !== null ? this.Lot.vat_rate : -1;
    var lotEC = this.Lot.ec_rate !== null ? this.Lot.ec_rate : -1;
    var lotEC_VAT = this.Lot.ec_vat !== null ? this.Lot.ec_vat : -1;

    this.vat_rate = lotVAT >= 0 ? lotVAT : this.auction.vat_rate !== null ? this.auction.vat_rate : parseFloat(AppSettings.auction.vat.rate) * 100;

    this.ec_rate = lotEC >= 0 ? lotEC : this.auction.ec_rate !== null ? this.auction.ec_rate : parseFloat(AppSettings.auction.extra_cost.rate) * 100;

    this.ec_vat_rate = lotEC_VAT >= 0 ? lotEC_VAT : this.auction.ec_vat !== null ? this.auction.ec_vat : parseFloat(AppSettings.auction.vat.rate) * 100;

    //Auction Status
    this.auctionStatus = {
      Expired: this.auction.expired,
      Open: this.auction.item_status === '0',
      Closed: this.auction.item_status === '1',
      Suspended: this.auction.item_status === '2'
    };

    //Price variables
    this.highestBid;
    this.multiple;
    this.bidStep;
    this.price;
    this.maxBid;
    this.maxPercent;
    this.bidsCount;
    this.leading = false;
    this.leadingMessage = '';

    //Calculate the right values
    this.calculateBidValues();

    $scope.$on('LotExpired', function (ev, data) {
      console.log('Processing expiration...');
      if (_this.Lot.id == data.id) {
        $scope.lot = data;
        _this.calculateBidValues();
      }
    });

    $scope.$on('BidPlaced', function (ev, data) {

      if (data.id == _this.Lot.id) {
        $scope.lot = data;
        _this.Lot = data;
        _this.pulse = true;
        _this.messages = _this.errors = '';
        _this.hasErrors = _this.hasMessages = false;

        $timeout(function () {
          _this.pulse = false;
        }, 2000);

        _this.calculateBidValues();
      }
    });
  }

  _createClass(LotBidController, [{
    key: 'toggleInfo',
    value: function toggleInfo() {
      this.infoExpanded = !this.infoExpanded;
    }
  }, {
    key: 'showProxyInfo',
    value: function showProxyInfo() {
      this.Dialog.show(this.Dialog.alert().parent(angular.element(document.body)).clickOutsideToClose(true).title(this.locale.proxyDefinitionTitle).textContent(this.settings.variables.proxy_definition).ok('Got it!'));
    }
  }, {
    key: 'isOpen',
    value: function isOpen() {
      return this.open && !this.expired && this.auctionStatus.Open;
    }
  }, {
    key: 'isClosed',
    value: function isClosed() {
      return this.closed || this.expired || this.auctionStatus.Closed;
    }
  }, {
    key: 'isSuspended',
    value: function isSuspended() {
      return this.suspended || this.auctionStatus.Suspended;
    }
  }, {
    key: 'closeBox',
    value: function closeBox(type) {

      switch (type) {
        case 'errors':
          this.hasErrors = false;
          break;
        case 'messages':
          this.hasMessages = false;
          break;
      }
    }
  }, {
    key: 'showDialog',
    value: function showDialog(type, amount, form) {
      var _this2 = this;

      if (amount && form.$valid) {

        amount *= 100;
        var costs = this.calculateCosts(amount);

        var confirmMessage = "<div class='dialog__row'>" + "<div class='prop'>" + this.locale.Bid + " :</div>" + "<div class='value'>" + this.parseCurrencyWithDecimal(costs.price) + "</div>" + "</div>" + "<div class='dialog__row'>" + "<div class='prop'>" + this.locale.VAT + " :</div>" + "<div class='value'>" + this.parseCurrencyWithDecimal(costs.priceVAT) + "</div>" + "</div>" + "<div class='dialog__row'>" + "<div class='prop'>" + this.locale.auctionCost + " (" + (this.extraCost * 100).toFixed(0) + "%) :</div>" + "<div class='value'>" + this.parseCurrencyWithDecimal(costs.extraCost) + "</div>" + "</div>" + "<div class='dialog__row'>" + "<div class='prop'>" + this.locale.auctionCost + " " + this.locale.VAT + " :</div>" + "<div class='value'>" + this.parseCurrencyWithDecimal(costs.extraCostVAT) + "</div>" + "</div>" + "<div class='dialog__row total__row'>" + "<div class='total prop'>" + this.locale.Total + " :</div>" + "<div class='value'>" + this.parseCurrencyWithDecimal(costs.total) + "</div>" + "</div>";

        this.Dialog.show({
          controller: function controller($scope, $mdDialog, LocaleFactory, template, AppSettings) {
            "ngInject";

            $scope.locale = LocaleFactory;
            _this2.locale = LocaleFactory;
            $scope.settings = AppSettings;
            _this2.settings = AppSettings;

            $scope.confirm = function () {
              $mdDialog.hide('confirmed');
            };

            $scope.cancel = function () {
              $mdDialog.cancel('canceled');
            };

            $scope.template = template;
            _this2.template = template;

            return _this2;
          },
          controllerAs: 'vm',
          locals: {
            template: confirmMessage
          },
          templateUrl: this.settings.templates + 'confirmBidDialog.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          fullscreen: false
        }).then(function () {
          _this2.placeBid(type, amount);
        }, function () {});
      }
    }
  }, {
    key: 'placeBid',
    value: function placeBid(type, amount) {
      var _this3 = this;

      this.errors = this.messages = '';
      this.hasErrors = this.hasMessages = false;
      this.loading = true;

      if (!amount) {
        this.errors = this.locale.invalidAmount;
        this.hasErrors = true;
        this.loading = false;
        return;
      }

      this.R.placeBid(this.Lot.id, amount, type).then(function (res) {
        var data = res.data;

        if (data.code != 2) {
          //display errors
          _this3.errors = "<ul>";
          angular.forEach(data.errors, function (err) {
            _this3.errors += "<li>" + err + "</li>";
          });
          _this3.errors += "</ul>";
          _this3.hasErrors = true;
        } else {
          //display messages
          _this3.messages = "<ul>";
          angular.forEach(data.bid.messages, function (mes) {
            _this3.messages += "<li>" + mes + "</li>";
          });
          _this3.messages += "</ul>";
          _this3.hasMessages = true;
        }
      }).finally(function () {
        _this3.loading = false;
        _this3.bid.proxy = _this3.bid.direct = '';
      });
    }
  }, {
    key: 'calculateCosts',
    value: function calculateCosts(amount) {
      var VAT = this.VAT;
      var ecVAT = this.ecVAT;

      if (typeof amount == 'String') amount.replace(/\D/g, '');

      amount = Math.floor(parseFloat(amount));

      var priceVAT = Math.floor(parseFloat(amount * VAT));
      var priceWithVAT = Math.floor(parseFloat(amount + priceVAT));

      var extraCost = Math.floor(parseFloat(amount * this.extraCost));
      var extraCostVAT = Math.floor(parseFloat(extraCost * ecVAT));
      var extraCostPlusVAT = Math.floor(parseFloat(extraCost + extraCostVAT));

      var total = Math.floor(parseFloat(priceWithVAT + extraCostPlusVAT));

      return {
        price: amount,
        priceVAT: priceVAT,
        priceWithVAT: priceWithVAT,
        extraCost: extraCost,
        extraCostVAT: extraCostVAT,
        extraCostWithVAT: extraCostPlusVAT,
        total: total
      };
    }
  }, {
    key: 'parseCurrency',
    value: function parseCurrency(amount) {
      amount /= 100;
      numbro.culture('nl-BE');
      numbro.defaultCurrencyFormat('$ 0,0');
      return numbro(amount).formatCurrency();
    }
  }, {
    key: 'parseCurrencyWithDecimal',
    value: function parseCurrencyWithDecimal(amount) {
      amount /= 100;
      numbro.culture('nl-BE');
      numbro.defaultCurrencyFormat('$ 0,0.00');
      return numbro(amount).formatCurrency();
    }
  }, {
    key: 'calculateBidValues',
    value: function calculateBidValues() {

      var auction = this.settings.auction;
      var Lot = this.Lot;

      this.currencySymbol = Lot.starting_price.symbol;
      this.highestBidRaw = Lot.highest_bid ? parseFloat(Lot.highest_bid.raw) : null;
      this.priceRaw = parseInt(Lot.starting_price.raw);
      this.bidStepRaw = 0;

      //Auction vars
      this.canBid = this.settings.isBidder();
      this.canExtend = auction.extend;
      this.timing = auction.timing_seconds;
      this.extend = auction.extend_seconds;
      this.extraCost = angular.isDefined(this.ec_rate) ? this.ec_rate / 100 : auction.extra_cost.rate;
      this.VAT = angular.isDefined(this.vat_rate) ? this.vat_rate / 100 : auction.vat.rate;
      this.ecVAT = angular.isDefined(this.ec_vat_rate) ? this.ec_vat_rate / 100 : auction.vat.rate;

      this.proxyEnabled = Lot.proxy_enabled;
      this.proxy = Lot.proxy;
      this.bidsCount = Lot.bids.length;
      this.requireMultiple = !this.multiple === false;

      //Bid Step
      switch (Lot.min_bid_increment) {
        //disabled
        case "-1.00":
          this.bidStep = null;
          this.bidStepRaw = 0;
          break;
        //global
        case "0.00":
          this.bidStep = parseFloat(auction.step) * 100;
          this.bidStepRaw = this.bidStep;
          break;
        //per Lot
        default:
          this.bidStep = parseFloat(Lot.min_bid_increment) * 100;
          this.bidStepRaw = this.bidStep;
      }

      //Multiple
      switch (Lot.bid_increment) {
        //disabled
        case "-1.00":
          this.multiple = null;
          break;
        //global
        case "0.00":
          this.multiple = parseFloat(auction.multiple) * 100;
          break;
        //per Lot
        default:
          this.multiple = parseFloat(Lot.bid_increment) * 100;
          break;
      }

      //MaxBid
      switch (Lot.max_bid_increment) {
        //disabled
        case "-1.00":
          this.maxBid = null;
          break;
        //global
        case "0.00":
          this.maxBid = parseFloat(auction.max) * 100;
          break;
        //per Lot
        default:
          this.maxBid = parseFloat(Lot.bid_increment) * 100;
      }

      //MaxPercentStep
      switch (Lot.max_bid_increment_percentage) {
        //disabled
        case "-1.00":
          this.maxPercent = null;
          break;
        //global
        case "0.00":
          this.maxPercent = parseFloat(auction.max_percent) * 100;
          break;
        //per Lot
        default:
          this.maxPercent = parseFloat(Lot.max_bid_increment_percentage) * 100;
      }

      this.highestBid = this.parseCurrency(this.highestBidRaw);
      this.price = this.parseCurrency(parseInt(this.priceRaw));

      var maxMultipleOrBidStep = Math.max(this.multiple, this.bidStep);

      this.nextBidRaw = this.highestBidRaw ? Math.floor(parseFloat(this.highestBidRaw)) + Math.floor(parseFloat(maxMultipleOrBidStep)) : Math.floor(parseFloat(this.priceRaw));

      this.bidStep = this.parseCurrency(this.bidStep);
      this.nextBid = this.parseCurrency(this.nextBidRaw);
      this.multipleRaw = this.multiple;
      this.multiple = this.parseCurrency(this.multiple);
      this.bidsCount = Lot.bids.length;
      this.nextValidBid = this.nextBidRaw / 100;

      this.suspended = Lot.item_status == '2';
      this.closed = Lot.item_status == '1';
      this.open = Lot.item_status == '0';

      //Selling ststuses
      this.forSale = Lot.buy_status === '0';
      this.awarded = Lot.buy_status === '1';
      this.unsold = Lot.buy_status === '5';
      this.ordering = !this.awarded && !this.forSale && !this.unsold;

      this.leading = Lot.leading.user_leads;
      this.leadingMessage = this.leading ? this.locale.isLeaderMessage : this.locale.isNotLeaderMessage;
      var now = moment();
      var expireTime = moment(Lot.timeout * 1000);

      this.expired = expireTime - now <= 0;

      if (!this.rootScope.$root.$$phase) {
        this.scope.$apply();
      }
    }
  }]);

  return LotBidController;
}();

exports.default = LotBidController;

},{}],39:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lotBid = require('./lotBid.component');

var _lotBid2 = _interopRequireDefault(_lotBid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LotBidModule = angular.module('app.shared.components.lot.lotbid', [
//Add module dependencies here
//in the format.
/*OtherModule.name*/
'ui.utils.masks']).directive('lotBid', _lotBid2.default);

exports.default = LotBidModule;

},{"./lotBid.component":37}],40:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lotItem = require('../lotItem.controller');

var _lotItem2 = _interopRequireDefault(_lotItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FeaturedLotItemComponent = function FeaturedLotItemComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'featuredLotItem_v1.html',
    controller: _lotItem2.default,
    controllerAs: "vm",
    scope: {
      lot: "="
    }
  };
};

exports.default = FeaturedLotItemComponent;

},{"../lotItem.controller":42}],41:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lotItem = require('../lotItem.controller');

var _lotItem2 = _interopRequireDefault(_lotItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ListLotItemComponent = function ListLotItemComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'listLotItem_v2.html',
    controller: _lotItem2.default,
    controllerAs: "vm",
    scope: {
      lot: "=",
      user: "=",
      live: "=",
      expandableinfo: "=",
      collapsedinfo: "="
    }
  };
};

exports.default = ListLotItemComponent;

},{"../lotItem.controller":42}],42:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LotItemController = function () {
  /*@ngInject*/

  function LotItemController($rootScope, $scope, AppSettings, $sce, LocaleFactory, WebSocketFactory, FormatsFactory, RestFactory) {
    var _this = this;

    _classCallCheck(this, LotItemController);

    //common features.
    this.name = 'LotItemController';
    this.lang = AppSettings.language.language;
    this.settings = AppSettings;
    this.trustAsHtml = $sce.trustAsHtml;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.t = Drupal.t;
    this.rootScope = $rootScope;
    this.scope = $scope;
    this.rest = RestFactory;
    this.WS = WebSocketFactory;
    this.isLive = $scope.live || false;
    this.bidExpanded = true;

    this.expireTime = $scope.lot ? FormatsFactory.formatDate($scope.lot.timeout) : moment().unix();
    //Component variables


    $scope.$watch(function () {
      return $scope.lot;
    }, function (nV, oV) {
      if (nV) {
        _this.expireTime = FormatsFactory.formatDate($scope.lot.timeout);
        _this.shortDateLang = _this.lang + 'short';
        _this.expiring = false;
        _this.lastMinute = false;
        _this.expired = false;
        _this.closed = $scope.lot.item_status === '1';
        _this.suspended = $scope.lot.item_status === '2';
        _this.open = $scope.lot.item_status === '0';

        _this.timelineStatus = {
          0: _this.locale.PastAuction,
          1: _this.locale.ActualAuction,
          2: _this.locale.FutureAuction
        };

        _this.timeline = '';
        _this.timelineIndex = '';

        _this.showUserStatus = angular.isDefined($scope.user);
        _this.user = _this.showUserStatus ? $scope.user : AppSettings.user;

        _this.leading = _this.user.id == $scope.lot.leading.leading_uid;
        //Not for sale and not unsold.
        _this.awarded = $scope.lot.buy_status === '1' || $scope.lot.buy_status !== '2';

        _this.getItemStatus(_this.scope.lot);
      }
    });

    $scope.$on('BidPlaced', function (ev, data) {
      if (data && data.id) {
        if (data.id == $scope.lot.id) {
          data.favourite = $scope.lot.favourite;
          $scope.lot = data;
          _this.getItemStatus(data);
        }
      }
    });

    $scope.$on('timer-tick', function (ev, args) {
      var millis = args.millis;

      millis <= 180000 ? _this.expiring = true : _this.expiring = false;

      millis <= 60000 ? _this.lastMinute = true : _this.lastMinute = false;

      millis <= 0 ? _this.expired = true : _this.expired = false;

      if (!$rootScope.$root.$$phase) {
        $scope.$apply();
      }
    });
  }

  _createClass(LotItemController, [{
    key: 'toggleBid',
    value: function toggleBid() {
      this.bidExpanded = !this.bidExpanded;
      console.log('clicked', this.bidExpanded);
    }
  }, {
    key: 'timeCompleted',
    value: function timeCompleted(lot) {
      this.expired = true;
      this.expiring = false;
      this.lastMinute = false;
      if (!this.rootScope.$root.$$phase) {
        this.scope.$digest();
      }
    }
  }, {
    key: 'showLive',
    value: function showLive() {
      var auctionTimeline = this.scope.lot && this.scope.lot.auction && this.scope.lot.auction.timeline && this.scope.lot.auction.timeline === '1';
      return this.isLive && this.itemStatus.open && !this.itemStatus.expired && !this.sellStatus.awarded && auctionTimeline;
    }
  }, {
    key: 'getItemStatus',
    value: function getItemStatus(lot) {
      var leading = lot.leading.user_leads;
      this.leadingMessage = this.leading ? this.locale.isLeaderMessage : this.locale.isNotLeaderMessage;

      this.awarded = lot.buy_status !== '1' && lot.buy_status !== '3';
      this.forSale = lot.buy_status === '0';
      this.awarded = lot.buy_status === '1';
      this.unsold = lot.buy_status === '5';
      this.ordering = !this.awarded && !this.forSale && !this.unsold;

      //Selling statuses
      this.sellStatus = {
        forSale: this.forSale,
        awarded: this.awarded || this.ordering,
        unsold: this.unsold,
        ordering: this.ordering
      };

      //expired
      this.expired = lot.expired;

      //last bid amount
      this.lastBid = lot.highest_bid && lot.highest_bid.decimal ? lot.highest_bid.formatted : lot.original_price.formatted;

      this.lastBidText = lot.highest_bid && lot.highest_bid.decimal ? this.locale.lastBid : this.locale.openingBid;

      //item status
      this.itemStatus = {
        open: lot.item_status === '0',
        closed: lot.item_status === '1',
        suspended: lot.item_status === '2',
        expired: lot.expired
      };

      if (this.awarded && this.leading) {
        this.leadingMessage = this.locale.wonMessage;
      }

      if (this.awarded && !this.leading) {
        this.leadingMessage = this.locale.lostMessage;
      }

      //Auction timeline status
      this.timelineIndex = lot.auction ? parseInt(lot.auction.timeline) : 2;
      this.timeline = this.timelineStatus[this.timelineIndex];

      this.showTimeline = this.timelineIndex !== 1;

      var closingString = this.locale.Closing;
      var closedString = this.locale.Closed;
      var suspendedString = this.locale.Suspended;
    }
  }, {
    key: 'addLotToFavourite',
    value: function addLotToFavourite(id) {
      var _this2 = this;

      var post = this.rest.flag(id).then(function (res) {
        _this2.scope.lot = res.data[0]['node'];
      }, function (err) {});
    }
  }]);

  return LotItemController;
}();

exports.default = LotItemController;

},{}],43:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _featuredLotItem = require('./featured/featuredLotItem');

var _featuredLotItem2 = _interopRequireDefault(_featuredLotItem);

var _listLotItem = require('./list/listLotItem');

var _listLotItem2 = _interopRequireDefault(_listLotItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LotItemModule = angular.module('app.shared.components.lot.lotitem', ['ngLodash', 'restangular']).directive('lotItemList', _listLotItem2.default).directive('lotItemFeatured', _featuredLotItem2.default);

exports.default = LotItemModule;

},{"./featured/featuredLotItem":40,"./list/listLotItem":41}],44:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lotList = require('./lotList.controller');

var _lotList2 = _interopRequireDefault(_lotList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LotListComponent = function LotListComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'lotList_v2.html',
    controller: _lotList2.default,
    controllerAs: "vm",
    scope: {
      range: "@",
      sort: '@'
    }
  };
};

exports.default = LotListComponent;

},{"./lotList.controller":45}],45:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LotListController = function () {
  /*@ngInject*/

  function LotListController($rootScope, $scope, $cookies, $stateParams, $state, AppSettings, $sce, lodash, LocaleFactory, FormatsFactory, RestFactory) {
    var _this = this;

    _classCallCheck(this, LotListController);

    //common features.
    this.rest = RestFactory;
    this.params = $stateParams;
    this.state = $state;
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this._ = lodash;
    this.loading = false;
    this.pageRange = $scope.range || AppSettings.pagesRange;

    console.log('List', $scope);
    this.currentPage = $stateParams.page || 1;
    $scope.currentPage = this.currentPage;
    $scope.page = this.currentPage;

    this.itemCount = 0;
    this.requestContext = 'controller';
    this.searchText = '';
    this.query = {};
    this.hidePaging = false;
    this.sortFilters = $scope.sort || null;

    this.name = 'LotListController';
    this.viewMode = $cookies.get('auctionActiveViewMode') || 'list';
    this.auctionId = $stateParams.id;
    this.lots = [];
    this.getLots(this.currentPage);

    //EVENTS
    $scope.$watch('currentPage', function (value) {
      $scope.page = value;
    });

    $scope.$on('AuctionViewModeChanged', function (ev, mode, scope) {
      _this.viewMode = mode;
    });

    $scope.$on('cancelSearch', function (ev, data) {
      if (data.type == 'lots') {
        _this.hidePaging = false;
        _this.query = {};
        _this.getLots();
      }
    });

    $scope.$on('doSearch', function (ev, data) {
      if (data.type == 'lots') {
        _this.searchText = data.searchText;
        _this.query = {};
        _this.query['filter[type]'] = 'lot';
        _this.requestContext = 'search';
        _this.searchLots();
        _this.hidePaging = true;
      }
    });

    $scope.$on('categoryChanged', function (ev, data) {
      _this.query = {};
      if (data) {
        _this.query['filter[categories]'] = data.id;
      }
      _this.requestContext = 'controller';
      _this.getLots();
    }); //$scope.$on
  }

  _createClass(LotListController, [{
    key: 'pageChanged',
    value: function pageChanged(page) {
      switch (this.requestContext) {
        case 'controller':
          this.state.go('auction', {
            id: this.auctionId,
            page: page
          });

          //        this.state.reload();
          //        this.getLots(page);
          break;
        case 'search':
          this.searchAuctions(page);
          break;
      }
      //    this.currentPage = page;
    }
  }, {
    key: 'searchLots',
    value: function searchLots(page) {
      var _this2 = this;

      if (this.searchText.length >= 3) {
        this.hidePaging = false;
        page = page || 1;
        this.currentPage = page;

        this.loading = true;
        var count = 0;
        this.lots = [];

        this.rest.search(this.searchText, this.query, null, page).then(function (res) {
          var lots = [];
          if (res.data) {
            angular.forEach(res.data[0], function (item) {
              if (item.resource && item.auction) {
                var lot = item.resource[0];
                if (lot.auction.nid == _this2.auctionId) {
                  lots.push(lot);
                }
              }
            });
            _this2.lots = lots;
          } else {
            _this2.lots = [];
            count = 0;
          }
        }).finally(function () {
          _this2.loading = false;
          _this2.itemCount = parseInt(count);
        });
      } //if >= 3
    } //if lots


  }, {
    key: 'getLots',
    value: function getLots(page) {
      var _this3 = this;

      this.loading = true;
      this.query['filter[auction]'] = this.auctionId;
      this.sortFilters ? this.query['sort'] = this.sortFilters : null;

      var lots = this.rest.lots(this.query, false, this.pageRange, page);
      lots.then(function (res) {
        _this3.lots = res.data;
        _this3.itemCount = res.count;
        _this3.currentPage = page;
      }).finally(function () {
        _this3.loading = false;
      });
    }
  }]);

  return LotListController;
}();

exports.default = LotListController;

},{}],46:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lotList = require('./lotList.component');

var _lotList2 = _interopRequireDefault(_lotList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LotListModule = angular.module('app.shared.components.lot.list', ['ngCookies', 'ngLodash']).directive('lotList', _lotList2.default);

exports.default = LotListModule;

},{"./lotList.component":44}],47:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lotSinglePage = require('./lotSinglePage.controller');

var _lotSinglePage2 = _interopRequireDefault(_lotSinglePage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LotSinglePageComponent = function LotSinglePageComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'lotSinglePagev2.html',
    controller: _lotSinglePage2.default,
    controllerAs: "vm",
    scope: {},
    link: function link(scope, element, attrs) {
      "use strict";

      var $ = jQuery;

      $(window).on('focus', function (e) {
        scope.$broadcast('LotPageTabFocused');
      });
    }
  };
};

exports.default = LotSinglePageComponent;

},{"./lotSinglePage.controller":48}],48:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LotSinglePageController = function () {
  /*@ngInject*/

  function LotSinglePageController($rootScope, $scope, $sce, AppSettings, $http, LocaleFactory, RestFactory, lodash, Restangular, $timeout, $stateParams, $state, FormatsFactory) {
    var _this = this;

    _classCallCheck(this, LotSinglePageController);

    //common features.
    this.name = 'LotSinglePageController';
    this.settings = AppSettings;
    this.lang = AppSettings.language.language;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.Restangular = Restangular;
    this.timeout = $timeout;
    $scope.$state = $state;
    this.state = $state;
    this.scope = $scope;
    this._ = lodash;
    this.R = RestFactory;
    this.http = $http;
    this.rootScope = $rootScope;

    //Custom code.
    this.lotId = $stateParams.id;
    this.lot = {};
    this.lotPhotos = [];
    this.lotLoading = false;

    this.auction = {};
    this.auctionLoaded = false;
    this.homeLink = '/' + this.settings.pathPrefix;
    this.lotEditLonk = FormatsFactory.siteUrl + 'node/' + this.lot.id + '/edit';
    //    this.auctionLink = '/' + this.settings.pathPrefix + "auctions/#auction/" + 2;

    this.relatedLots = [];
    this.relatedLotdLoading = false;
    this.relatedLotsLen = 0;
    this.currentLot = null;
    this.currentLotIndex = null;

    this.getLot(this.lotId);

    //Component variables
    this.shortDateLang = this.lang + 'short';
    this.expiring = false;
    this.lastMinute = false;
    this.expired = false;
    this.awarded = false;

    //Date and time
    this.serverTime = null;
    this.expiringTime = null;
    this.timeOffSet = 0;

    this.leading = false;
    this.leadingMessage = '';

    //manage the browser tab and reload when focused
    $scope.$on('LotPageTabFocused', function () {
      _this.getLot(_this.lotId);
    });

    $scope.$on('timer-tick', function (ev, args) {
      var millis = args.millis;

      millis <= 180000 ? _this.expiring = true : _this.expiring = false;

      millis <= 60000 ? _this.lastMinute = true : _this.lastMinute = false;

      if (millis <= 0 || millis == NaN) {
        _this.timeCompleted();
      }

      if (!$rootScope.$root.$$phase) {
        $scope.$apply();
      }
    });

    $scope.$on('BidPlaced', function (ev, data) {
      //favourite result is taken from the bidder not the current user
      //so we need to copy and paste the value.
      var isFavourite = angular.copy(_this.lot.favourite);
      if (data.id == _this.lot.id) {

        _this.lot = data;
        _this.lot.favourite = isFavourite;
        _this.lot.expired = _this.expired = false;
        _this.serverTime = data.server_time.time;
        _this.updateValues(_this.lot);
        if (!$rootScope.$root.$$phase) {
          $scope.$apply();
        }
      }
    });
  }

  _createClass(LotSinglePageController, [{
    key: 'timeCompleted',
    value: function timeCompleted() {
      var _this2 = this;

      this.timeout(function () {
        console.log('lot expired, reloading...');
        _this2.getLot(_this2.lotId);
        _this2.rootScope.$broadcast('LotExpired', _this2.lot);
        if (!_this2.rootScope.$root.$$phase) {
          //        this.scope.$digest();
        }
      }, 1000);
      //      this.expired = true;
      //      this.expiring = false;
      //      this.lastMinute = false;
      //      this.lot.expired = true;
      //      this.expired = true;
    }
  }, {
    key: 'getLot',
    value: function getLot(id) {
      var _this3 = this;

      this.lotLoading = true;
      var lot = this.R.lot(id, 'square,gallery_1200');

      this.serverTime = moment().unix();
      this.http({
        method: 'GET',
        'url': this.settings.restPath + '/users/me'
      }).then(function (res) {
        var headers = res.headers();
        var serverDate = headers.date;
        _this3.serverTime = moment(serverDate).tz(_this3.settings.timezone).unix();
        var requestStartTime = moment().unix();
        var requestEndTime = null;

        lot.then(function (lotRes) {
          requestEndTime = moment().unix();
          _this3.timeOffSet = requestEndTime - requestStartTime;
          _this3.lot = lotRes.data[0];
          _this3.serverTime = parseInt(_this3.serverTime, 10);
          _this3.serverTime += _this3.timeOffSet;
          _this3.getAuction();
          _this3.updateValues(_this3.lot);

          _this3.lotPhotos = [];
          angular.forEach(_this3.lot.photos, function (photo) {
            _this3.lotPhotos.push({
              thumb: photo.styled_url || photo.url,
              img: photo.gallery_1200_url,
              description: photo.title
            });
          });
        }, function (err) {
          return console.log(err);
        }).finally(function () {
          _this3.lotLoading = false;
        });
      }, function (err) {
        return console.log(err);
      });
    }
  }, {
    key: 'updateValues',
    value: function updateValues(lot) {
      this.expiringTime = moment(parseInt(lot.timeout * 1000, 10)).unix();
      this.countdown = parseInt(this.expiringTime - this.serverTime, 10);
      this.expired = this.countdown < 0;

      this.scope.$broadcast('timer-reset');
      this.scope.$broadcast('timer-set-countdown', this.countdown);
      this.scope.$broadcast('timer-start');

      this.lotEditLink = this.formats.siteUrl() + 'node/' + lot.id + '/edit';

      this.leading = lot.leading.user_leads;
      this.leadingMessage = this.leading ? this.locale.isLeaderMessage : this.locale.isNotLeaderMessage;

      this.awarded = lot.buy_status !== '1' && lot.buy_status !== '3';

      this.forSale = lot.buy_status === '0';
      this.awarded = lot.buy_status === '1';
      this.unsold = lot.buy_status === '5';
      this.ordering = !this.awarded && !this.forSale && !this.unsold;

      //Selling statuses
      this.sellStatus = {
        forSale: this.forSale,
        awarded: this.awarded || this.ordering,
        unsold: this.unsold,
        ordering: this.ordering
      };

      //item status
      this.itemStatus = {
        open: lot.item_status === '0' && this.auction.item_status === '0',
        closed: lot.item_status === '1' || this.auction.item_status === '1',
        suspended: lot.item_status === '2' || this.auction.item_status === '2',
        expired: this.expired
      };

      //Auction status
      this.auctionStatus = {
        open: this.auction.item_status === '0',
        closed: this.auction.item_status === '1',
        suspended: this.auction.item_status === '2'
      };

      if (this.awarded && this.leading) {
        this.leadingMessage = this.locale.wonMessage;
      }

      if (this.awarded && !this.leading) {
        this.leadingMessage = this.locale.lostMessage;
      }
    }
  }, {
    key: 'getAuction',
    value: function getAuction(auctionId) {
      //check if we have a valid lot.
      if (this.lot.id) {
        this.auction = this.lot.auction; //res.data[0];
        this.auctionLink = this.formats.siteUrl() + "#/auction/" + this.auction.id + '/';
        this.relatedLots = this.auction.lots;
        this.relatedLotsLen = this.relatedLots.length;
        this.setCurrentLotIndex();
        this.auctionLoaded = true;
      }
    }
  }, {
    key: 'setCurrentLotIndex',
    value: function setCurrentLotIndex() {
      var _this4 = this;

      var index = this._.findIndex(this.relatedLots, function (l) {
        return l == _this4.lot.id;
      });
      this.currentLotIndex = index;
      this.currentLot = this.relatedLots[index];
    }
  }, {
    key: 'nextLotId',
    value: function nextLotId() {
      var idx = this.currentLotIndex;

      if (idx < this.relatedLots.length - 1) {
        idx += 1;
      }

      return this.state.href('lot', {
        id: this.relatedLots[idx]
      });
    }
  }, {
    key: 'prevLotId',
    value: function prevLotId() {
      var idx = this.currentLotIndex;

      if (idx > 0) {
        idx -= 1;
      }

      return this.state.href('lot', {
        id: this.relatedLots[idx]
      });
    }
  }, {
    key: 'changeLot',
    value: function changeLot() {}
  }, {
    key: 'addLotToFavourite',
    value: function addLotToFavourite(id) {
      var _this5 = this;

      this.R.flag(id).then(function (res) {
        _this5.lot = res.data[0]['node'];
      });
    }
  }]);

  return LotSinglePageController;
}();

exports.default = LotSinglePageController;

},{}],49:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lotSinglePage = require('./lotSinglePage.component');

var _lotSinglePage2 = _interopRequireDefault(_lotSinglePage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LotSinglePageModule = angular.module('app.shared.components.lot.lotsinglepage', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('lotSinglePage', _lotSinglePage2.default);

exports.default = LotSinglePageModule;

},{"./lotSinglePage.component":47}],50:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _newsItem = require('./newsItem.controller');

var _newsItem2 = _interopRequireDefault(_newsItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NewsItemComponent = function NewsItemComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'newsItem.html',
    controller: _newsItem2.default,
    controllerAs: "vm",
    scope: {
      item: "="
    }
  };
};

exports.default = NewsItemComponent;

},{"./newsItem.controller":51}],51:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var NewsItemController = function () {
  /*@ngInject*/

  function NewsItemController($rootScope, $scope, $mdDialog, $sce, AppSettings, LocaleFactory, FormatsFactory) {
    _classCallCheck(this, NewsItemController);

    //common features.
    this.settings = AppSettings;
    this.Dialog = $mdDialog;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.name = 'NewsItemController';
    this.scope = $scope;
    this.item = $scope.item;

    //Custom code.

  }

  _createClass(NewsItemController, [{
    key: 'showDialog',
    value: function showDialog() {

      this.Dialog.show({
        locals: {
          item: this.item,
          dialog: this.Dialog
        },
        templateUrl: this.settings.templates + 'newsDialog.html',
        parent: angular.element(document.body),
        controller: function controller($scope, $mdDialog, LocaleFactory, item, dialog, AppSettings) {

          "ngInject";

          $scope.locale = LocaleFactory;
          $scope.settings = AppSettings;
          $scope.item = item;

          $scope.hide = function () {
            $mdDialog.hide();
          };
        }
      }).then(function (res) {}); //then
    } //open dialog


  }]);

  return NewsItemController;
}();

exports.default = NewsItemController;

},{}],52:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _newsItem = require('./newsItem.component');

var _newsItem2 = _interopRequireDefault(_newsItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NewsItemModule = angular.module('app.shared.components.news.newsitem', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('newsItem', _newsItem2.default);

exports.default = NewsItemModule;

},{"./newsItem.component":50}],53:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _newsList = require('./newsList.controller');

var _newsList2 = _interopRequireDefault(_newsList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NewsListComponent = function NewsListComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'newsList.html',
    controller: _newsList2.default,
    controllerAs: "vm",
    scope: {}
  };
};

exports.default = NewsListComponent;

},{"./newsList.controller":54}],54:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var NewsListController = function () {
  /*@ngInject*/

  function NewsListController($rootScope, $scope, $sce, AppSettings, RestFactory, LocaleFactory, FormatsFactory) {
    _classCallCheck(this, NewsListController);

    Drupal.behaviors.angmod = function (context) {

      //common features.
      this.settings = AppSettings;
      this.locale = LocaleFactory;
      this.formats = FormatsFactory;
      this.trustAsHtml = $sce.trustAsHtml;
      this.t = Drupal.t;
      this.name = 'NewsListController';
      this.R = RestFactory;

      //Custom code.
      this.range = AppSettings.variables.news_range || 3;
      this.count = 0;

      this.loading = false;
      this.news = [];

      this.fetchNews(this.range);
    }.bind(this)();
  }

  _createClass(NewsListController, [{
    key: 'fetchNews',
    value: function fetchNews(range, page) {
      var _this = this;

      this.loading = true;
      this.R.news({}, null, range, 1).then(function (res) {
        _this.news = res.data;
        _this.count = res.count;
      }, function (err) {
        _this.count = 0;
      }).finally(function () {
        _this.loading = false;
      });
    }
  }]);

  return NewsListController;
}();

exports.default = NewsListController;

},{}],55:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _newsList = require('./newsList.component');

var _newsList2 = _interopRequireDefault(_newsList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NewsListModule = angular.module('app.shared.components.news.newslist', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('newsList', _newsList2.default);

exports.default = NewsListModule;

},{"./newsList.component":53}],56:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _searchBar = require('./searchBar.controller');

var _searchBar2 = _interopRequireDefault(_searchBar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SearchBarComponent = function SearchBarComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'searchBar.html',
    controller: _searchBar2.default,
    controllerAs: "vm",
    scope: {}
  };
};

exports.default = SearchBarComponent;

},{"./searchBar.controller":57}],57:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SearchBarController = function () {
  /*@ngInject*/

  function SearchBarController($rootScope, $scope, $sce, $q, $location, $window, $stateParams, $state, RestFactory, AppSettings, LocaleFactory, FormatsFactory) {
    _classCallCheck(this, SearchBarController);

    //common features.
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.$sce = $sce;
    this.t = Drupal.t;
    this.$q = $q;
    this.$location = $location;
    this.window = $window;
    this.state = $state;
    this.stateParams = $stateParams;
    this.R = RestFactory;
    this.name = 'SearchBarController';

    //Custom code.
    this.items = [];
    this.selectedItem = null;
  }

  _createClass(SearchBarController, [{
    key: 'querySearch',
    value: function querySearch(query) {
      return this.R.Factory().all('search_open/' + query).getList({
        style: 'medium',
        range: 50
      }).then(function (items) {
        return items.data.length > 0 ? items.data[0] : [];
      });
    }
  }, {
    key: 'searchTextChange',
    value: function searchTextChange(text) {}
  }, {
    key: 'selectedItemChange',
    value: function selectedItemChange(item) {
      if (item && item.type) {
        var siteUrl = this.formats.siteUrl();
        switch (item.type) {
          case 'lot':
            siteUrl += '#/lot/' + item.entity_id;
            this.window.location.href = siteUrl;
            break;
          case 'auction':
            siteUrl = siteUrl += '#/auction/' + item.entity_id + '/';
            this.window.location.href = siteUrl;
            break;
        }
      }
    }
  }, {
    key: 'formSubmitted',
    value: function formSubmitted() {
      this.state.go('search', { term: this.searchText });
    }
  }, {
    key: 'getTitleEncoded',
    value: function getTitleEncoded(item) {
      var lang = this.settings.language.language;
      var title = item.title;
      if (item.type == 'lot' && item.title_lot[lang]) {
        title = item.title_lot[lang][0].value;
      } else if (item.type == 'auction' && item.title_auction[lang]) {
        title = item.title_auction[lang][0].value;
      }
      return title;
    }
  }]);

  return SearchBarController;
}();

exports.default = SearchBarController;

},{}],58:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _searchBar = require('./searchBar.component');

var _searchBar2 = _interopRequireDefault(_searchBar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SearchBarModule = angular.module('app.shared.components.searchbar', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('searchBar', _searchBar2.default);

exports.default = SearchBarModule;

},{"./searchBar.component":56}],59:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _searchPage = require('./searchPage.controller');

var _searchPage2 = _interopRequireDefault(_searchPage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SearchPageComponent = function SearchPageComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'searchPage.html',
    controller: _searchPage2.default,
    controllerAs: "vm",
    scope: {}
  };
};

exports.default = SearchPageComponent;

},{"./searchPage.controller":60}],60:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SearchPageController = function () {
  /*@ngInject*/

  function SearchPageController($rootScope, $scope, $stateParams, $sce, AppSettings, RestFactory, LocaleFactory, FormatsFactory) {
    _classCallCheck(this, SearchPageController);

    //common features.
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.R = RestFactory;
    this.state = $stateParams;
    this.name = 'SearchPageController';

    //Custom code.
    this.currentPage = 1;
    this.itemsPerPage = 9;
    this.term = $stateParams.term;
    this.items = [];

    if (this.term.length >= 3) this.searchTerm(this.term);
  }

  _createClass(SearchPageController, [{
    key: 'searchTerm',
    value: function searchTerm(term, page) {
      var _this = this;

      page = page || 1;
      this.loading = true;
      this.items = [];
      this.R.Factory().all('search_open/' + term).getList({
        style: 'medium',
        page: page,
        range: this.itemsPerPage
      }).then(function (res) {
        _this.itemsResultTotal = res.count;
        _this.currentPage = page;
        var items = res.data.length > 0 ? res.data[0] : [];

        if (items.length < 1) _this.loading = false;

        angular.forEach(items, function (e, i) {
          e.type == 'lot' ? _this.getLot(e) : _this.getAuction(e);
          if (i == res.data.length - 1) _this.loading = false;
        });
      }, function (err) {
        _this.loading = false;
        console.log(err);
      });
    }
  }, {
    key: 'getLot',
    value: function getLot(item) {
      var _this2 = this;

      if (item.type == 'lot') {
        item.loading = true;
        this.R.lot(item.entity_id).then(function (res) {
          item = res.data[0];
          _this2.items.push(item);
        }, function (err) {
          console.log(err);
          item.loaded = false;
        }).finally(function () {
          item.loading = false;
        });
      }
    }
  }, {
    key: 'getAuction',
    value: function getAuction(item) {
      var _this3 = this;

      if (item.type == 'auction') {
        item.loading = true;
        this.R.auction(item.entity_id).then(function (res) {
          item = res.data[0];
          _this3.items.push(item);
        }, function (err) {
          console.log(err);
          item.loaded = false;
        }).finally(function () {
          item.loading = false;
        });
      }
    }
  }, {
    key: 'pageChanged',
    value: function pageChanged(page) {
      this.searchTerm(this.term, page);
    }
  }]);

  return SearchPageController;
}();

exports.default = SearchPageController;

},{}],61:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _searchPage = require('./searchPage.component');

var _searchPage2 = _interopRequireDefault(_searchPage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SearchPageModule = angular.module('app.shared.components.searchpage', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('searchPage', _searchPage2.default);

exports.default = SearchPageModule;

},{"./searchPage.component":59}],62:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _profile = require('./profile.controller');

var _profile2 = _interopRequireDefault(_profile);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ProfileComponent = function ProfileComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'profile.html',
    controller: _profile2.default,
    controllerAs: "vm",
    scope: {}
  };
};

exports.default = ProfileComponent;

},{"./profile.controller":63}],63:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ProfileController = function () {
  /*@ngInject*/

  function ProfileController($rootScope, $scope, $sce, lodash, RestFactory, AppSettings, LocaleFactory, FormatsFactory) {
    _classCallCheck(this, ProfileController);

    //common features.
    this.name = 'ProfileController';
    this.scope = $scope;
    this._ = lodash;
    this.rootScope = $rootScope;
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.R = RestFactory;
    this.t = Drupal.t;

    //Custom code.
    this.user = {};

    this.getUser();
  }

  _createClass(ProfileController, [{
    key: 'getUser',
    value: function getUser() {
      var _this = this;

      if (this.settings.pathArgument0 == 'user') {
        var uid = this.settings.pathArgument1;
        this.loading = true;
        this.R.User(uid).then(function (res) {
          _this.user = res.data[0];
        }, function (err) {}).finally(function () {
          _this.loading = false;
        });
      }
    }
  }]);

  return ProfileController;
}();

exports.default = ProfileController;

},{}],64:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _profile = require('./profile.component');

var _profile2 = _interopRequireDefault(_profile);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ProfileModule = angular.module('app.shared.components.user.profile', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('profile', _profile2.default);

exports.default = ProfileModule;

},{"./profile.component":62}],65:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _profile = require('./profile/profile');

var _profile2 = _interopRequireDefault(_profile);

var _userLots = require('./userLots/userLots');

var _userLots2 = _interopRequireDefault(_userLots);

var _userFavLots = require('./userFavLots/userFavLots');

var _userFavLots2 = _interopRequireDefault(_userFavLots);

var _userMyLots = require('./userMyLots/userMyLots');

var _userMyLots2 = _interopRequireDefault(_userMyLots);

var _userListTab = require('./userListTab/userListTab');

var _userListTab2 = _interopRequireDefault(_userListTab);

var _userWonBids = require('./userWonBids/userWonBids');

var _userWonBids2 = _interopRequireDefault(_userWonBids);

var _userLive = require('./userLive/userLive');

var _userLive2 = _interopRequireDefault(_userLive);

var _userLiveItem = require('./userLiveItem/userLiveItem');

var _userLiveItem2 = _interopRequireDefault(_userLiveItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserComponentsModule = angular.module('app.shared.components.user', [_profile2.default.name, _userLots2.default.name, _userFavLots2.default.name, _userMyLots2.default.name, _userListTab2.default.name, _userWonBids2.default.name, _userLive2.default.name, _userLiveItem2.default.name]);

exports.default = UserComponentsModule;

},{"./profile/profile":64,"./userFavLots/userFavLots":68,"./userListTab/userListTab":71,"./userLive/userLive":74,"./userLiveItem/userLiveItem":77,"./userLots/userLots":80,"./userMyLots/userMyLots":83,"./userWonBids/userWonBids":86}],66:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userFavLots = require('./userFavLots.controller');

var _userFavLots2 = _interopRequireDefault(_userFavLots);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserFavLotsComponent = function UserFavLotsComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'userFavLots.html',
    controller: _userFavLots2.default,
    controllerAs: "vm",
    scope: {}
  };
};

exports.default = UserFavLotsComponent;

},{"./userFavLots.controller":67}],67:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UserFavLotsController = function () {
  /*@ngInject*/

  function UserFavLotsController($rootScope, $scope, $sce, RestFactory, $cookies, AppSettings, LocaleFactory, FormatsFactory) {
    var _this = this;

    _classCallCheck(this, UserFavLotsController);

    //common features.
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.R = RestFactory;
    this.name = 'UserFavLotsController';

    //Custom code.
    this.viewMode = $cookies.get('UserViewMode') || 'list';
    this.lots = [];
    this.itemCount = 0;
    this.pageRange = 21;
    this.loading = false;

    this.getFavLots();

    //EVENTS
    $scope.$on('UserViewModeChanged', function (ev, mode, scope) {
      _this.viewMode = mode;
    });
  }

  _createClass(UserFavLotsController, [{
    key: 'getFavLots',
    value: function getFavLots(page) {
      var _this2 = this;

      page = page || 1;
      this.loading = true;
      this.uid = this.settings.pathArgument1;
      this.R.flaggedLots(this.uid, this.pageRange, page).then(function (res) {
        _this2.itemCount = res.count;
        _this2.lots = res.data;
      }).finally(function () {
        _this2.loading = false;
      });
    }
  }, {
    key: 'changePage',
    value: function changePage(page) {
      this.getFavLots(page);
    }
  }]);

  return UserFavLotsController;
}();

exports.default = UserFavLotsController;

},{}],68:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userFavLots = require('./userFavLots.component');

var _userFavLots2 = _interopRequireDefault(_userFavLots);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserFavLotsModule = angular.module('app.shared.components.user.userfavlots', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('userFavLots', _userFavLots2.default);

exports.default = UserFavLotsModule;

},{"./userFavLots.component":66}],69:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userListTab = require('./userListTab.controller');

var _userListTab2 = _interopRequireDefault(_userListTab);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserListTabComponent = function UserListTabComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'userListTab.html',
    controller: _userListTab2.default,
    controllerAs: "vm",
    scope: {
      uid: "@"
    }
  };
};

exports.default = UserListTabComponent;

},{"./userListTab.controller":70}],70:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UserListTabController = function () {
  /*@ngInject*/

  function UserListTabController($rootScope, $scope, $sce, $state, $stateParams, RestFactory, AppSettings, LocaleFactory, FormatsFactory) {
    _classCallCheck(this, UserListTabController);

    //common features.
    this.name = 'UserListTabController';
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.R = RestFactory;

    //Custom code.
    this.uid = $scope.uid || AppSettings.pathArgument1;

    this.lotsCount = 0;
    this.favsCount = 0;
    this.awardedCount = 0;

    this.loading = false;
    this.favsLoading = false;
    this.wonLoading = false;

    this.getFavouritesLots();
    this.getWonLots();
    this.getUserLots();
  }

  _createClass(UserListTabController, [{
    key: 'getUserLots',
    value: function getUserLots() {
      var _this = this;

      this.loading = true;
      this.R.userLots(this.uid).then(function (res) {
        _this.lotsCount = res.count;
        //        angular.forEach(res.data, (lot) => {
        //          lot.awarded = lot.order && (parseInt(lot.order.uid) == this.uid);
        //          lot.awarded ? this.awardedCount += 1 : '';
        //        });
      }, function (err) {}).finally(function () {
        _this.loading = false;
      });
    }
  }, {
    key: 'getWonLots',
    value: function getWonLots() {
      var _this2 = this;

      this.wonLoading = true;
      this.R.userWonLots(this.uid).then(function (res) {
        _this2.awardedCount = res.count;
      }).finally(function () {
        _this2.wonLoading = false;
      });
    }
  }, {
    key: 'getFavouritesLots',
    value: function getFavouritesLots() {
      var _this3 = this;

      this.favsLoading = true;
      this.R.flaggedLots(this.uid).then(function (res) {
        _this3.favsCount = res.count;
      }, function (err) {}).finally(function () {
        _this3.favsLoading = false;
      });
    }
  }]);

  return UserListTabController;
}();

exports.default = UserListTabController;

},{}],71:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userListTab = require('./userListTab.component');

var _userListTab2 = _interopRequireDefault(_userListTab);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserListTabModule = angular.module('app.shared.components.user.userlisttab', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('userListTab', _userListTab2.default);

exports.default = UserListTabModule;

},{"./userListTab.component":69}],72:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userLive = require('./userLive.controller');

var _userLive2 = _interopRequireDefault(_userLive);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserLiveComponent = function UserLiveComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'userLive.html',
    controller: _userLive2.default,
    controllerAs: "vm",
    scope: {}
  };
};

exports.default = UserLiveComponent;

},{"./userLive.controller":73}],73:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UserLiveController = function () {
  /*@ngInject*/

  function UserLiveController($rootScope, $scope, $sce, $state, $stateParams, RestFactory, AppSettings, LocaleFactory, FormatsFactory) {
    _classCallCheck(this, UserLiveController);

    //common features.
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.name = 'UserLiveController';
    this.R = RestFactory;

    //Custom code.
    this.uid = $scope.uid || parseInt(AppSettings.user.id);
    this.loading = false;
    this.lotsCount = 0;
    this.lots = [];

    this.pageRange = AppSettings.variables.all_lots_range || 12;
    console.log(this.pagesRange);
    this.hidePaging = false;

    this.getUser(this.uid);
    this.getUserLots(1);
  }

  _createClass(UserLiveController, [{
    key: 'getUser',
    value: function getUser(uid) {
      var _this = this;

      this.R.User(uid).then(function (res) {
        _this.user = res.data[0];
      });
    }
  }, {
    key: 'getUserLots',
    value: function getUserLots(page) {
      var _this2 = this;

      this.loading = true;
      this.R.userLiveLots(this.uid, this.pageRange, page, { sort: 'timeout' }).then(function (res) {
        _this2.lots = res.data;
        _this2.lotsCount = res.count;
      }, function (err) {
        console.log(err);
      }).finally(function () {
        _this2.loading = false;
      });
    }
  }, {
    key: 'pageChanged',
    value: function pageChanged(page) {
      console.log(page);
      this.getUserLots(page);
    }
  }]);

  return UserLiveController;
}();

exports.default = UserLiveController;

},{}],74:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userLive = require('./userLive.component');

var _userLive2 = _interopRequireDefault(_userLive);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserLiveModule = angular.module('app.shared.components.user.userlive', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('userLive', _userLive2.default);

exports.default = UserLiveModule;

},{"./userLive.component":72}],75:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userLiveItem = require('./userLiveItem.controller');

var _userLiveItem2 = _interopRequireDefault(_userLiveItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserLiveItemComponent = function UserLiveItemComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'userLiveItem.html',
    controller: _userLiveItem2.default,
    controllerAs: "vm",
    scope: {
      lot: "=",
      user: "=",
      live: "="
    }
  };
};

exports.default = UserLiveItemComponent;

},{"./userLiveItem.controller":76}],76:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UserLiveItemController =
/*@ngInject*/

function UserLiveItemController($rootScope, $scope, $sce, AppSettings, LocaleFactory, FormatsFactory) {
  _classCallCheck(this, UserLiveItemController);

  //common features.
  this.settings = AppSettings;
  this.locale = LocaleFactory;
  this.formats = FormatsFactory;
  this.trustAsHtml = $sce.trustAsHtml;
  this.t = Drupal.t;
  this.name = 'UserLiveItemController';
  this.lot = $scope.lot;
  this.user = $scope.user;
  this.isLive = $scope.live;

  //Custom code.
};

exports.default = UserLiveItemController;

},{}],77:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userLiveItem = require('./userLiveItem.component');

var _userLiveItem2 = _interopRequireDefault(_userLiveItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserLiveItemModule = angular.module('app.shared.components.user.userliveitem', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('userLiveItem', _userLiveItem2.default);

exports.default = UserLiveItemModule;

},{"./userLiveItem.component":75}],78:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userLots = require('./userLots.controller');

var _userLots2 = _interopRequireDefault(_userLots);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserLotsComponent = function UserLotsComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'userLots.html',
    controller: _userLots2.default,
    controllerAs: "vm",
    scope: {}
  };
};

exports.default = UserLotsComponent;

},{"./userLots.controller":79}],79:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UserLotsController = function () {
  /*@ngInject*/

  function UserLotsController($rootScope, $scope, $sce, $cookies, RestFactory, AppSettings, LocaleFactory, FormatsFactory) {
    var _this = this;

    _classCallCheck(this, UserLotsController);

    //common features.
    this.name = 'UserLotsController';
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.R = RestFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;

    //Custom code.
    this.viewMode = $cookies.get('UserViewMode') || 'list';
    this.pageRange = 2; //AppSettings.auctions_home_range || 6;

    this.lots = [];
    this.favouriteLots = [];
    this.loading = false;
    this.favouriteLoading = false;
    this.itemCount = 0;
    this.favouriteCount = 0;
    this.awardedCount = 0;
    this.uid = parseInt(this.settings.pathArgument1);

    //    this.getUserLots();
    //    this.getFavouritesLots();

    //EVENTS
    $scope.$on('UserViewModeChanged', function (ev, mode, scope) {
      _this.viewMode = mode;
    });
  }

  _createClass(UserLotsController, [{
    key: 'getUserLots',
    value: function getUserLots(page) {
      var _this2 = this;

      this.loading = true;
      this.R.userLots(this.uid, this.pageRange, page).then(function (res) {
        _this2.itemCount = res.count;
        _this2.lots = [];
        angular.forEach(res.data, function (lot) {
          lot.awarded = lot.order && parseInt(lot.order.uid) == _this2.uid;
          lot.awarded ? _this2.awardedCount += 1 : '';
          _this2.lots.push(lot);
        });
      }, function (err) {}).finally(function () {
        _this2.loading = false;
      });
    }
  }, {
    key: 'getFavouritesLots',
    value: function getFavouritesLots() {
      var _this3 = this;

      this.favouriteLoading = true;
      this.R.flaggedLots(this.uid).then(function (res) {
        _this3.favouriteLots = res.data;
        _this3.favouriteCount = res.count;
      }, function (err) {}).finally(function () {
        _this3.favouriteLoading = false;
      });
    }
  }, {
    key: 'pageChanged',
    value: function pageChanged(page) {
      this.getUserLots(page);
    }
  }]);

  return UserLotsController;
}();

exports.default = UserLotsController;

},{}],80:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userLots = require('./userLots.component');

var _userLots2 = _interopRequireDefault(_userLots);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserLotsModule = angular.module('app.shared.components.user.userlots', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('userLots', _userLots2.default);

exports.default = UserLotsModule;

},{"./userLots.component":78}],81:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userMyLots = require('./userMyLots.controller');

var _userMyLots2 = _interopRequireDefault(_userMyLots);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserMyLotsComponent = function UserMyLotsComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'userMyLots_v1.html',
    controller: _userMyLots2.default,
    controllerAs: "vm",
    scope: {}
  };
};

exports.default = UserMyLotsComponent;

},{"./userMyLots.controller":82}],82:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UserMyLotsController = function () {
  /*@ngInject*/

  function UserMyLotsController($rootScope, $scope, $sce, AppSettings, RestFactory, $cookies, LocaleFactory, FormatsFactory) {
    var _this = this;

    _classCallCheck(this, UserMyLotsController);

    //common features.
    this.name = 'UserLotsController';
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.R = RestFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;

    //Custom code.
    this.viewMode = $cookies.get('UserViewMode') || 'list';
    this.pageRange = 6;
    this.selectedTime = 1;
    this.startDate = moment().unix();
    this.endDate = moment().subtract(10, 'days').unix();

    this.lots = [];
    this.loading = false;
    this.itemCount = 0;
    //    this.awardedCount = 0;
    this.uid = parseInt(this.settings.pathArgument1);

    this.getUser(this.uid);
    this.getUserLots();

    //EVENTS
    $scope.$on('UserViewModeChanged', function (ev, mode, scope) {
      _this.viewMode = mode;
    });
  }

  _createClass(UserMyLotsController, [{
    key: 'searchPeriod',
    value: function searchPeriod(start, end, time) {
      this.startDate = null;
      this.endDate = null;
      this.selectedTime = time;

      if (start) {
        this.startDate = moment().subtract(start, 'days').unix();
      }

      if (end) {
        this.endDate = moment().subtract(end, 'days').unix();
      }

      this.getUserLots(1);
    }
  }, {
    key: 'getUser',
    value: function getUser(uid) {
      var _this2 = this;

      this.R.User(uid).then(function (res) {
        _this2.user = res.data[0];
      });
    }
  }, {
    key: 'getUserLots',
    value: function getUserLots(page) {
      var _this3 = this;

      this.loading = true;
      var query = {};
      if (this.startDate) {
        query['starting_date'] = this.startDate;
      }

      if (this.endDate) {
        query['ending_date'] = this.endDate;
      }

      this.R.userLots(this.uid, this.pageRange, page, query).then(function (res) {
        _this3.itemCount = res.count;
        _this3.lots = res.data;
        //        this.lots = [];
        //        angular.forEach(res.data, (lot) => {
        //          lot.awarded = lot.order && (parseInt(lot.order.uid) == this.uid);
        //          lot.awarded ? this.awardedCount += 1 : '';
        //          this.lots.push(lot);
        //        });
      }, function (err) {}).finally(function () {
        _this3.loading = false;
      });
    }
  }, {
    key: 'pageChanged',
    value: function pageChanged(page) {
      this.getUserLots(page);
    }
  }]);

  return UserMyLotsController;
}();

exports.default = UserMyLotsController;

},{}],83:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userMyLots = require('./userMyLots.component');

var _userMyLots2 = _interopRequireDefault(_userMyLots);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserMyLotsModule = angular.module('app.shared.components.user.usermylots', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('userMyLots', _userMyLots2.default);

exports.default = UserMyLotsModule;

},{"./userMyLots.component":81}],84:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userWonBids = require('./userWonBids.controller');

var _userWonBids2 = _interopRequireDefault(_userWonBids);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserWonBidsComponent = function UserWonBidsComponent(AppSettings) {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'userWonBids.html',
    controller: _userWonBids2.default,
    controllerAs: "vm",
    scope: {}
  };
};

exports.default = UserWonBidsComponent;

},{"./userWonBids.controller":85}],85:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UserWonBidsController = function () {
  /*@ngInject*/

  function UserWonBidsController($rootScope, $scope, RestFactory, $cookies, $sce, AppSettings, LocaleFactory, FormatsFactory) {
    var _this = this;

    _classCallCheck(this, UserWonBidsController);

    //common features.
    this.name = 'UserWonBidsController';
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.R = RestFactory;
    this.uid = this.settings.pathArgument1;
    this.viewMode = $cookies.get('UserViewMode') || 'list';

    //Custom code.
    this.loading = false;
    this.itemCount = 0;
    this.pageRange = 9;
    this.lots = [];

    this.getWonBids();
    //EVENTS
    $scope.$on('UserViewModeChanged', function (ev, mode, scope) {
      _this.viewMode = mode;
    });
  }

  _createClass(UserWonBidsController, [{
    key: 'getWonBids',
    value: function getWonBids(page) {
      var _this2 = this;

      page = page || 1;

      this.loading = true;
      var query = {
        'filter[order]': this.uid
      };

      this.R.userWonLots(this.uid, this.pageRange, page).then(function (res) {
        _this2.itemCount = res.count;
        _this2.lots = res.data;
      }, function (err) {}).finally(function () {
        _this2.loading = false;
      });
    }
  }]);

  return UserWonBidsController;
}();

exports.default = UserWonBidsController;

},{}],86:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _userWonBids = require('./userWonBids.component');

var _userWonBids2 = _interopRequireDefault(_userWonBids);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UserWonBidsModule = angular.module('app.shared.components.user.userwonbids', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
]).directive('userWonBids', _userWonBids2.default);

exports.default = UserWonBidsModule;

},{"./userWonBids.component":84}],87:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var CapitalizeModule = angular.module('app.shared.filters.capitalize', []).filter('capitalize', function () {
  "ngInject";

  return function (input, all) {
    var reg = all ? /([^\W_]+[^\s-]*) */g : /([^\W_]+[^\s-]*)/;
    return !!input ? input.replace(reg, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }) : '';
  };
});

exports.default = CapitalizeModule;

},{}],88:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _startFrom = require('./startFrom/startFrom.filter');

var _startFrom2 = _interopRequireDefault(_startFrom);

var _capitalize = require('./capitalize/capitalize.filter');

var _capitalize2 = _interopRequireDefault(_capitalize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Filters = angular.module('app.shared.filters', [_capitalize2.default.name, _startFrom2.default.name]);

exports.default = Filters;

},{"./capitalize/capitalize.filter":87,"./startFrom/startFrom.filter":89}],89:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var StartFrom = angular.module('app.shared.filters.startfrom', []).filter('startFrom', function () {
  "ngInject";

  return function (input, start) {
    if (input.length) {
      start = +start; //parse to int
      return input.slice(start);
    }
  };
});

exports.default = StartFrom;

},{}],90:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _appSettings = require('./appSettings.service');

var _appSettings2 = _interopRequireDefault(_appSettings);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AppSettings = angular.module('app.shared.services.settings', ['ngLodash']).factory('AppSettings', _appSettings2.default);

exports.default = AppSettings;

},{"./appSettings.service":91}],91:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
function AppSettings($rootScope, lodash) {
  "ngInject";

  var settings = Drupal.settings.angmod;
  var _ = lodash;

  Drupal.behaviors.angmod = function (context) {
    settings.name = 'AppSettings';
    settings.lang = settings.lang || {};
    settings.basePath = Drupal.settings.basePath;
    settings.pathPrefix = Drupal.settings.pathPrefix;

    /**
     * Global Settings
     *
     * when a value is set to 0 (zero) it means unlimited.
     */

    //Deafult number of items per page
    //fallback if can't get the value from the variables.
    settings.pagesRange = 6;
    //Number of news in the latest news block
    settings.latestNewsItems = 3;
    //Number of featured lots
    settings.featuredLotsItems = 3;

    //Track currents search text and category filter
    settings.searchText = '';
    settings.category = '';
    settings.categoryName = '';

    //Reset search filters
    settings.resetFilters = function () {
      "use strict";

      settings.searchText = '';
      settings.category = '';
      settings.categoryName = '';
    };

    //TODO:REMOVE
    settings.lang.noItemFound = Drupal.t('No item available at the moment.');

    settings.isAdmin = function () {
      "use strict";

      return _.includes(settings.user.roles, 'administrator');
    };

    settings.isAuthenticated = function () {
      "use strict";

      return parseInt(settings.user.id) > 0;
    };

    settings.isBidder = function () {
      "use strict";

      return _.includes(settings.user.roles, 'bidder');
    };

    settings.dateDiff = function (date) {
      "use strict";

      date = date * 1000;
      var now = moment().tz(settings.timezone).unix();
      //      var now = new Date();
      //      now = now.getTime();
      var diff = date - now;
      return diff;
    };

    //Add an item to the favourite list
    settings.addLotToFavourite = function (id, listName) {};

    //Get the correct expiring date time
    settings.getExpiringDateTime = function (date, scope) {
      scope.expireDate = settings.dateDiff(date);
      return scope.expireDate;
    };

    //Check whether an item date is expired
    settings.itemExpired = function (date, scope) {
      return settings.getExpiringDateTime(date, scope) <= 0;
    };

    $rootScope.$on('$stateChangeStart', function () {
      settings.search = null;
      settings.category = null;
    });
  }.bind(this)();

  return settings;
}

exports.default = AppSettings;

},{}],92:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var EventHelper = function EventHelper($rootScope) {
  "use strict";
  "ngInject";

  var loading = false;
  var BidPlaced = 'BidPlaced';

  function broadcastGlobalEvent(eventName, data, scope) {
    $rootScope.$broadcast(eventName, data, scope);
  }

  function globalLoadingStart() {
    loading = true;
  }

  function globalLoadingStop() {
    loading = false;
  }

  var methods = {
    broadcastGlobalEvent: broadcastGlobalEvent,
    globalLoadingStart: globalLoadingStart,
    globalLoadingStop: globalLoadingStop,
    loading: loading
  };

  return methods;
};

exports.default = EventHelper;

},{}],93:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _event = require('./event.factory');

var _event2 = _interopRequireDefault(_event);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = angular.module('app.shared.services.helper.event', []).factory('EventHelper', _event2.default);

exports.default = _module;

},{"./event.factory":92}],94:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _formats = require('./formats.service');

var _formats2 = _interopRequireDefault(_formats);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Formats = angular.module('app.shared.services.helper.formats', []).factory('FormatsFactory', _formats2.default);

exports.default = Formats;

},{"./formats.service":95}],95:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
function FormatsFactory(AppSettings, $filter, LocaleFactory) {
  "ngInject";

  var factory = {};
  var filter = $filter;
  factory.name = 'Formats';
  var tz = AppSettings.timezone;
  moment.locale(AppSettings.language.language);

  var siteUrl = AppSettings.sitePath + AppSettings.basePath + AppSettings.pathPrefix;

  function formateTimestampToDate(timestamp, format) {
    timestamp *= 1000;
    format = format || 'dd/MM/yyyy';
    return filter('date')(new Date(timestamp), format);
  }

  Drupal.behaviors.angmod = function (context) {

    factory.siteUrl = function () {
      "use strict";

      return siteUrl;
    };

    factory.getDateStatus = function (date) {};

    factory.lotsPlural = function (count) {
      count = count || 0;
      return Drupal.formatPlural(count, '1 ' + LocaleFactory.lot, '@count ' + LocaleFactory.lots);
    };

    factory.ClosesOn = function (date, shortDate, shortTime) {
      var _date = shortDate ? formateTimestampToDate(date, 'dd/MM/yy') : formateTimestampToDate(date);
      var _time = shortTime ? formateTimestampToDate(date, 'HH:mm') : formateTimestampToDate(date, 'HH:mm:ss');
      return Drupal.t('Closes on !date at !time', {
        '!date': _date,
        '!time': _time
      });
    };

    factory.startClosingOnOld = function (date) {

      var diff = AppSettings.dateDiff(date);

      if (diff <= 0) {
        return LocaleFactory.Closing;
      }

      var _date = formateTimestampToDate(date);
      var _time = formateTimestampToDate(date, 'HH:mm');

      return Drupal.t('Start closing on !date at !time', {
        '!date': _date,
        '!time': _time
      });
    };

    factory.startClosingOn = function (timestamp) {
      var today = moment().tz(tz);
      var time = timestamp * 1000;
      var passedDate = moment(time).tz(tz);
      var isToday = today.isSame(passedDate, 'd');
      if (isToday) {
        return Drupal.t('Closes today at !time', { '!time': passedDate.format('HH:mm') });
      }
      return Drupal.t('Start closing on !date at !time', {
        '!date': passedDate.format('DD/MM/YYYY'),
        '!time': passedDate.format('HH:mm')
      });
    };

    factory.formatStartEndDates = function (start, end, dateFormat, timeFormat) {
      "use strict";

      var dayFormat = dateFormat || 'dd/MM/yyyy';
      var hourFormat = timeFormat || 'HH:mm';

      var _dayStart = formateTimestampToDate(start, dayFormat);
      var _hourStart = formateTimestampToDate(start, hourFormat);
      var _dayEnd,
          _hourEnd = null;

      if (end && end != start) {
        _dayEnd = formateTimestampToDate(end, dayFormat);
        _hourEnd = formateTimestampToDate(end, hourFormat);

        if (_dayStart == _dayEnd) {

          return "<div>" + _dayStart + "<span class='date-separator'>&nbsp;&nbsp;-&nbsp;&nbsp;</span> " + _hourStart + "<span class='date-separator'>/</span>" + _hourEnd + "</div>";
        }

        return Drupal.t("From !start <br>To !end", {
          "!start": _dayStart + " " + _hourStart, "!end": _dayEnd + " " + _hourStart
        });
      }

      return _dayStart + "<span class='date-separator'>&nbsp;&nbsp;-&nbsp;&nbsp;</span>" + _hourStart;
    };

    factory.formatDate = function (timestamp, dateFormat, timeFormat) {
      var date = timestamp * 1000;
      var _date = filter('date')(new Date(date), dateFormat || 'dd/MM/yyyy');
      var _dateShort = filter('date')(new Date(date), timeFormat || 'dd/MM/yy');
      var _time = filter('date')(new Date(date), timeFormat || 'HH:mm:ss');
      var _timeShort = filter('date')(new Date(date), timeFormat || 'HH:mm');
      return {
        date: _date,
        time: _time,
        timeShort: _timeShort,
        fullDate: Drupal.formatString('!date !time', { '!date': _date, '!time': _time }),
        shortDate: _dateShort + ' ' + _timeShort
      };
    };

    factory.lastBidFormat = function (amount) {
      "use strict";

      return Drupal.t('Last bid: !amount', { "!amount": amount });
    };

    factory.bidsCount = function (count) {
      "use strict";

      return Drupal.formatPlural(count, '1 ' + LocaleFactory.bid, '@count ' + LocaleFactory.bids);
    };
  }.bind(this)();

  return factory;
}

exports.default = FormatsFactory;

},{}],96:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _event = require('./event/event.module');

var _event2 = _interopRequireDefault(_event);

var _model = require('./model/model.module');

var _model2 = _interopRequireDefault(_model);

var _locale = require('./locale/locale');

var _locale2 = _interopRequireDefault(_locale);

var _formats = require('./formats/formats');

var _formats2 = _interopRequireDefault(_formats);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HelperModule = angular.module('app.shared.services.helper', [_model2.default.name, _event2.default.name, _locale2.default.name, _formats2.default.name]);

exports.default = HelperModule;

},{"./event/event.module":93,"./formats/formats":94,"./locale/locale":97,"./model/model.module":100}],97:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _locale = require('./locale.service');

var _locale2 = _interopRequireDefault(_locale);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Locale = angular.module('app.shared.services.helper.locale', []).factory('LocaleFactory', _locale2.default);

exports.default = Locale;

},{"./locale.service":98}],98:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
function LocaleFactory($filter, AppSettings) {
  "ngInject";

  var factory = {};
  var settings = AppSettings;

  Drupal.behaviors.angmod = function (context) {
    factory.name = 'Locale';
    factory.slogan = Drupal.t('Welcome to @siteName', {
      '@siteName': AppSettings.siteName
    });

    //Common Lot's strings.
    factory.Lot = Drupal.t('Lot');
    factory.lot = Drupal.t('lot');
    factory.Lots = Drupal.t('Lots');
    factory.lots = Drupal.t('lots');
    factory.MyLots = Drupal.t('My lots');
    factory.nextLot = Drupal.t('Next lot');
    factory.previousLot = Drupal.t('Previous lot');
    factory.FeaturedLots = Drupal.t('Featured lots');
    factory.Featured = Drupal.t('Featured');
    factory.PastLots = Drupal.t('Past lots');
    factory.PastLot = Drupal.t('Past lot');
    factory.FutureLots = Drupal.t('Future lots');
    factory.FutureLot = Drupal.t('Future lot');
    factory.ActualLots = Drupal.t('Current lots');
    factory.ActualLot = Drupal.t('Current lot');

    //BID
    factory.NextMinBid = Drupal.t('Next minimum bid');
    factory.Multiple = Drupal.t('Bid must be a mutiple of');
    factory.CurrentBid = Drupal.t('Current bid');
    factory.PlaceABid = Drupal.t('Place a bid');
    factory.PlaceAnAutoBid = Drupal.t('Place an auto bid');
    factory.Bid = Drupal.t('Bid');
    factory.AutoBid = Drupal.t('Auto Bid');
    factory.OpeningBid = Drupal.t('Opening bid');
    factory.bidStep = Drupal.t('Bid step');
    factory.numberOfBids = Drupal.t('Number of bids');
    factory.auctionCost = Drupal.t('Auction cost');
    factory.VAT = Drupal.t('VAT');
    factory.auctionCostVAT = Drupal.t('Auction cost VAT');
    factory.Price = Drupal.t('Price');
    factory.Total = Drupal.t('Total');

    factory.invalidAmount = Drupal.t('The amount is not valid.');
    factory.confirmBidTitle = Drupal.t('Confirm your bid.');
    factory.confirmBidContent = Drupal.t('Before proceding please review the the bid details.');
    factory.confirmBidOk = Drupal.t('The amount is not valid.');
    factory.confirmBidCancel = Drupal.t('The amount is not valid.');

    //Common Auction's strings.
    factory.Auction = Drupal.t('Auction');
    factory.auction = Drupal.t('auction');
    factory.Auctions = Drupal.t('Auctions');
    factory.auctions = Drupal.t('auctions');
    factory.AllAuctions = Drupal.t('All auctions');
    factory.PastAuction = Drupal.t('Past auction');
    factory.FutureAuctions = Drupal.t('Open Soon');
    factory.FutureAuction = Drupal.t('Opens Soon');
    factory.ActualAuctions = Drupal.t('Current auctions');

    factory.OurAuctions = Drupal.t('Our auction');
    factory.RetailAuctions = Drupal.t('Retails');
    factory.AcquisitionAuctions = Drupal.t('Acquisitions');
    factory.PastAuctions = Drupal.t('Past auctions');

    factory.downloadCatalog = Drupal.t('download catalog');

    //Common Strings translation
    factory.home = Drupal.t('Home');
    factory.ClosesOn = Drupal.t('Closes on');
    factory.closesOn = Drupal.t('closes on');
    factory.closing = Drupal.t('closing');
    factory.Closing = Drupal.t('Closing');
    factory.closed = Drupal.t('closed');
    factory.Closed = Drupal.t('Closed');
    factory.Close = Drupal.t('Close');
    factory.Suspended = Drupal.t('Suspended');
    factory.Expired = Drupal.t('Expired');
    factory.Awarded = Drupal.t('Awarded');
    factory.wonMessage = Drupal.t('Congratulation. You won this auction');
    factory.lostMessage = Drupal.t('Unfortunatly this auction was awarded by onother');

    factory.isLeaderMessage = Drupal.t("Currently you are the bid leader");
    factory.isNotLeaderMessage = Drupal.t("You are not the bid leader");

    factory.Description = Drupal.t('Description');
    factory.lastBid = Drupal.t('Last bid');
    factory.openingBid = Drupal.t('Opening bid');
    factory.GeneralInformation = Drupal.t('General Information');
    factory.Categories = Drupal.t("Categories");
    factory.OpeningDate = Drupal.t("Opening date");
    factory.ClosingDate = Drupal.t("Closing date");
    factory.VisitingDate = Drupal.t("Viewing days");
    factory.PickUpDate = Drupal.t("Pick-up date");
    factory.Location = Drupal.t("Location");
    factory.LatestNews = Drupal.t('Latest news');

    //Wide-site strings
    factory.Accept = Drupal.t('Accept');
    factory.Cancel = Drupal.t('Cancel');
    factory.User = Drupal.t('User');
    factory.Company = Drupal.t('Company');
    factory.Login = Drupal.t('Login');
    factory.Register = Drupal.t('Register');
    factory.Company = Drupal.t('Company');
    factory.Phone = Drupal.t('Phone');
    factory.VAT = Drupal.t('VAT');
    factory.Live = Drupal.t('Live');
    factory.LivePageTitel = Drupal.t('Live bid on your items !');

    factory.Bailiff = Drupal.t("Bailiff");
    factory.Curator = Drupal.t("Curator");

    factory.Search = Drupal.t('Search');
    factory.SearchResults = Drupal.t('Search results');
    factory.search = Drupal.t('search');
    factory.searchForAuctions = Drupal.t('Search for auctions');
    factory.searchInThisAuction = Drupal.t('Search in this auctions');
    factory.searchForLots = Drupal.t('Search for lots');
    factory.noItemFound = Drupal.t('No item available at the moment.');
    factory.cancelSearch = Drupal.t('Clear current search query and category filters');
    factory.MoreInfo = Drupal.t('More info');
    factory.ListView = Drupal.t('List view');
    factory.GalleryView = Drupal.t('Gallery view');
    factory.YouAreHere = Drupal.t('You are here');
    factory.FAQ = Drupal.t("Frequently Asked Questions");
    factory.News = Drupal.t('News');
    factory.MyProfile = Drupal.t('My Profile');
    factory.Username = Drupal.t("Username");
    factory.Active = Drupal.t("Active");
    factory.Bidder = Drupal.t("Bidder");
    factory.gsmAuthenticated = Drupal.t("GSM Authenticated");
    factory.City = Drupal.t("City");
    factory.Country = Drupal.t("Country");
    factory.Address = Drupal.t("Address");
    factory.Edit = Drupal.t("Edit");
    factory.ClientNumber = Drupal.t("Client number");
    factory.YourClientNumber = Drupal.t("Your Client number");
    factory.YourList = Drupal.t("Your list");
    factory.AllLots = Drupal.t('All lots');
    factory.lotsBidOn = Drupal.t('Lots bid on');
    factory.WonBids = Drupal.t('Won bids');
    factory.FavouriteLots = Drupal.t('Favourite lots');
    factory.TypeHereForSearchingAuctionsOrLots = Drupal.t('Type here for searching auctions or lots');
    factory.Last10days = Drupal.t('Last 10 days');
    factory.Last15days = Drupal.t('Last 15 days');
    factory.Last30days = Drupal.t('Last 30 days');
    factory.AllTime = Drupal.t('All time');
    //Common bid strings.
    factory.registerToBid = Drupal.t('In order to place bids you must be a registered user and have a verified account');
    factory.Bid = Drupal.t('Bid');
    factory.bid = Drupal.t('bid');
    factory.Bids = Drupal.t('Bids');
    factory.bids = Drupal.t('bids');
    factory.proxyDefinitionTitle = Drupal.t('Proxy Bidding');
    factory.itemsFoundCount = function (total) {
      "use strict";

      return Drupal.formatPlural(total, Drupal.t('Found 1 item'), Drupal.t('Found @num items', { '@num': total }));
    };
  }.bind(this)();

  return factory;
}

exports.default = LocaleFactory;

},{}],99:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ModelHelper = function ModelHelper($rootScope, lodash) {
  "use strict";
  "ngInject";

  var _ = lodash;

  function getById(id, model) {
    _.find(model, function (item) {
      return item.id == id;
    });
  }

  function updateItem(item, model) {
    var index = _.findIndex(model, function (i) {
      return item.id == i.id;
    });
    if (index != -1) {
      model[index] = item;
      return item;
    }
    return false;
  }

  var methods = {
    getById: getById,
    updateItem: updateItem
  };

  return methods;
};

exports.default = ModelHelper;

},{}],100:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _model = require('./model.factory');

var _model2 = _interopRequireDefault(_model);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ModelHelperModule = angular.module('app.shared.services.helper.model', ['ngLodash']).factory('ModelHelper', _model2.default);

exports.default = ModelHelperModule;

},{"./model.factory":99}],101:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var RestFactory = function RestFactory(Restangular, AppSettings) {
  "ngInject";
  "use strict";

  var imageStyle = 'square';

  /**
   *
   * @param query
   * @param style
   *  A drupal Image style. Default to {imageStyle}.
   *  A value of 'none' will return no styled images.
   * @returns {*|{}}
   */
  var preBuildQuery = function preBuildQuery(query, style, range, page) {
    var query = query || {};
    if (style != 'none') {
      query['style'] = style || imageStyle;
    }

    if (range) {
      query['range'] = range;
    }

    if (page) {
      query['page'] = page;
    }
    if (!query.hasOwnProperty('sort') && query['sort'] != null) {
      query['sort'] = '-created';
    }

    return query;
  };

  var token = '';

  (function () {
    if (AppSettings.isAuthenticated()) {
      Restangular.one('session/token').get().then(function (res) {
        token = res['X-CSRF-Token'] || res.data['X-CSRF-Token'];
        Restangular.setDefaultHeaders({ "X-CSRF-Token": token });
      });
    }
  })();

  function Rest() {
    var _this = this;

    this.getToken = function () {
      return token;
    };

    this.Factory = function () {
      return Restangular;
    };

    /**
     * Auctions and Lots stauses.
     *
     * buy_status: (Lots):
     *  0 in progress
     *  1: order placed.
     *
     * item_status: (Lots and Auctions):
     *  0: Open
     *  2: Closed
     *  3: Suspended.
     *
     *  auction_item_status (Lots)
     *  the {@item_status} of the parent auction.
     *
     * auction_published (Lots)
     *  0: parent auction not published
     *  1: parent auction published
     *
     *  published (Auctions and Lots)
     *  0/1 whether the node is published or not.
     *
     */
    this.auction = function (id, style) {
      var query = preBuildQuery({}, style);
      query['sort'] = null;
      var auction = Restangular.one('auctions', id);
      return auction.get(query);
    };

    this.auctions = function (query, style, range, page) {
      query = preBuildQuery(query, style, range, page);
      if (!query['sort']) {
        query['sort'] = 'closing_date';
      }
      var auctions = Restangular.all('auctions');
      return auctions.getList(query);
    };

    this.nodes = function (query, style, range, page) {
      query = preBuildQuery(query, style, range, page);
      var nodes = Restangular.all('nodes');
      return nodes.getList(query);
    };

    this.news = function (query, style, range, page) {
      query = preBuildQuery(query, style, range, page);
      query['type'] = 'news';
      var nodes = Restangular.all('news');
      return nodes.getList(query);
    };

    this.faqs = function (query, style, range, page) {
      query = preBuildQuery(query, 'none', range, page);
      query['type'] = 'faq';
      var faqs = Restangular.all('faq');
      return faqs.getList(query);
    };

    this.lots = function (query, style, range, page) {
      query = preBuildQuery(query, style, range, page);

      if (!query.hasOwnProperty('sort')) {
        query['sort'] = 'timeout';
      }
      //      query['filter[auction][value]'] = 'false';
      //      query['filter[auction][operator]'] = '<>';
      var lots = Restangular.all('lots');
      return lots.getList(query);
    };

    this.lot = function (id, style, query) {
      query = preBuildQuery(query, style);
      var lot = Restangular.one('lots', id);
      return lot.get(query);
    };

    this.featuredAuctions = function (query, style, range, page) {
      query = preBuildQuery(query, style, range, page);
      query['filter[featured]'] = 1;
      var auctions = Restangular.all('auctions');
      return auctions.getList(query);
    };

    this.featuredLots = function (query, style, range, page) {
      query = preBuildQuery(query, style, range, page);
      query['filter[featured]'] = 1;
      var lots = Restangular.all('lots');
      return lots.getList(query);
    };

    this.lotsByCategory = function (tid, query, style, range, page) {
      query = preBuildQuery(query, style, range, page);
      query['filter[categories][value]'] = tid;
      query['filter[categories][operator]'] = 'IN';
      return _this.lots(query);
    };

    this.search = function (text, query, range, page) {
      query = preBuildQuery(query, false, range, page);
      query['sort'] = 'relevance';
      var search = Restangular.all('search/' + text);
      return search.getList(query);
    };

    this.flag = function (id, name) {
      name = name || 'lots_list';
      var flag = Restangular.one('flag', id);
      flag.name = name;
      return flag.post();
    };

    this.parentCategories = function () {
      var catgs = Restangular.all('parent_categories');
      return catgs.getList({ 'sort': 'weight' });
    };

    this.categories = function (tid) {
      var catgs = void 0;

      if (tid) {
        catgs = Restangular.one('categories', tid);
        return catgs.get({ 'sort': 'weight' });
      }
      catgs = Restangular.all('categories');
      return catgs.getList({ 'sort': 'weight' });
    };

    /**
     * lotId: the lot nid;
     * amount: amount in the format of drupal commerce (e.g. 100 = 10000 / 100,50 == 10050)
     * bidType: 0: direct, 1: proxy.
     */
    this.placeBid = function (lotId, amount, bidType) {
      var point = Restangular.all('placebid');
      var query = {
        nid: lotId,
        amount: amount,
        bid_type: bidType
      };
      return point.post(query);
    };

    /**
     * Get user's flagged lots
     *
     * @method flaggedLots
     * @param UserId {integer} The unique user id (uid)
     * @param range {integer} The returned result range
     * @param page {integer} The returned page
     * @return {object}
     */
    this.flaggedLots = function (UserId, range, page, query) {
      if (!UserId) {
        throw new Error('User Id is mandatory');
      }
      var $query = query || {};

      $query = preBuildQuery($query, null, range, page);
      $query['user'] = UserId;
      $query['filter[auction.id][value]'] = 0;
      $query['filter[auction.id][operator]'] = '>';
      var endpoint = Restangular.all('flaggedlots');
      return endpoint.getList($query);
    };

    /**
     * Get lots where user placed a bid
     *
     * @method userLots
     * @param UserId {integer} The unique user id (uid)
     * @param range {integer} The returned result range
     * @param page {integer} The returned page
     * @return {object}
     */
    this.userLots = function (UserId, range, page, query) {
      if (!UserId) {
        throw new Error('User Id is mandatory');
      }
      var $query = query || {};

      $query = preBuildQuery($query, null, range, page);
      $query['user'] = UserId;
      $query['filter[auction.id][value]'] = 0;
      $query['filter[auction.id][operator]'] = '>';
      var endpoint = Restangular.all('userlots');
      return endpoint.getList($query);
    };

    this.userLiveLots = function (uid, range, page, query) {
      var $query = preBuildQuery(query || {}, null, range, page);
      // $query['filter[timeout][value]'] = moment().unix();
      // $query['filter[timeout][operator]'] = '>=';
      // $query['filter[status]'] = '1';
      $query['filter[buy_status]'] = '0';
      $query['filter[item_status]'] = '0';
      $query['sort'] = 'timeout';
      $query['user'] = uid;
      $query['filter[auction.id][value]'] = 0;
      $query['filter[auction.id][operator]'] = '>';
      var endpoint = Restangular.all('userlivelots');
      return endpoint.getList($query);
    };

    this.userOpenLots = function (UserId, range, page, query) {
      if (!UserId) {
        throw new Error('User Id is mandatory');
      }
      var $query = query || {};

      $query = preBuildQuery($query, null, range, page);
      $query['user'] = UserId;
      $query['filter[expired]'] = '0';
      $query['filter[auction.id][value]'] = 0;
      $query['filter[auction.id][operator]'] = '>';
      var endpoint = Restangular.all('userlots');
      return endpoint.getList($query);
    };

    /**
     * Get user profile
     *
     * @method User
     * @param UserId {integer} The unique user id (uid)
     * @param $query {object} A set of filters
     * @return {object}
     */
    this.User = function (UserId, $query) {
      $query = preBuildQuery($query, 'thumbnail');
      var endpoint = Restangular.one('v2.0/users', UserId);
      return endpoint.get($query);
    };

    /**
     * Get lots where user placed a bid
     *
     * @method userLots
     * @param UserId {integer} The unique user id (uid)
     * @param range {integer} The returned result range
     * @param page {integer} The returned page
     * @return {object}
     */
    this.userWonLots = function (UserId, range, page) {
      if (!UserId) {
        throw new Error('User Id is mandatory');
      }
      var $query = {};

      $query = preBuildQuery($query, null, range, page);
      $query['user'] = UserId;
      $query['filter[auction.id][value]'] = 0;
      $query['filter[auction.id][operator]'] = '>';
      var endpoint = Restangular.all('wonlots');
      return endpoint.getList($query);
    };
  }

  return new Rest();
};

exports.default = RestFactory;

},{}],102:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _rest = require('./rest.factory');

var _rest2 = _interopRequireDefault(_rest);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RestModule = angular.module('app.shared.services.rest', ['restangular']).config(['RestangularProvider', function (RestangularProvider) {
  "use strict";
  "ngInject";

  var settings = Drupal.settings.angmod;

  RestangularProvider.setBaseUrl(settings.restPath);

  RestangularProvider.addResponseInterceptor(function (data, operation, what, url, response, deferred) {
    var extractedData = [];

    if (operation === "getList") {
      extractedData.data = data.data;
      extractedData.response = response;
      extractedData.meta = data.meta;
      extractedData.count = data.count;
    } else {
      extractedData = data;
    }
    return extractedData;
  });
}]).factory('RestFactory', _rest2.default);

exports.default = RestModule;

},{"./rest.factory":101}],103:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _helper = require('./helper/helper.module');

var _helper2 = _interopRequireDefault(_helper);

var _rest = require('./rest/rest');

var _rest2 = _interopRequireDefault(_rest);

var _appSettings = require('./appSettings/appSettings');

var _appSettings2 = _interopRequireDefault(_appSettings);

var _webSocket = require('./webSocket/webSocket');

var _webSocket2 = _interopRequireDefault(_webSocket);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SharedServices = angular.module('app.shared.services', [_appSettings2.default.name, _rest2.default.name, _helper2.default.name, _webSocket2.default.name]);

exports.default = SharedServices;

},{"./appSettings/appSettings":90,"./helper/helper.module":96,"./rest/rest":102,"./webSocket/webSocket":104}],104:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _webSocket = require('./webSocket.service');

var _webSocket2 = _interopRequireDefault(_webSocket);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var WebSocket = angular.module('app.shared.services.websocket', ['ngWebSocket']).factory('WebSocketFactory', _webSocket2.default);

exports.default = WebSocket;

},{"./webSocket.service":105}],105:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
function WebSocketFactory($rootScope, AppSettings, LocaleFactory, FormatsFactory, $websocket) {
  "ngInject";

  var factory = {};
  var settings = AppSettings;
  var locale = LocaleFactory;
  var formats = FormatsFactory;

  factory.name = 'WebSocket';

  var protocol = AppSettings.web_socket.protocol;
  var host = AppSettings.web_socket.host;
  var port = AppSettings.web_socket.port;
  var address = protocol + host + ':' + port;
  var ws = $websocket(address);

  ws.onMessage(function (message) {
    "use strict";

    var data = void 0;
    if (message.data) {
      try {
        data = JSON.parse(message.data);
        var bid = data.bid;
        var lot = angular.copy(data.data);
        lot.leading.leading_uid = bid.leader_uid;
        lot.leading.leading_username = bid.leader_name;
        var l_uid = parseInt(bid.leader_uid);
        var c_uid = parseInt(AppSettings.user.id);
        lot.leading.user_leads = l_uid === c_uid;
        if (data.type == 'final_bid') {
          $rootScope.$broadcast('BidPlaced', lot);
        }
      } catch (e) {
        data = message.data;
      }
    }
  });

  return factory;
}

exports.default = WebSocketFactory;

},{}],106:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _common = require('./components/common.components');

var _common2 = _interopRequireDefault(_common);

var _filters = require('./filters/filters');

var _filters2 = _interopRequireDefault(_filters);

var _services = require('./services/services.module');

var _services2 = _interopRequireDefault(_services);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SharedComponents = angular.module('app.shared', [_filters2.default.name, _services2.default.name, _common2.default.name]);

exports.default = SharedComponents;

},{"./components/common.components":29,"./filters/filters":88,"./services/services.module":103}]},{},[2])

