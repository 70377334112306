import AuctionListComponent from './auctionList.component';

let AuctionListModule = angular.module('app.shared.components.auction.list', [
  'ngAnimate',
  'ngMaterial',
  'ngCookies',
  'ngAnimate'
])
  .directive('auctionList', AuctionListComponent)

export default AuctionListModule;
