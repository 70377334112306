import UserLiveComponent from './userLive.component';

let UserLiveModule = angular.module('app.shared.components.user.userlive', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('userLive', UserLiveComponent);

export default UserLiveModule;
