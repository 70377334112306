class LotBidController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              $timeout,
              lodash,
              AppSettings,
              $mdDialog,
              RestFactory,
              WebSocketFactory,
              LocaleFactory,
              FormatsFactory) {


    //common features.
    this.name = 'LotBidController';
    this.rootScope = $rootScope;
    this.scope = $scope;
    this.timeout = $timeout;
    this.settings = AppSettings;
    this.formats = FormatsFactory;
    this.locale = LocaleFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.Dialog = $mdDialog;
    this.t = Drupal.t;
    this.R = RestFactory;
    this.WS = WebSocketFactory;

    //Custom code.
    this.Lot = $scope.lot;
    this.hasErrors = false;
    this.hasMessages = false;
    this.messages = '';
    this.errors = '';
    this.loading = false;
    this.expired = false;
    this.pulse = false;
    this.expandableInfo = $scope.expandableinfo || false;
    this.collapsedInfo = $scope.collapsedinfo || false;
    this.infoExpanded = !this.collapsedInfo;

    //userStatus
    let _ = lodash;
    this.authenticated = _.includes(AppSettings.user.roles, 'authenticated user');
    this.bidder = _.includes(AppSettings.user.roles, 'bidder');

    //Lot statuses
    this.open = false;
    this.closed = false;
    this.suspended = false;
    this.awarded = false;
    this.auction = this.Lot.auction;

    const lotVAT = this.Lot.vat_rate !== null ? this.Lot.vat_rate : -1;
    const lotEC = this.Lot.ec_rate !== null ? this.Lot.ec_rate : -1;
    const lotEC_VAT = this.Lot.ec_vat !== null ? this.Lot.ec_vat : -1;

    this.vat_rate = lotVAT >= 0 ? lotVAT : this.auction.vat_rate !== null ? this.auction.vat_rate : parseFloat(AppSettings.auction.vat.rate)*100;

    this.ec_rate = lotEC >= 0 ? lotEC: this.auction.ec_rate !== null ? this.auction.ec_rate : parseFloat(AppSettings.auction.extra_cost.rate)*100;

    this.ec_vat_rate = lotEC_VAT >= 0 ? lotEC_VAT : this.auction.ec_vat !== null ? this.auction.ec_vat : parseFloat(AppSettings.auction.vat.rate)*100;


    //Auction Status
    this.auctionStatus = {
      Expired: this.auction.expired,
      Open: this.auction.item_status === '0',
      Closed: this.auction.item_status === '1',
      Suspended: this.auction.item_status === '2'
    };

    //Price variables
    this.highestBid;
    this.multiple;
    this.bidStep;
    this.price;
    this.maxBid;
    this.maxPercent
    this.bidsCount;
    this.leading = false;
    this.leadingMessage = '';


    //Calculate the right values
    this.calculateBidValues();


    $scope.$on('LotExpired', (ev, data) => {
      console.log('Processing expiration...');
      if (this.Lot.id == data.id) {
        $scope.lot = data;
        this.calculateBidValues();
      }
    });

    $scope.$on('BidPlaced', (ev, data) => {

      if (data.id == this.Lot.id) {
        $scope.lot = data;
        this.Lot = data;
        this.pulse = true;
        this.messages = this.errors = '';
        this.hasErrors = this.hasMessages = false;


        $timeout(()=> {
          this.pulse = false;
        }, 2000);

        this.calculateBidValues();
      }

    });
  }
  toggleInfo() {
    this.infoExpanded = !this.infoExpanded;
  }
  showProxyInfo() {
    this.Dialog.show(
      this.Dialog.alert()
        .parent(angular.element(document.body))
        .clickOutsideToClose(true)
        .title(this.locale.proxyDefinitionTitle)
        .textContent(this.settings.variables.proxy_definition)
        .ok('Got it!')
    );
  }

  isOpen() {
    return this.open && !this.expired && this.auctionStatus.Open;
  }

  isClosed() {
    return this.closed || this.expired || this.auctionStatus.Closed;
  }

  isSuspended() {
    return this.suspended || this.auctionStatus.Suspended;
  }

  closeBox(type) {

    switch (type) {
      case 'errors':
        this.hasErrors = false;
        break;
      case 'messages':
        this.hasMessages = false;
        break;
    }

  }

  showDialog(type, amount, form) {

    if (amount && form.$valid) {

      amount *= 100;
      let costs = this.calculateCosts(amount);

      let confirmMessage =
        "<div class='dialog__row'>" +
        "<div class='prop'>" + this.locale.Bid + " :</div>" +
        "<div class='value'>" + this.parseCurrencyWithDecimal(costs.price) + "</div>" +
        "</div>" +

        "<div class='dialog__row'>" +
        "<div class='prop'>" + this.locale.VAT + " :</div>" +
        "<div class='value'>" + this.parseCurrencyWithDecimal(costs.priceVAT) + "</div>" +
        "</div>" +

        "<div class='dialog__row'>" +
        "<div class='prop'>" + this.locale.auctionCost + " (" + (this.extraCost * 100).toFixed(0) + "%) :</div>" +
        "<div class='value'>" + this.parseCurrencyWithDecimal(costs.extraCost) + "</div>" +
        "</div>" +

        "<div class='dialog__row'>" +
        "<div class='prop'>" + this.locale.auctionCost + " " + this.locale.VAT + " :</div>" +
        "<div class='value'>" + this.parseCurrencyWithDecimal(costs.extraCostVAT) + "</div>" +
        "</div>" +

        "<div class='dialog__row total__row'>" +
        "<div class='total prop'>" + this.locale.Total + " :</div>" +
        "<div class='value'>" + this.parseCurrencyWithDecimal(costs.total) + "</div>" +
        "</div>";

      this.Dialog.show({
          controller: ($scope,
                       $mdDialog,
                       LocaleFactory,
                       template,
                       AppSettings) => {
            "ngInject";

            $scope.locale = LocaleFactory;
            this.locale = LocaleFactory;
            $scope.settings = AppSettings;
            this.settings = AppSettings;

            $scope.confirm = () => {
              $mdDialog.hide('confirmed');
            };

            $scope.cancel = () => {
              $mdDialog.cancel('canceled')
            };

            $scope.template = template;
            this.template = template;

            return this;

          },
          controllerAs: 'vm',
          locals: {
            template: confirmMessage,
          },
          templateUrl: this.settings.templates + 'confirmBidDialog.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          fullscreen: false,
        })
        .then(
          () => {
            this.placeBid(type, amount);
          },
          () => {
          }
        );
    }
  }

  placeBid(type, amount) {
    this.errors = this.messages = '';
    this.hasErrors = this.hasMessages = false;
    this.loading = true;

    if (!amount) {
      this.errors = this.locale.invalidAmount;
      this.hasErrors = true;
      this.loading = false;
      return;
    }

    this.R.placeBid(this.Lot.id, amount, type).then(
      (res) => {
        let data = res.data;

        if (data.code != 2) {
          //display errors
          this.errors = "<ul>";
          angular.forEach(data.errors, (err) => {
            this.errors += "<li>" + err + "</li>";
          });
          this.errors += "</ul>";
          this.hasErrors = true;
        }
        else {
          //display messages
          this.messages = "<ul>";
          angular.forEach(data.bid.messages, (mes) => {
            this.messages += "<li>" + mes + "</li>";
          });
          this.messages += "</ul>";
          this.hasMessages = true;
        }
      }).finally(() => {
      this.loading = false;
      this.bid.proxy = this.bid.direct = '';
    });

  }

  calculateCosts(amount) {
    let VAT = this.VAT;
    let ecVAT = this.ecVAT;

    if (typeof amount == 'String')
      amount.replace(/\D/g, '');


    amount = Math.floor(parseFloat(amount));

    let priceVAT = Math.floor(parseFloat(amount * VAT));
    let priceWithVAT = Math.floor(parseFloat(amount + priceVAT));

    let extraCost = Math.floor(parseFloat(amount * this.extraCost));
    let extraCostVAT = Math.floor(parseFloat(extraCost * ecVAT));
    let extraCostPlusVAT = Math.floor(parseFloat(extraCost + extraCostVAT));

    let total = Math.floor(parseFloat(priceWithVAT + extraCostPlusVAT));

    return {
      price: amount,
      priceVAT: priceVAT,
      priceWithVAT: priceWithVAT,
      extraCost: extraCost,
      extraCostVAT: extraCostVAT,
      extraCostWithVAT: extraCostPlusVAT,
      total: total
    }

  }

  parseCurrency(amount) {
    amount /= 100;
    numbro.culture('nl-BE');
    numbro.defaultCurrencyFormat('$ 0,0');
    return numbro(amount).formatCurrency();
  }

  parseCurrencyWithDecimal(amount) {
    amount /= 100;
    numbro.culture('nl-BE');
    numbro.defaultCurrencyFormat('$ 0,0.00');
    return numbro(amount).formatCurrency();
  }

  calculateBidValues() {

    let auction = this.settings.auction;
    let Lot = this.Lot;

    this.currencySymbol = Lot.starting_price.symbol;
    this.highestBidRaw = Lot.highest_bid ? parseFloat(Lot.highest_bid.raw) : null;
    this.priceRaw = parseInt(Lot.starting_price.raw);
    this.bidStepRaw = 0;

    //Auction vars
    this.canBid = this.settings.isBidder();
    this.canExtend = auction.extend;
    this.timing = auction.timing_seconds;
    this.extend = auction.extend_seconds;
    this.extraCost = angular.isDefined(this.ec_rate) ? this.ec_rate / 100 :  auction.extra_cost.rate;
    this.VAT = angular.isDefined(this.vat_rate) ? this.vat_rate / 100 : auction.vat.rate;
    this.ecVAT = angular.isDefined(this.ec_vat_rate) ? this.ec_vat_rate / 100 : auction.vat.rate;

    this.proxyEnabled = Lot.proxy_enabled;
    this.proxy = Lot.proxy;
    this.bidsCount = Lot.bids.length;
    this.requireMultiple = !this.multiple === false;

    //Bid Step
    switch (Lot.min_bid_increment) {
      //disabled
      case "-1.00":
        this.bidStep = null;
        this.bidStepRaw = 0;
        break;
      //global
      case "0.00":
        this.bidStep = parseFloat(auction.step) * 100;
        this.bidStepRaw = this.bidStep;
        break;
      //per Lot
      default:
        this.bidStep = parseFloat(Lot.min_bid_increment) * 100;
        this.bidStepRaw = this.bidStep;
    }

    //Multiple
    switch (Lot.bid_increment) {
      //disabled
      case "-1.00":
        this.multiple = null;
        break;
      //global
      case "0.00":
        this.multiple = parseFloat(auction.multiple) * 100;
        break;
      //per Lot
      default:
        this.multiple = parseFloat(Lot.bid_increment) * 100;
        break;
    }

    //MaxBid
    switch (Lot.max_bid_increment) {
      //disabled
      case "-1.00":
        this.maxBid = null;
        break;
      //global
      case "0.00":
        this.maxBid = parseFloat(auction.max) * 100;
        break;
      //per Lot
      default:
        this.maxBid = parseFloat(Lot.bid_increment) * 100;
    }

    //MaxPercentStep
    switch (Lot.max_bid_increment_percentage) {
      //disabled
      case "-1.00":
        this.maxPercent = null;
        break;
      //global
      case "0.00":
        this.maxPercent = parseFloat(auction.max_percent) * 100;
        break;
      //per Lot
      default:
        this.maxPercent = parseFloat(Lot.max_bid_increment_percentage) * 100;
    }

    this.highestBid = this.parseCurrency(this.highestBidRaw);
    this.price = this.parseCurrency(parseInt(this.priceRaw));

    let maxMultipleOrBidStep = Math.max(this.multiple, this.bidStep);

    this.nextBidRaw = this.highestBidRaw ? Math.floor(parseFloat(this.highestBidRaw)) + Math.floor(
      parseFloat(maxMultipleOrBidStep)) : Math.floor(parseFloat(this.priceRaw));

    this.bidStep = this.parseCurrency(this.bidStep);
    this.nextBid = this.parseCurrency(this.nextBidRaw);
    this.multipleRaw = this.multiple;
    this.multiple = this.parseCurrency(this.multiple);
    this.bidsCount = Lot.bids.length;
    this.nextValidBid = this.nextBidRaw / 100;

    this.suspended = Lot.item_status == '2';
    this.closed = Lot.item_status == '1';
    this.open = Lot.item_status == '0';

    //Selling ststuses
    this.forSale = Lot.buy_status === '0';
    this.awarded = Lot.buy_status === '1';
    this.unsold = Lot.buy_status === '5';
    this.ordering = !this.awarded && !this.forSale && !this.unsold;

    this.leading = Lot.leading.user_leads;
    this.leadingMessage = this.leading ?
      this.locale.isLeaderMessage :
      this.locale.isNotLeaderMessage;
    let now = moment();
    let expireTime = moment(Lot.timeout * 1000);

    this.expired = (expireTime - now) <= 0;

    if (!this.rootScope.$root.$$phase) {
      this.scope.$apply();
    }

  }
}

export default LotBidController;
