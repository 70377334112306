import AuctionSinglePageController from './auctionSinglePage.controller';

let AuctionSinglePageComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'auctionSinglePage_v2.html',
    controller: AuctionSinglePageController,
    controllerAs: "vm",
    scope: {}
  }

};

export default AuctionSinglePageComponent;
