import UserMyLotsComponent from './userMyLots.component';

let UserMyLotsModule = angular.module('app.shared.components.user.usermylots', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('userMyLots', UserMyLotsComponent);

export default UserMyLotsModule;
