import LotBidController from './lotBid.controller';

let LotBidComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'lotBid_v2.html',
    controller: LotBidController,
    controllerAs: "vm",
    scope: {
      lot: "=",
      expandableinfo: "=",
      collapsedinfo: "="
    }
  }

};

export default LotBidComponent;
