import SearchBarController from './searchBar.controller';

let SearchBarComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'searchBar.html',
    controller: SearchBarController,
    controllerAs: "vm",
    scope: {}
  }

};

export default SearchBarComponent;
