import ProfileController from './profile.controller';

let ProfileComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'profile.html',
    controller: ProfileController,
    controllerAs: "vm",
    scope: {}
  }

};

export default ProfileComponent;
