import UserListTabComponent from './userListTab.component';

let UserListTabModule = angular.module('app.shared.components.user.userlisttab', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('userListTab', UserListTabComponent);

export default UserListTabModule;
