import SearchPageController from './searchPage.controller';

let SearchPageComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'searchPage.html',
    controller: SearchPageController,
    controllerAs: "vm",
    scope: {}
  }

};

export default SearchPageComponent;
