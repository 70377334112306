import AuctionInfoComponent from './auctionInfo.component';

let AuctionInfoModule = angular.module('app.shared.components.auction.auctioninfo', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('auctionInfo', AuctionInfoComponent);

export default AuctionInfoModule;
