class LotListController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $cookies,
              $stateParams,
              $state,
              AppSettings,
              $sce,
              lodash,
              LocaleFactory,
              FormatsFactory,
              RestFactory) {

    //common features.
    this.rest = RestFactory;
    this.params = $stateParams;
    this.state = $state;
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this._ = lodash;
    this.loading = false;
    this.pageRange = $scope.range || AppSettings.pagesRange;

    console.log('List', $scope);
    this.currentPage = $stateParams.page || 1;
    $scope.currentPage = this.currentPage;
    $scope.page = this.currentPage;

    this.itemCount = 0;
    this.requestContext = 'controller';
    this.searchText = '';
    this.query = {};
    this.hidePaging = false;
    this.sortFilters = $scope.sort || null;

    this.name = 'LotListController';
    this.viewMode = $cookies.get('auctionActiveViewMode') || 'list';
    this.auctionId = $stateParams.id;
    this.lots = [];
    this.getLots(this.currentPage);


    //EVENTS
    $scope.$watch('currentPage', (value) => {
      $scope.page = value;
    });

    $scope.$on('AuctionViewModeChanged', (ev, mode, scope) => {
      this.viewMode = mode;
    });

    $scope.$on('cancelSearch', (ev, data) => {
      if (data.type == 'lots') {
        this.hidePaging = false;
        this.query = {};
        this.getLots();
      }
    });

    $scope.$on('doSearch', (ev, data) => {
      if (data.type == 'lots') {
        this.searchText = data.searchText;
        this.query = {};
        this.query['filter[type]'] = 'lot';
        this.requestContext = 'search';
        this.searchLots();
        this.hidePaging = true;
      }
    });

    $scope.$on('categoryChanged', (ev, data) => {
      this.query = {};
      if (data) {
        this.query['filter[categories]'] = data.id;
      }
      this.requestContext = 'controller';
      this.getLots();
    });//$scope.$on

  }

  pageChanged(page) {
    switch (this.requestContext) {
      case 'controller':
        this.state.go('auction', {
            id: this.auctionId,
            page: page
          }
        );

//        this.state.reload();
//        this.getLots(page);
        break;
      case 'search':
        this.searchAuctions(page);
        break;
    }
//    this.currentPage = page;
  }

  searchLots(page) {

    if (this.searchText.length >= 3) {
      this.hidePaging = false;
      page = page || 1;
      this.currentPage = page;

      this.loading = true;
      let count = 0;
      this.lots = [];

      this.rest.search(this.searchText, this.query, null, page).then(
        (res) => {
          let lots = [];
          if (res.data) {
            angular.forEach(res.data[0], (item) => {
              if (item.resource && item.auction) {
                let lot = item.resource[0];
                if (lot.auction.nid == this.auctionId) {
                  lots.push(lot);
                }
              }
            });
            this.lots = lots;
          }
          else {
            this.lots = [];
            count = 0;
          }
        }
      ).finally(() => {
        this.loading = false;
        this.itemCount = parseInt(count);
      });

    }//if >= 3

  }//if lots


  getLots(page) {
    this.loading = true;
    this.query['filter[auction]'] = this.auctionId;
    this.sortFilters ?
      this.query['sort'] = this.sortFilters : null;

    var lots = this.rest.lots(this.query, false, this.pageRange, page);
    lots.then((res) => {
      this.lots = res.data;
      this.itemCount = res.count;
      this.currentPage = page;
    }).finally(() => {
      this.loading = false;
    });
  }

}

export default LotListController;
