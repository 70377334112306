class CategoryController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              AppSettings,
              RestFactory,
              lodash,
              $stateParams,
              LocaleFactory,
              FormatsFactory) {

    Drupal.behaviors.angmod = (function (context) {

      //common features.
      this.settings = AppSettings;
      this.locale = LocaleFactory;
      this.formats = FormatsFactory;
      this.trustAsHtml = $sce.trustAsHtml;
      this.t = Drupal.t;
      this.name = 'CategoryController';
      this.item = $scope.item;
      this.scope = $scope;
      this.rootScope = $rootScope;
      this.R = RestFactory;
      this._ = lodash;
      this.loading = false;

      //Custom code.
      this.children = [];

      if($stateParams.category) {
        if($stateParams.category == $scope.item.id) {
          $scope.item.selected = true;
          this.settings.category = $scope.item.id;
          this.settings.categoryName = $scope.item.title;
        }
      }


      $scope.$on('categoryChanged', function (ev, data) {
        //Already selected
        if ($scope.item.selected && (data.id == $scope.item.id)) {
          $scope.item.selected = false;
          this.settings.category = null;
          this.settings.categoryName = '';
        }
        else {
          $scope.item.selected = $scope.item.id === data.id;
        }
        AppSettings.searchText = '';
      });

      $scope.$on('cancelSearch', (ev, data) => {
        $scope.item.selected = false;
      });

      $scope.$on('doSearch', (ev, data) => {
       $scope.item.selected = false;
        this.settings.category = null;
        this.settings.categoryName = '';
      });


    }.bind(this))();
  }

  categorySelected() {
    if (this.item.selected) {
      this.rootScope.$broadcast('categoryChanged', 0);
      this.settings.category = null;
      this.settings.categoryName = null;
      this.item.selcted = false;
    }
    else {
      this.rootScope.$broadcast('categoryChanged', this.item);
      this.toggleChildren();
      this.settings.category = this.item.id;
      this.settings.categoryName = this.item.title;
      this.item.selected = true;
    }
  }

  toggleChildren() {
    //if children in not empty we eant to remove them,
    if (this.children.length) {
      this.children = [];
      this.item.class = '';
    }
    else {
      //Else we need to fecth children
      this.loading = true;
      this.R.categories(this.item.tid).then(
        (res) => {
          //get tids of children
          if (res.data[0].children.length) {
            let tids = this._.map(res.data[0].children, (t) => {
              return t.id;
            });

            //get all children categories.
            this.R.categories(tids).then(
              (res) => {
                this.children = res.data;
                this.item.class = 'expanded';
              }
            ).finally(() => {
              this.loading = false
            });
          }
        }
      );

    }//else-if

  }


}

export default CategoryController;
