class LotItemController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              AppSettings,
              $sce,
              LocaleFactory,
              WebSocketFactory,
              FormatsFactory,
              RestFactory) {

    //common features.
    this.name = 'LotItemController';
    this.lang = AppSettings.language.language;
    this.settings = AppSettings;
    this.trustAsHtml = $sce.trustAsHtml;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.t = Drupal.t;
    this.rootScope = $rootScope;
    this.scope = $scope;
    this.rest = RestFactory;
    this.WS = WebSocketFactory;
    this.isLive = $scope.live || false;
    this.bidExpanded = true;

    this.expireTime = $scope.lot ? FormatsFactory.formatDate($scope.lot.timeout) : moment().unix();
    //Component variables


    $scope.$watch(() => $scope.lot, (nV, oV) => {
      if (nV) {
        this.expireTime = FormatsFactory.formatDate($scope.lot.timeout);
        this.shortDateLang = this.lang + 'short';
        this.expiring = false;
        this.lastMinute = false;
        this.expired = false;
        this.closed = $scope.lot.item_status === '1';
        this.suspended = $scope.lot.item_status === '2';
        this.open = $scope.lot.item_status === '0';

        this.timelineStatus = {
          0: this.locale.PastAuction,
          1: this.locale.ActualAuction,
          2: this.locale.FutureAuction
        };

        this.timeline = '';
        this.timelineIndex = '';

        this.showUserStatus = angular.isDefined($scope.user);
        this.user = this.showUserStatus ?
          $scope.user : AppSettings.user;


        this.leading = this.user.id == $scope.lot.leading.leading_uid;
        //Not for sale and not unsold.
        this.awarded = $scope.lot.buy_status === '1' || $scope.lot.buy_status !== '2';

        this.getItemStatus(this.scope.lot);
      }
    });

    $scope.$on('BidPlaced', (ev, data) => {
      if (data && data.id) {
        if (data.id == $scope.lot.id) {
          data.favourite = $scope.lot.favourite;
          $scope.lot = data;
          this.getItemStatus(data);
        }
      }
    });

    $scope.$on('timer-tick', (ev, args) => {
      var millis = args.millis;

      millis <= 180000 ?
        this.expiring = true :
        this.expiring = false;

      millis <= 60000 ?
        this.lastMinute = true :
        this.lastMinute = false;

      millis <= 0 ?
        this.expired = true :
        this.expired = false;


      if (!$rootScope.$root.$$phase) {
        $scope.$apply();
      }
    });

  }

  toggleBid() {
    this.bidExpanded = !this.bidExpanded;
    console.log('clicked', this.bidExpanded);
  }

  timeCompleted(lot) {
    this.expired = true;
    this.expiring = false;
    this.lastMinute = false;
    if (!this.rootScope.$root.$$phase) {
      this.scope.$digest();
    }
  }
  showLive() {
    const auctionTimeline = this.scope.lot && this.scope.lot.auction && this.scope.lot.auction.timeline && this.scope.lot.auction.timeline === '1';
    return this.isLive && this.itemStatus.open && !this.itemStatus.expired && !this.sellStatus.awarded && auctionTimeline;
  }
  getItemStatus(lot) {
    let leading = lot.leading.user_leads;
    this.leadingMessage = this.leading ?
      this.locale.isLeaderMessage :
      this.locale.isNotLeaderMessage;

    this.awarded = lot.buy_status !== '1' && lot.buy_status !== '3';
    this.forSale = lot.buy_status === '0';
    this.awarded = lot.buy_status === '1';
    this.unsold = lot.buy_status === '5';
    this.ordering = !this.awarded && !this.forSale && !this.unsold;

    //Selling statuses
    this.sellStatus = {
      forSale: this.forSale,
      awarded: this.awarded || this.ordering,
      unsold: this.unsold,
      ordering: this.ordering,
    };

    //expired
    this.expired = lot.expired;

    //last bid amount
    this.lastBid = lot.highest_bid && lot.highest_bid.decimal ?
      lot.highest_bid.formatted :
      lot.original_price.formatted;

    this.lastBidText = lot.highest_bid && lot.highest_bid.decimal ?
      this.locale.lastBid : this.locale.openingBid;

    //item status
    this.itemStatus = {
      open: lot.item_status === '0',
      closed: lot.item_status === '1',
      suspended: lot.item_status === '2',
      expired: lot.expired,
    };

    if (this.awarded && this.leading) {
      this.leadingMessage = this.locale.wonMessage;
    }

    if (this.awarded && !this.leading) {
      this.leadingMessage = this.locale.lostMessage;
    }

    //Auction timeline status
    this.timelineIndex = lot.auction ? parseInt(lot.auction.timeline) : 2;
    this.timeline = this.timelineStatus[this.timelineIndex];

    this.showTimeline = this.timelineIndex !== 1;

    let closingString = this.locale.Closing;
    let closedString = this.locale.Closed;
    let suspendedString = this.locale.Suspended;
  }

  addLotToFavourite(id) {
    var post = this.rest.flag(id).then(
      (res) => {
        this.scope.lot = res.data[0]['node'];
      },
      (err) => {
      }
    )

  }

}

export default LotItemController;
