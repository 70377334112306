class AllLotsController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $stateParams,
              $state,
              $cookies,
              AppSettings,
              $sce,
              LocaleFactory,
              RestFactory,
              FormatsFactory) {

    Drupal.behaviors.angmod = (function (context) {

      //common features.
      this.name = 'AllLotsController';
      this.settings = AppSettings;
      this.pageRange = AppSettings.variables.all_lots_range || 12;
      this.scope = $scope;
      this.locale = LocaleFactory;
      this.formats = FormatsFactory;
      this.R = RestFactory;
      this.state = $state;
      this.stateParams = $stateParams;
      this.trustAsHtml = $sce.trustAsHtml;
      this.t = Drupal.t;
      this.viewMode = $cookies.get('auctionActiveViewMode') || 'list';


      //Custom code.
      this.loading = false;
      this.lots = [];
      this.requestContext = 'controller';
      this.searchedText = '';
      this.showPager = true;
      this.itemCount = 0;

      this.page = $stateParams.page || 1;
      this.category = $stateParams.category || null;

      if ($stateParams.period == 'false') {
        $stateParams.period = 'actual';
      }

      this.periodClass = $stateParams.period ?
        $stateParams.period :
        'actual';

      this.createPeriodQuery(this.periodClass);

      this.showingPeriod = this.locale.ActualLots;

      this.getAllLots();

      $scope.$on('AuctionViewModeChanged', (ev, mode, scope) => {
        this.viewMode = mode;
      });

      $scope.$on('cancelSearch', (ev, data) => {
        if (data.type == 'lots') {
          this.searchedText = '';
          this.settings.category = null;
          this.settings.categoryName = null;
          this.query = {};
          this.requestContext = 'controller';

          this.showingPeriod = this.locale.ActualLots;
          this.periodClass = 'actual';
//          this.filterLots('actual');
          this.createPeriodQuery(this.periodClass);
          this.changeState(1, this.periodClass, null);
          this.getAllLots();
        }

      });

      $scope.$on('doSearch', (ev, data) => {
        this.searchedText = data.searchText;
        this.requestContext = 'search';

        if (this.searchedText.length >= 3) {
          if (data.type == 'lots') {
            this.query = {};
            this.category = null;
            this.settings.category = null;
            this.settings.categoryName = null;
            this.requestContext = 'search';
            this.periodClass = this.showingPeriod = false;
            this.searchLots();
          }
        }
      });

      $scope.$on('categoryChanged', (ev, data) => {

//        this.settings.categoryName = null;

        if (data) {
//          this.query['filter[categories]'] = data.id;
          this.category = data.id;


//          if (this.settings.category) {
//            this.settings.category = null;
//            this.settings.categoryName = null;
//          }
//          else {
          this.settings.category = data.id;
          this.settings.categoryName = data.title;
//          }

          this.requestContext = 'controller';
          this.page = 1;
          this.createPeriodQuery(this.periodClass || 'actual');
          this.changeState(1, this.periodClass, this.category);
          return;

        }//if data
        else {
          this.category = '0';
          this.settings.searchText = null;
          this.settings.category = null;
        }

        this.page = 1;
        this.requestContext = 'controller';
        this.createPeriodQuery(this.periodClass);
        this.changeState(1, this.periodClass, this.category);

      });//$scope.$on

    }.bind(this))();


  }//Constructor

  createPeriodQuery(period) {
    this.query = {};
    let now = moment();
    now.tz(this.settings.timezone);
    now = now.unix();

    switch (period) {
      case 'past':
        delete  this.query['filter[auction.timeline]'];
        this.query['filter[timeout][value]'] = now;
        this.query['filter[timeout][operator]'] = "<";
        this.period = this.locale.PastLots;
        this.periodClass = 'past';
        break;
      case 'actual':
        this.query['filter[auction.timeline]'] = '1';
        this.query['filter[timeout][value]'] = now;
        this.query['filter[timeout][operator]'] = ">";
        this.period = this.locale.ActualLots;
        this.periodClass = 'actual';
        break;
      case 'future':
        this.query['filter[timeout][operator]'] = '>';
        this.query['filter[timeout][value]'] = now;
        this.query['filter[auction.timeline]'] = '2';
        this.period = this.locale.FutureLots;
        this.periodClass = 'future';
        break;
    }

    if (this.category !== '0') {
      this.query['filter[categories]'] = this.category;
    }
  }

  changeState(page, period, category) {
    this.state.go('home', {
      page: page,
      period: period,
      category: category
    });
  }

  filterLots(period) {
    this.createPeriodQuery(period);
    this.changeState("1", period, this.category);
//    this.getAllLots();
  }

  getAllLots() {

    this.showingPeriod = '';
    this.settings.searchText = null;
    this.loading = true;
    let currentPage = this.page;
    this.R.lots(this.query, null, this.pageRange, this.page).then(
      (res) => {
        this.lots = res.data;
        this.itemCount = res.count;
        this.showingPeriod = this.period;
        this.periodViewClass = this.periodClass;
        this.page = currentPage;

      }
    ).finally(() => {
      this.showPager = true;
      this.loading = false;
    });

  }

  searchLots(page) {
    page = page || 1;
    this.page = 1;

    this.category = null;
    this.settings.searchText = null;
    this.settings.category = null;
    this.settings.categoryName = null;
    this.showPager = false;
    this.showingPeriod = this.locale.SearchResults;

    let query = {
      'filter[type]': 'lot'
    };

    this.loading = true;
    let count = 0;

    this.R.search(this.searchedText, query, null, page).then(
      (res) => {
        let lots = [];
        if (res.data) {
          angular.forEach(res.data[0], (item) => {
            if (item.resource && item.auction) {
              item.resource[0].relevance = item.relevance;
              lots.push(item.resource[0]);
            }
          });
          this.periodClass = false;
          this.periodViewClass = '';
          this.showingPeriod = this.locale.SearchResults;
          this.lots = lots;
        } //if res.data
        else {
          this.lots = [];
        }//else res.data

      }
    ).finally(() => {
      this.loading = false;
      this.itemCount = parseInt(count);
    });

  } //search Lots

  pageChanged(page) {
    switch (this.requestContext) {
      case 'controller':
//        this.getAllLots(page);
        this.page = page;
        this.changeState(page, this.periodClass, this.category);
        break;
      case 'search':
        this.searchLots(page);
        break;
    }
  }
}

export default AllLotsController;
