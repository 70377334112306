import ProfileComponent from './profile.component';

let ProfileModule = angular.module('app.shared.components.user.profile', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('profile', ProfileComponent);

export default ProfileModule;
