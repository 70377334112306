class AuctionListController {
  /*@ngInject*/
  constructor($rootScope,
              $scope,
              $cookies,
              AppSettings,
              $sce,
              RestFactory,
              EventHelper,
              LocaleFactory) {

    //common features.
    this.name = 'AuctionListController';
    this.$cookies = $cookies;
    this.settings = AppSettings;
    this.trustAsHtml = $sce.trustAsHtml;
    this.t = Drupal.t;
    this.scope = $scope;
    this.eventHelper = EventHelper;
    this.locale = LocaleFactory;
    this.noItemFound = LocaleFactory.noItemFound;
    this.viewMode = this.$cookies.get('auctionActiveViewMode') || 'list';
    this.loading = null;
    this.R = RestFactory;
    this.requestContext = 'controller';
    this.searchedText = '';
    this.showLiveButton = false;
    this.auctions = [];
    this.range = $scope.range || AppSettings.pagesRange;
    this.itemCount = 0;
    this.context = 'auctions';
//    this.fetchAuctions(1);
    this.query = {};
    this.showingPeriod = this.locale.OurAuction;
    this.periodClass = 'actual';
    this.filterAuctions('our');
    this.pastAuctionsDays = AppSettings.variables.past_auctions_days || 90;
    const isBidder = AppSettings.isBidder();
    if(isBidder) {
      const uid = AppSettings.user.id;
      RestFactory.userLiveLots(uid, 1, 1, {'fields': 'id,nid'}).then(res => {
        this.showLiveButton = res.count && res.count > 0;
      }, err => {
        console.log(err);
      });
    }

    //RootScope events
    $scope.$on('AuctionViewModeChanged', (ev, mode, scope) => {
      this.viewMode = mode;
    });


    $scope.$on('cancelSearch', (ev, data) => {
      if (data.type == 'auctions') {
        this.searchedText = '';
        this.fetchAuctions();
      }

    });

    $scope.$on('doSearch', (ev, data) => {
      this.searchedText = data.searchText;
      this.requestContext = 'search';

      if (this.searchedText.length >= 3 && data.type == 'auctions') {
        this.searchAuctions();
      }//if

    });//$on doSearch;


  }

  showLive() {
    this.context = 'live';
    this.periodClass = 'live';
    this.periodViewClass = 'live';
    this.eventHelper.broadcastGlobalEvent('auctionFiltersChanged', 'live', this);
  }

  filterAuctions(filter) {

    // time-line: 0=Past, 1=Current, 2=Future
    // 1. Our auctions: time=current+future, taxonomy=all taxonomy without retails and acquisitions
    // 22/01/2017: (Changed after client request to also show retails and acquisitions)
    // 02/07/2018: (Changed after client request to also show retails and acquisitions)
    // 2. Retails: time=current+future, taxonomy=retails(winkelverkopen), tid=131
    // 3. Acquisitions: time=current+future, taxonomy=acquisitions(overnames), tid=136
    // 4. Past: time=past, taxonomy=all
    this.context = 'auctions';
    this.settings.searchText = '';
    this.settings.categoryName = '';
    this.query = {};
    this.eventHelper.broadcastGlobalEvent('auctionFiltersChanged', filter, this);
    switch (filter) {
      case 'our':
        this.query['timeline'] = '1,2';
        // this.query['reason'] = '136';
        // this.query['reasonOperator'] = 'NOT IN';
        this.query['sort'] = 'timeline,closing_date';
        this.period = this.locale.OurAuctions;
        this.periodClass = 'our';
        break;
      case 'retails':
        this.query['timeline'] = '1,2';
        this.query['reason'] = '131';
        this.query['sort'] = 'timeline,closing_date';
        this.period = this.locale.RetailAuctions;
        this.periodClass = 'retails';
        break;
      case 'acquisitions':
        this.query['timeline'] = '1,2';
        this.query['reason'] = '136';
        this.query['sort'] = 'timeline,closing_date';
        this.period = this.locale.AcquisitionAuctions;
        this.periodClass = 'acquisitions';
        break;
      case 'past':
        const days = parseInt(this.pastAuctionsDays, 10);
        const startFrom = moment().subtract(days, 'd').unix();
        this.query['timeline'] = '0';
        this.query['filter[closing_date][value]'] = startFrom;
        this.query['filter[closing_date][operator]'] = ">=";
        this.query['sort'] = '-closing_date';
        // this.query['range'] = 10;
        this.period = this.locale.PastAuctions;
        this.periodClass = 'past';
        break;

    }
    this.fetchAuctions();
  }

  pageChanged(page) {
    switch (this.requestContext) {
      case 'controller':
        this.fetchAuctions(page);
        break;
      case 'search':
        this.searchAuctions(page);
        break;
    }
  }

  searchAuctions(page) {
    page = page || 1;

    let query = {
      'filter[type]': 'auction',
    };

    this.loading = true;
    this.periodViewClass = '';
    let count = 0;

    this.R.search(this.searchedText, query, this.range, page).then(
      (res) => {
        let auctions = [];
        if (res.data) {
          angular.forEach(res.data[0], (item) => {
            item.resource[0].relevance = item.relevance;
            auctions.push(item.resource[0]);
            count = item.count;
          });
          this.auctions = auctions;
          this.showingPeriod = this.locale.AllAuctions;
        } //if res.data
        else {
          this.auctions = [];
          count = 0;
        }//else res.data

      }
    ).finally(() => {
      this.loading = false;
      this.itemCount = parseInt(count);
    });

  }; //search Auctions

  fetchAuctions(page) {
    page = page || 1;
    var Auctions = this.R.auctions(this.query, null, this.range, page);
    this.loading = true;

    Auctions.then(
      (res) => {
        this.auctions = res.data;
        this.itemCount = res.count;
        this.showingPeriod = this.period;
        this.periodViewClass = this.periodClass;
      }
    ).finally(
      () => {
        this.loading = false;
      });

  };//fetch Auctions

}


export default AuctionListController;
