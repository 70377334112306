import UserLotsComponent from './userLots.component';

let UserLotsModule = angular.module('app.shared.components.user.userlots', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('userLots', UserLotsComponent);

export default UserLotsModule;
