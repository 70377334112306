import SearchBarComponent from './searchBar.component';

let SearchBarModule = angular.module('app.shared.components.searchbar', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('searchBar', SearchBarComponent);

export default SearchBarModule;
