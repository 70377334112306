class SearchBarController {
  /*@ngInject*/

  constructor($rootScope,
              $scope,
              $sce,
              $q,
              $location,
              $window,
              $stateParams,
              $state,
              RestFactory,
              AppSettings,
              LocaleFactory,
              FormatsFactory) {


    //common features.
    this.settings = AppSettings;
    this.locale = LocaleFactory;
    this.formats = FormatsFactory;
    this.trustAsHtml = $sce.trustAsHtml;
    this.$sce = $sce;
    this.t = Drupal.t;
    this.$q = $q;
    this.$location = $location;
    this.window = $window;
    this.state = $state;
    this.stateParams = $stateParams;
    this.R = RestFactory;
    this.name = 'SearchBarController';

    //Custom code.
    this.items = [];
    this.selectedItem = null;
  }

  querySearch(query) {
    return this.R.Factory().all('search_open/' + query).getList({
      style: 'medium',
      range: 50
    })
      .then(
        (items) => {
          return items.data.length > 0 ? items.data[0]: [];
        }
      )
  }

  searchTextChange(text) {

  }

  selectedItemChange(item) {
    if (item && item.type) {
      let siteUrl = this.formats.siteUrl();
      switch (item.type) {
        case 'lot':
          siteUrl += '#/lot/' + item.entity_id;
          this.window.location.href = siteUrl;
          break;
        case 'auction':
          siteUrl = siteUrl += '#/auction/' + item.entity_id + '/'
          this.window.location.href = siteUrl;
          break;
      }
    }
  }

  formSubmitted() {
    this.state.go('search', {term: this.searchText});
  }

  getTitleEncoded(item) {
    let lang = this.settings.language.language;
    let title = item.title;
    if(item.type == 'lot' && item.title_lot[lang]){
      title = item.title_lot[lang][0].value;
    }
    else if(item.type == 'auction' && item.title_auction[lang]) {
      title = item.title_auction[lang][0].value;
    }
    return title;
  }
}

export default SearchBarController;
