import NewsItemController from './newsItem.controller';

let NewsItemComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'newsItem.html',
    controller: NewsItemController,
    controllerAs: "vm",
    scope: {
      item: "="
    }
  }

};

export default NewsItemComponent;
