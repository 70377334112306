let ModelHelper = ($rootScope, lodash) => {
  "use strict";
  "ngInject";

  let _ = lodash;

  function getById(id, model) {
    _.find(model, function (item) {
      return item.id == id;
    });
  }

  function updateItem(item, model) {
    var index = _.findIndex(model, function (i) {
      return item.id == i.id;
    });
    if (index != -1) {
      model[index] = item;
      return item;
    }
    return false;
  }

  var methods = {
    getById: getById,
    updateItem: updateItem,
  }

  return methods;

}

export default ModelHelper;
