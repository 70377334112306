import LotItemController from '../lotItem.controller';

let FeaturedLotItemComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'featuredLotItem_v1.html',
    controller: LotItemController,
    controllerAs: "vm",
    scope: {
      lot: "="
    }
  }

};

export default FeaturedLotItemComponent;
