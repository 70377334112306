import NavbarController from './navbar.controller';

let NavbarComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'auctions.navbar_v1.html',
    controller: NavbarController,
    controllerAs: "vm",
    scope: {
      type: "@", //The node types to search for.
      text: "@",
      global: "@" //whether to search for all open items or current auction
    },
    bindToController: true
  }

};

export default NavbarComponent;
