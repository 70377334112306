import FeaturedLotItemComponent from './featured/featuredLotItem';
import ListLotItemComponent from './list/listLotItem';

let LotItemModule = angular.module('app.shared.components.lot.lotitem', [
  'ngLodash',
  'restangular'
])
  .directive('lotItemList', ListLotItemComponent)
  .directive('lotItemFeatured', FeaturedLotItemComponent);

export default LotItemModule;
