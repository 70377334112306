import CategoryComponent from './category.component';

let CategoryModule = angular.module('app.shared.components.category', [
  //Add module dependencies here
  //in the format.
  /*OtherModule.name*/
])
  .directive('category', CategoryComponent);

export default CategoryModule;
