import LotItemController from '../lotItem.controller';

let ListLotItemComponent = (AppSettings) => {
  "ngInject";

  return {
    restrict: 'E',
    templateUrl: AppSettings.templates + 'listLotItem_v2.html',
    controller: LotItemController,
    controllerAs: "vm",
    scope: {
      lot: "=",
      user: "=",
      live: "=",
      expandableinfo: "=",
      collapsedinfo: "="
    }
  }

};

export default ListLotItemComponent;
