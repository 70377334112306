let RestFactory = (Restangular, AppSettings) => {
  "ngInject";
  "use strict";
  var imageStyle = 'square';

  /**
   *
   * @param query
   * @param style
   *  A drupal Image style. Default to {imageStyle}.
   *  A value of 'none' will return no styled images.
   * @returns {*|{}}
   */
  var preBuildQuery = (query, style, range, page) => {
    var query = query || {};
    if (style != 'none') {
      query['style'] = style || imageStyle;
    }

    if (range) {
      query['range'] = range;
    }

    if (page) {
      query['page'] = page;
    }
    if (!query.hasOwnProperty('sort') && query['sort'] != null) {
      query['sort'] = '-created';
    }

    return query;
  };

  var token = '';

  (() => {
    if (AppSettings.isAuthenticated()) {
      Restangular.one('session/token').get().then(
        (res) => {
          token = res['X-CSRF-Token'] || res.data['X-CSRF-Token'];
          Restangular.setDefaultHeaders({"X-CSRF-Token": token});
        }
      )
    }
  })();

  function Rest() {
    this.getToken = () => {
      return token
    };

    this.Factory = () => {
      return Restangular;
    };

    /**
     * Auctions and Lots stauses.
     *
     * buy_status: (Lots):
     *  0 in progress
     *  1: order placed.
     *
     * item_status: (Lots and Auctions):
     *  0: Open
     *  2: Closed
     *  3: Suspended.
     *
     *  auction_item_status (Lots)
     *  the {@item_status} of the parent auction.
     *
     * auction_published (Lots)
     *  0: parent auction not published
     *  1: parent auction published
     *
     *  published (Auctions and Lots)
     *  0/1 whether the node is published or not.
     *
     */
    this.auction = (id, style) => {
      let query = preBuildQuery({}, style);
      query['sort'] = null;
      var auction = Restangular.one('auctions', id);
      return auction.get(query);
    };

    this.auctions = function (query, style, range, page) {
      query = preBuildQuery(query, style, range, page);
      if(!query['sort']){
        query['sort'] = 'closing_date';
      }
      var auctions = Restangular.all('auctions');
      return auctions.getList(query);
    };

    this.nodes = function (query, style, range, page) {
      query = preBuildQuery(query, style, range, page);
      var nodes = Restangular.all('nodes');
      return nodes.getList(query);
    };

    this.news = function (query, style, range, page) {
      query = preBuildQuery(query, style, range, page);
      query['type'] = 'news';
      var nodes = Restangular.all('news');
      return nodes.getList(query);
    };

    this.faqs = function (query, style, range, page) {
      query = preBuildQuery(query, 'none', range, page);
      query['type'] = 'faq';
      var faqs = Restangular.all('faq');
      return faqs.getList(query);
    };

    this.lots = function (query, style, range, page) {
      query = preBuildQuery(query, style, range, page);

      if (!query.hasOwnProperty('sort')) {
        query['sort'] = 'timeout';
      }
//      query['filter[auction][value]'] = 'false';
//      query['filter[auction][operator]'] = '<>';
      var lots = Restangular.all('lots');
      return lots.getList(query);
    };

    this.lot = (id, style, query) => {
      query = preBuildQuery(query, style);
      var lot = Restangular.one('lots', id);
      return lot.get(query);
    };

    this.featuredAuctions = function (query, style, range, page) {
      query = preBuildQuery(query, style, range, page);
      query['filter[featured]'] = 1;
      var auctions = Restangular.all('auctions');
      return auctions.getList(query);
    };

    this.featuredLots = function (query, style, range, page) {
      query = preBuildQuery(query, style, range, page);
      query['filter[featured]'] = 1;
      var lots = Restangular.all('lots');
      return lots.getList(query);
    };

    this.lotsByCategory = (tid, query, style, range, page) => {
      query = preBuildQuery(query, style, range, page);
      query['filter[categories][value]'] = tid;
      query['filter[categories][operator]'] = 'IN';
      return this.lots(query);
    };

    this.search = (text, query, range, page) => {
      query = preBuildQuery(query, false, range, page);
      query['sort'] = 'relevance';
      let search = Restangular.all('search/' + text);
      return search.getList(query);
    };

    this.flag = (id, name) => {
      name = name || 'lots_list';
      var flag = Restangular.one('flag', id);
      flag.name = name;
      return flag.post();
    };

    this.parentCategories = () => {
      let catgs = Restangular.all('parent_categories');
      return catgs.getList({'sort': 'weight'});
    };

    this.categories = (tid) => {
      let catgs;

      if (tid) {
        catgs = Restangular.one('categories', tid);
        return catgs.get({'sort': 'weight'});
      }
      catgs = Restangular.all('categories');
      return catgs.getList({'sort': 'weight'});
    };

    /**
     * lotId: the lot nid;
     * amount: amount in the format of drupal commerce (e.g. 100 = 10000 / 100,50 == 10050)
     * bidType: 0: direct, 1: proxy.
     */
    this.placeBid = (lotId, amount, bidType) => {
      let point = Restangular.all('placebid');
      let query = {
        nid: lotId,
        amount: amount,
        bid_type: bidType
      };
      return point.post(query);
    };

    /**
     * Get user's flagged lots
     *
     * @method flaggedLots
     * @param UserId {integer} The unique user id (uid)
     * @param range {integer} The returned result range
     * @param page {integer} The returned page
     * @return {object}
     */
    this.flaggedLots = (UserId, range, page, query) => {
      if (!UserId) {
        throw new Error('User Id is mandatory');
      }
      let $query = query || {};

      $query = preBuildQuery($query, null, range, page);
      $query['user'] = UserId;
      $query['filter[auction.id][value]'] = 0;
      $query['filter[auction.id][operator]'] = '>';
      let endpoint = Restangular.all('flaggedlots');
      return endpoint.getList($query);
    };

    /**
     * Get lots where user placed a bid
     *
     * @method userLots
     * @param UserId {integer} The unique user id (uid)
     * @param range {integer} The returned result range
     * @param page {integer} The returned page
     * @return {object}
     */
    this.userLots = (UserId, range, page, query) => {
      if (!UserId) {
        throw new Error('User Id is mandatory');
      }
      let $query = query || {};

      $query = preBuildQuery($query, null, range, page);
      $query['user'] = UserId;
      $query['filter[auction.id][value]'] = 0;
      $query['filter[auction.id][operator]'] = '>';
      let endpoint = Restangular.all('userlots');
      return endpoint.getList($query);
    };

    this.userLiveLots = (uid, range, page, query) => {
      const $query = preBuildQuery(query || {}, null, range, page);
      // $query['filter[timeout][value]'] = moment().unix();
      // $query['filter[timeout][operator]'] = '>=';
      // $query['filter[status]'] = '1';
      $query['filter[buy_status]'] = '0';
      $query['filter[item_status]'] = '0';
      $query['sort'] = 'timeout';
      $query['user'] = uid;
      $query['filter[auction.id][value]'] = 0;
      $query['filter[auction.id][operator]'] = '>';
      let endpoint = Restangular.all('userlivelots');
      return endpoint.getList($query);
    };

    this.userOpenLots = (UserId, range, page, query) => {
      if (!UserId) {
        throw new Error('User Id is mandatory');
      }
      let $query = query || {};

      $query = preBuildQuery($query, null, range, page);
      $query['user'] = UserId;
      $query['filter[expired]'] = '0';
      $query['filter[auction.id][value]'] = 0;
      $query['filter[auction.id][operator]'] = '>';
      let endpoint = Restangular.all('userlots');
      return endpoint.getList($query);
    };

    /**
     * Get user profile
     *
     * @method User
     * @param UserId {integer} The unique user id (uid)
     * @param $query {object} A set of filters
     * @return {object}
     */
    this.User = (UserId, $query)  => {
      $query = preBuildQuery($query, 'thumbnail');
      let endpoint = Restangular.one('v2.0/users', UserId);
      return endpoint.get($query);
    };

    /**
     * Get lots where user placed a bid
     *
     * @method userLots
     * @param UserId {integer} The unique user id (uid)
     * @param range {integer} The returned result range
     * @param page {integer} The returned page
     * @return {object}
     */
    this.userWonLots = (UserId, range, page) => {
      if (!UserId) {
        throw new Error('User Id is mandatory');
      }
      let $query = {};

      $query = preBuildQuery($query, null, range, page);
      $query['user'] = UserId;
      $query['filter[auction.id][value]'] = 0;
      $query['filter[auction.id][operator]'] = '>';
      let endpoint = Restangular.all('wonlots');
      return endpoint.getList($query);
    }


  }


  return new Rest;
};

export default RestFactory;
